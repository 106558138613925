import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";

import styled from "styled-components";
import { addMonths } from "date-fns";
import {
  FlexAlignItems,
  FlexBox,
  FlexDirection,
  FlexJustifyContent,
} from "../../../shared/components/FlexBox";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { Color } from "../../../constants/ColorEnum";
import formatDate from "../../../utils/formatDate";
import { usePrevious } from "../../../shared/hooks/usePrevious";
import { Icon } from "../../../shared/components/Icon";
import { IconType } from "../../../constants/IconType";
import { IconSize } from "../../../constants/IconSize";
import { TextWeight } from "../../../constants/TextWeight";
import staticRanges from "./StaticRanges";
import StyledDateRange from "./StyledDateRange";
import { useAppSelector } from "../../../app/hooks";

// import formatDate from '../utils/formatDate';
// import { Icon, IconSize } from '../Icon';
// import StyledDateRange from './StyledDateRange';
// import staticRanges from './StaticRanges';
// import { usePrevious } from '../hooks/usePrevious';

export type Range = {
  startDate?: Date;
  endDate?: Date;
  color?: string;
  key?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  showDateDisplay?: boolean;
};

export enum DateRangePosition {
  START = "start",
  END = "end",
}

export type DateRangePickerPropsType = {
  className?: string;
  buttonClassName?: string;
  onApply: (range: Range) => void;
  range: Range;
  minDate?: Date;
  maxDate?: Date;
  position?: DateRangePosition;
};

const Container = styled(FlexBox)`
  position: relative;
  max-width: 600px;
`;

const StyledButton = styled(Button)`
  span {
    font-weight: ${TextWeight.REGULAR};
  }
`;

const CalendarWrapper = styled(FlexBox)<{ position?: DateRangePosition }>`
  padding: 24px;
  border: 1px solid ${Color.PRIMARY10};
  background-color: ${Color.SECONDARY};
  border-radius: 12px;
  box-sizing: border-box;
  position: absolute;
  min-height: 440px;
  box-sizing: border-box;
  top: 41px;
  min-width: 630px;
  ${({ position }) =>
    position === DateRangePosition.START ? "left: 26px;" : "right: -3px;"}
  z-index: 5;
`;

const SEPRATOR = "→";

export function DateRangePicker({
  className,
  onApply,
  range,
  minDate,
  maxDate,
  position = DateRangePosition.START,
  buttonClassName,
}: DateRangePickerPropsType) {
  // const { t } = useTranslation();
  const { from, to } = useAppSelector((state) => state.events);

  const [ranges, setRanges] = useState<Range[]>([
    { ...range, key: "selection" },
  ]);
  const [datePickerVisibility, setDatePickerVisibility] =
    useState<boolean>(false);
  const calendarRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const currentRangeValue = useMemo(() => `${formatDate(
    from as Date
  )} ${SEPRATOR} ${formatDate(to as Date)}`, [from, to]);

  const currentStartDateValue = formatDate(ranges[0].startDate as Date);
  const currentEndDateValue = formatDate(ranges[0].endDate as Date);
  const previousStartDateValue = usePrevious(
    formatDate(range.startDate as Date)
  );
  const previousEndDateValue = usePrevious(formatDate(range.endDate as Date));
  const applyButtonDisabled = !(
    currentStartDateValue !== previousStartDateValue ||
    currentEndDateValue !== previousEndDateValue
  );

  const handleClickOutside = useCallback(({ target }: MouseEvent) => {
    if (
      target &&
      !calendarRef.current?.contains(target as Node) &&
      !buttonRef.current?.contains(target as Node)
    ) {
      setDatePickerVisibility(false);
    }
  }, []);

  useEffect(() => {
    if (datePickerVisibility) {
      document.addEventListener("mousedown", handleClickOutside, {
        capture: true,
      });
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, {
        capture: true,
      });
    };
  }, [datePickerVisibility, handleClickOutside]);

  return (
    <Container direction={FlexDirection.COLUMN}>
      <div ref={buttonRef}>
        <FlexBox
          alignItems={FlexAlignItems.CENTER}
          justifyContent={FlexJustifyContent.FLEX_END}
          distance="8px"
          width="230px"
        >
          <Icon icon={IconType.calendar} size={IconSize.M} />
          <StyledButton
            onClick={() => setDatePickerVisibility(!datePickerVisibility)}
            buttonType={ButtonType.BORDERED_WHITE}
            size={ButtonSize.R}
            className={buttonClassName}
            dataTestid={buttonClassName}
          >
            {currentRangeValue}
          </StyledButton>
        </FlexBox>
      </div>
      {datePickerVisibility && (
        <div ref={calendarRef}>
          <CalendarWrapper
            direction={FlexDirection.COLUMN}
            distance="18px"
            width="100%"
            position={position}
          >
            <StyledDateRange
              className={className}
              editableDateInputs={true}
              onChange={(item) => {
                setRanges([item.selection]);
              }}
              months={2}
              minDate={minDate}
              maxDate={maxDate}
              preventSnapRefocus={true}
              showMonthAndYearPickers={false}
              direction="horizontal"
              rangeColors={[Color.ACCENT]}
              staticRanges={staticRanges}
              monthDisplayFormat="MMMM YYY"
              navigatorRenderer={(currFocusedDate, changeShownDate) => {
                return (
                  <FlexBox
                    justifyContent={FlexJustifyContent.SPACE_BETWEEN}
                    alignItems={FlexAlignItems.CENTER}
                  >
                    <Button
                      icon={IconType.chevronLeft}
                      size={ButtonSize.S}
                      buttonType={ButtonType.GHOST}
                      onClick={() =>
                        changeShownDate(addMonths(currFocusedDate, -1))
                      }
                    />
                    <Button
                      icon={IconType.chevronRight}
                      size={ButtonSize.S}
                      buttonType={ButtonType.GHOST}
                      onClick={() =>
                        changeShownDate(addMonths(currFocusedDate, 1))
                      }
                    />
                  </FlexBox>
                );
              }}
              ranges={ranges}
            />

            <FlexBox
              justifyContent={FlexJustifyContent.FLEX_END}
              alignItems={FlexAlignItems.CENTER}
              distance="16px"
            >
              <Button
                size={ButtonSize.L}
                buttonType={ButtonType.GHOST}
                onClick={() => {
                  setRanges([range]);
                  setDatePickerVisibility(false);
                }}
              >
                {"Cancel"}
              </Button>
              <Button
                size={ButtonSize.L}
                disabled={applyButtonDisabled}
                onClick={() => {
                  onApply(ranges[0]);
                  setDatePickerVisibility(false);
                }}
              >
                {"Apply"}
              </Button>
            </FlexBox>
          </CalendarWrapper>
        </div>
      )}
    </Container>
  );
}
