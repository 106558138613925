import { TextSize } from "./TextSize";

export const LineHeight = {
  [TextSize.XXXS]: "14px",
  [TextSize.XXS]: "16px",
  [TextSize.XS]: "18px",
  [TextSize.S]: "20px",
  [TextSize.M]: "20px",
  [TextSize.L]: "22px",
  [TextSize.XL]: "24px",
  [TextSize.XXL]: "36px",
  [TextSize.XXXL]: "54px",
};

export default LineHeight;
