import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Input } from "../../../shared/components/Input";
import {
  Button,
  ButtonSize,
  ButtonType,
  HTMLButtonType,
} from "../../../shared/components/Button";
import {
  activateBonus,
  createBonus,
  createBurningItemTranslation,
  deactivateBonus,
  getBonuses,
  getItemWithTranslation,
  updateBonus,
  updateBurningItemTranslation,
} from "../redux/actions";
import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { Checkbox } from "../../../shared/components/Checkbox";
import { InputTags } from "../../../shared/components/InputTags";
import { InputLabel } from "../../../shared/components/InputLabel";
import { ITag } from "../../Tags/redux/tags.slice";
import { attachTag, deAttachTag } from "../../Tags/redux/actions";
import { BonusSchema, Language } from "../redux/bonus.slice";
import { Dropdown, DropdownType } from "../../../shared/components/Dropdown";
import { DropdownSize } from "../../../shared/components/DropdownListItem";

const InputWithMargin = styled(Input)`
  margin-top: 20px;
`;

const AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const AuthFormNameWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-top: 27px;
`;

const ErrorText = styled(Text)`
  margin-top: 10px;
`;

const ButtonWithLeftMargin = styled.div`
  margin-left: 16px;
`;

const CheckboxWrapper = styled.div`
  display flex;
  align-items: center;
  margin-top: 20px;
`;

const CheckboxText = styled(Text)`
  margin-right: 8px;
`;

const ChipsWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  .p-chips .p-chips-multiple-container {
    padding: 0.375rem 0.75rem;
  }

  .p-chips .p-chips-multiple-container:not(.p-disabled) .p-focus {
    box-shodow: none;
  }

  .pi-times-circle:before {
    content: url('data:image/svg+xml; utf8,     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 4L12 12M12 4L4 12" stroke="#364159" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>');
  }
`;

const DescriptionContainer = styled.div`
  margin-top: 16px;
`;


const DropdownWrapper = styled.section`
  margin-top: 24px;
`;

const editBurningItemSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(250, "Maximum 250 symbols")
    .required("Title is required"),
  description: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(2000, "Maximum 2000 symbols")
    .required("Description is required"),
  iconUrl: Yup.string()
    // .matches(
    //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //   "Enter correct url!"
    // )
    .required("Icon Url is required"),
  usdPrice: Yup.string().required("USD Price is required"),
  burningItemForeignId: Yup.string().required(
    "burningItemForeignId is required"
  ),
});

export function BurningItemAddForm() {
  const dispatch = useAppDispatch();

  const { errorMessageSubmit, editBonusItem, result } = useAppSelector(
    (state) => state.bonuses
  );

  const [lang, setLang] = useState('en')

  const initialValues = {
    // operatorId: burningItem?.burningItemForeignId || '',
    id: editBonusItem?.id,
    title: editBonusItem?.title.en || "",
    description: editBonusItem?.description.en || "",
    isActive: editBonusItem?.isActive,
    usdPrice: editBonusItem?.usdPrice || 1,
    iconUrl: editBonusItem?.iconUrl || "",
    burningItemForeignId: editBonusItem?.burningItemForeignId || "",
  };

  const [selectedTags, setSelectedTags] = useState<
    { id: string; disabled: boolean }[]
  >([]);
  const [tagsSelectorFormat, setTagsSelectorFormat] =
    useState<{ id: string; label: string; thumb: string }[]>();

  useEffect(() => {
    if (editBonusItem?.tags && editBonusItem?.tags.length > 0) {
      // const mapTags = editBonusItem.tags.map((tag) => tag.name);
      const mapTagsForSelector = editBonusItem.tags.map((tag) => ({
        id: tag.id,
        disabled: false,
      }));

      setSelectedTags(mapTagsForSelector);
    }
  }, [editBonusItem]);

  const tagsList = useAppSelector((state) => state.tags.list);

  useEffect(() => {
    if (Array.isArray(tagsList)) {
      const formatTagsToTagsSelectorFormat = tagsList.map((tag) => ({
        id: tag.id,
        label: tag.name,
        thumb: "",
      }));
      setTagsSelectorFormat(formatTagsToTagsSelectorFormat || []);
    }
  }, [tagsList]);

  const findTagsChanged = (): {
    tagsAdded: { [key: string]: ITag };
    tagsDeleted: { [key: string]: ITag };
  } => {
    console.log(editBonusItem, "Edit");
    if (!editBonusItem) return { tagsAdded: {}, tagsDeleted: {} };

    const initTags: { [key: string]: ITag } = {};
    const tagsDeleted: { [key: string]: ITag } = {};

    //@ts-ignore
    for (const tag of editBonusItem.tags) {
      initTags[tag.id] = tag;

      if (!selectedTags.find((e) => e.id === tag.id)) {
        tagsDeleted[tag.id] = tag;
      }
    }

    const tagsAdded = selectedTags.reduce(
      (acc: { [key: string]: any }, tag) => {
        if (initTags[tag.id]) {
          return acc;
        } else {
          acc[tag.id] = tag;
          return acc;
        }
      },
      {}
    );
    console.log(tagsAdded, "ADDED", tagsDeleted, "DELETED");

    return { tagsAdded, tagsDeleted };
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editBurningItemSchema,
    onSubmit: async (values) => {
      const submitObject = { ...values, usdPrice: String(values.usdPrice) };
      if (!editBonusItem?.id) {
        delete submitObject.id;
        const itemsWithSameTitle = result.filter(
          (item) => item.title === values.title
        );
        const data = await dispatch(
          createBonus({ ...submitObject, isActive: values.isActive || false })
        );

        //if tags changed run tags job ...
        findTagsChanged();

        if (data?.payload?.hash) {
          dispatch(getBonuses({ page: "1", limit: "9999" })).then((data) => {
            const newResult = data.payload.result;
            if (selectedTags.length == 0) {
              dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
            } else {
              const burningItemCreatedId =
                newResult.find(
                  (bonus: BonusSchema) =>
                    bonus.title === values.title &&
                    !itemsWithSameTitle.find((item) => item.id === bonus.id)
                )?.id || "";

              if (burningItemCreatedId) {
                const promises = [];
                for (let tag of selectedTags) {
                  promises.push(
                    dispatch(
                      attachTag({
                        tagId: tag.id,
                        burningItemId: burningItemCreatedId,
                      })
                    )
                  );
                }

                Promise.all(promises)
                  .then(() => {
                    dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
                    dispatch(getBonuses({ page: "1", limit: "9999" }));
                  })
                  .catch((err) => {
                    console.log(err, "PROMICE ALL FAILED");
                  });
              }
            }
          });
        }
      } else {
        let languageSubmit = {};
        for (let i of Object.entries(values)) {
          // //@ts-ignore
          // if (`${i[0]}` !== "id" && editBonusItem[`${i[0]}`] === i[1]) {
          //   //@ts-ignore
          //   delete submitObject[`${i[0]}`];
          // }

          const key = `${i[0]}`;
          if (key === "title" || key === "description") {
            //@ts-ignore
            languageSubmit[key] = i[1];
            // Exclude 'title' and 'description' keys from submitObject
            delete submitObject[key];
            //@ts-ignore
          } else if (key !== "id" && editBonusItem[key] === i[1]) {
            //@ts-ignore
            delete submitObject[key];
          }
        }

        const promises = [];
        if (Object.keys(languageSubmit).length) {
          //@ts-ignore
          if (!!editBonusItem?.title[lang]) {
            promises.push(
              dispatch(
                //@ts-ignore
                updateBurningItemTranslation({
                  ...languageSubmit,
                  burningItemId: editBonusItem.id,
                  language: lang,
                })
              )
            );
          } else {
            promises.push(
              dispatch(
                //@ts-ignore
                createBurningItemTranslation({
                  ...languageSubmit,
                  burningItemId: editBonusItem.id,
                  language: lang,
                })
              )
            );
          }
        }

        promises.push(dispatch(updateBonus(submitObject)));

        const { tagsDeleted, tagsAdded } = findTagsChanged();
        for (let tag of Object.values(tagsDeleted)) {
          promises.push(
            dispatch(
              deAttachTag({ tagId: tag.id, burningItemId: editBonusItem.id })
            )
          );
        }

        for (let tag of Object.values(tagsAdded)) {
          promises.push(
            dispatch(
              attachTag({ tagId: tag.id, burningItemId: editBonusItem.id })
            )
          );
        }

        Promise.all(promises)
          .then(() => {
            setTimeout(() => {
              if (errorMessageSubmit) return;
              dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
              dispatch(getBonuses({ page: "1", limit: "9999" }));
            }, 0);
          })
          .catch((err) => {
            console.log(err, "PROMICE ALL FAILED");
          });
      }
    },
  });

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ 'color': [] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
    ],
    clipboard: {
      matchVisual: false
    }
  };
  
  useEffect(() => {
    if (editBonusItem) {
      dispatch(
        getItemWithTranslation({ language: lang, id: editBonusItem?.id })
      );
    }
  }, [lang]);

  useEffect(() => {
    //@ts-ignore
    formik.setFieldValue("title", editBonusItem?.title[lang]);
    //@ts-ignore
    formik.setFieldValue("description", editBonusItem?.description[lang]);
  }, [editBonusItem]);

  const languageOptions = Object.values(Language).map((name) => ({
    key: name,
    value: name,
    content: { text: name },
  }));

  return (
    <AuthFormWrapper>
      <form onSubmit={formik.handleSubmit}>
        <AuthFormNameWrapper>
          <Text size={TextSize.M} weight={TextWeight.SEMIBOLD}>
            Bonus settings
          </Text>
        </AuthFormNameWrapper>
        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <Input
          label="Burning Item Foreign Id"
          readOnly={editBonusItem && !!editBonusItem.id}
          {...formik.getFieldProps("burningItemForeignId")}
          placeholder={"Burning Item Foreign Id"}
          error={
            (formik.touched.burningItemForeignId &&
              formik.errors.burningItemForeignId) ||
            ""
          }
          maxLength={50}
          required={true}
        />
        {editBonusItem && editBonusItem.id && (
          <DropdownWrapper>
            <InputLabel text={"language"} textSize={10} required={true} />
            <Dropdown
              items={languageOptions}
              width={347}
              listWidth={347}
              size={DropdownSize.S}
              onItemClick={(value) =>
                // setLang(value)
                // console.log(value, 'VALUE')
                //@ts-ignore
                setLang(value)
              }
              selectedValue={lang}
              type={DropdownType.OUTLINED}
              maxVisibleItems={5}

              // width={DropdownListWidth[DropdownSize.M]}
            />
          </DropdownWrapper>
        )}
        <InputWithMargin
          label="Title"
          {...formik.getFieldProps("title")}
          placeholder={"Tilte"}
          error={(formik.touched.title && formik.errors.title) || ""}
          maxLength={50}
          required={true}
        />
        {/* <InputWithMargin
          label="Description"
          {...formik.getFieldProps("description")}
          placeholder={"Description"}
          error={
            (formik.touched.description && formik.errors.description) || ""
          }
          required={true}
        /> */}
        <DescriptionContainer>
          <InputLabel text={"Description"} textSize={10} required={true} />
          <ReactQuill
            modules={modules}
            value={formik.values.description}
            onChange={(value) => formik.setFieldValue("description", value)}
            theme="snow"
          />
          {(formik.touched.description && formik.errors.description) || ""}
        </DescriptionContainer>
        <InputWithMargin
          label="Icon Url"
          {...formik.getFieldProps("iconUrl")}
          placeholder={"Icon Url"}
          error={(formik.touched.iconUrl && formik.errors.iconUrl) || ""}
          required={true}
        />
        <InputWithMargin
          label="USD Price"
          {...formik.getFieldProps("usdPrice")}
          placeholder={"USD Price"}
          error={(formik.touched.usdPrice && formik.errors.usdPrice) || ""}
          required={true}
        />
        <ChipsWrapper>
          <InputLabel text={"Tags"} textSize={10} />
          <InputTags
            items={tagsSelectorFormat || []}
            selectedItems={selectedTags || []}
            unselectItem={(e) => {
              const tagsUpdated = selectedTags.filter((tag) => tag.id !== e);
              setSelectedTags(tagsUpdated);
            }}
            selectItem={(e) => {
              const tagsUpdated = [...selectedTags, { id: e, disabled: false }];
              setSelectedTags(tagsUpdated);
            }}
            placeholder={"Tags"}
          />
        </ChipsWrapper>
        <CheckboxWrapper>
          <CheckboxText size={TextSize.XS} weight={TextWeight.SEMIBOLD}>
            Activated:
          </CheckboxText>
          <Checkbox
            checked={formik.values.isActive}
            onClick={(value) => {
              console.log("clicked", editBonusItem?.id, editBonusItem);
              if (editBonusItem?.burningItemForeignId) {
                console.log(
                  "item_clicked",
                  editBonusItem.id,
                  formik.values.isActive
                );
                if (formik.values.isActive) {
                  dispatch(
                    deactivateBonus({ burningItemId: editBonusItem.id })
                  ).then(() => {
                    formik.setFieldValue("isActive", false);
                  });
                } else {
                  dispatch(
                    activateBonus({ burningItemId: editBonusItem.id })
                  ).then(() => {
                    formik.setFieldValue("isActive", true);
                  });
                }
              } else {
                formik.setFieldValue("isActive", !formik.values.isActive);
              }
            }}
          />
        </CheckboxWrapper>

        <ButtonWrapper>
          <Button
            fullWidth={true}
            customMaxWidth={140}
            size={ButtonSize.L}
            type={HTMLButtonType.SUBMIT}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Confirm
          </Button>
          <ButtonWithLeftMargin>
            <Button
              fullWidth={true}
              customMaxWidth={140}
              size={ButtonSize.L}
              buttonType={ButtonType.WHITE}
              onClick={() =>
                dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }))
              }
            >
              Cancel
            </Button>
          </ButtonWithLeftMargin>
        </ButtonWrapper>
        <div>
          {errorMessageSubmit && (
            <ErrorText color={Color.RED_HOT_HOOD}>
              {errorMessageSubmit}
            </ErrorText>
          )}
        </div>
      </form>
    </AuthFormWrapper>
  );
}
