import React, { useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { Button, ButtonSize } from "../../../shared/components/Button";
import { Text } from "../../../shared/components/Text";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { PageCountEnum } from "../../../constants/PageCountEnum";
import { getResources, updateResource } from "../redux/actions";
import { Modal } from "../../../shared/components/Modal";
import { ResourceAddForm } from "./ResourceAddForm";
import { truncateMiddle } from "../../../utils/utils";
import { resetEditResourceItem } from "../redux/resources.slice";
import { Icon } from "../../../shared/components/Icon";
import { Loading } from "../../../constants/Loading";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import {
  EditableField,
  UpdateValueType,
} from "../../../shared/components/EditableField";

const TableWrapper = styled.div`
  position: relative;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const FirstColumnCell = styled.div`
  display: flex;
  padding-left: 16px;
  width: 25%;
`;

const ColumnCell = styled.div`
  display: flex;
  width: 25%;
`;


const TableRow = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  background-color: ${Color.SECONDARY};
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const LoaderWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
`;

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

export function ResourcesTable() {
  const dispatch = useAppDispatch();
  const {
    result,
    loading,
    loadingUpdate: { id: loadingUpdateId },
  } = useAppSelector((state) => state.resources);
  const [page, setPage] = useState("1");
  const [limit, setLimit] = useState(PageCountEnum.TWO_HUNDRED);
  const showEditResourceModal =
    useAppSelector((state) => state.modalHub.shownModal) ===
    ShowModalEnum.RESOURCE_EDIT_MODAL;

  useEffectSkipFirst(() => {
    dispatch(getResources({ page, limit }));

    //remove after implementation
    setPage("1");
    setLimit(PageCountEnum.TWO_HUNDRED);
  }, []);

  const updateValue = ({ value, fieldName, id }: UpdateValueType) => {
    dispatch(updateResource({ id, [fieldName]: value }));
  };

  return (
    <>
      <TitleTextWrapper>
        <Text size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
          Resources
        </Text>
        <Button
          icon={IconType.lightning}
          size={ButtonSize.L}
          onClick={() => {
            dispatch(resetEditResourceItem());
            dispatch(
              setShownModal({ shownModal: ShowModalEnum.RESOURCE_EDIT_MODAL })
            );
          }}
        >
          Add new
        </Button>
      </TitleTextWrapper>
      {loading === Loading.SUCCESS && (
        <TableWrapper>
          <TableHeader>
            <FirstColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                ID
              </Text>
            </FirstColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Operator ID
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Name
              </Text>
            </ColumnCell>

            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Foreign Resource ID
              </Text>
            </ColumnCell>
          </TableHeader>
          {result.map((item, i) => (
            <TableRow key={"resurcesTableRow_" + i}>
              <FirstColumnCell>
                <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.id && truncateMiddle(item.id)}
                </Text>
              </FirstColumnCell>
              <ColumnCell>
                <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.operatorId && truncateMiddle(item.operatorId)}
                </Text>
              </ColumnCell>
              <ColumnCell>
                <EditableField
                  id={item.id || "777"}
                  fieldName={"name"}
                  value={item.name}
                  updateValue={updateValue}
                  maxWidth={"170px"}
                  isLoading={item.id === loadingUpdateId}
                />
              </ColumnCell>

              <ColumnCell>
                <EditableField
                  id={item.id || "777"}
                  fieldName={"foreignResourceId"}
                  value={item.foreignResourceId}
                  updateValue={updateValue}
                  maxWidth={"170px"}
                  isLoading={item.id === loadingUpdateId}
                />
              </ColumnCell>
            </TableRow>
          ))}
        </TableWrapper>
      )}
      {loading === Loading.PENDING && (
        <LoaderWrap>
          <RewindLoader color={"#233447"} size={110} sizeUnit={"px"} />
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Loading date
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                Please take a deep breath
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {loading === Loading.ERROR && (
        <LoaderWrap>
          <Icon
            icon={IconType.error}
            size={80}
            color={Color.BRIGHT_GRAY}
          ></Icon>
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Something went wrong.
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                No data fetched. Please reload page or come back in few mins
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {showEditResourceModal && (
        <Modal>
          <ResourceAddForm />
        </Modal>
      )}
    </>
  );
}
