import React, { useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { Button, ButtonSize } from "../../../shared/components/Button";
import { Text } from "../../../shared/components/Text";
import { Modal } from "../../../shared/components/Modal";
import { TagAddForm } from "./TagAddForm";
import { truncateMiddle } from "../../../utils/utils";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import { Loading } from "../../../constants/Loading";
import { Icon } from "../../../shared/components/Icon";
import { deleteTag, getAllOperatorsTags } from "../redux/actions";

const TableWrapper = styled.div`
  position: relative;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const FirstColumnCell = styled.div`
  display: flex;
  padding-left: 16px;
  width: 13%;
`;

const ColumnCell = styled.div`
  display: flex;
  width: 15%;
  padding-right: 12px;
`;

const LastCell = styled.div`
  display: flex;
  width: 150px
  padding: 0 6px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  min-height: 44px;
  background-color: ${Color.SECONDARY};
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const LoaderWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
`;

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const DeleteWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
`;

const TextStyled = styled(Text)`
  white-space: nowrap;
`;

export function TagsTable() {
  const dispatch = useAppDispatch();
  const showEditTagsModal =
    useAppSelector((state) => state.modalHub.shownModal) ===
    ShowModalEnum.TAG_EDIT_MODAL;
  const { list, loading } = useAppSelector((state) => state.tags);
  // const [page] = useState("1");
  // const [limit] = useState(PageCountEnum.TWO_HUNDRED);

  useEffectSkipFirst(() => {
    if (loading === Loading.INITIAL) {
      dispatch(getAllOperatorsTags());
    }
  }, []);

  const { errorMessageDelete, loadingDelete, deletedTagId } = useAppSelector((state) => state.tags);

  // const updateValue = ({ value, fieldName, id }: UpdateValueType) => {
  //   dispatch(updateGrade({ id, [fieldName]: value }));
  // };

  return (
    <>
      <TitleTextWrapper>
        <Text size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
          Tags list
        </Text>
        <Button
          icon={IconType.lightning}
          size={ButtonSize.L}
          onClick={() =>
            dispatch(
              setShownModal({ shownModal: ShowModalEnum.TAG_EDIT_MODAL })
            )
          }
        >
          Add new
        </Button>
      </TitleTextWrapper>
      {loading === Loading.SUCCESS && (
        <TableWrapper>
          <TableHeader>
            <FirstColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                ID
              </Text>
            </FirstColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Operator ID
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Name
              </Text>
            </ColumnCell>
            <LastCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Delete tag
              </Text>
            </LastCell>
          </TableHeader>
          {list.map((item, i) => (
            <TableRow key={"gradesTableRow_" + i}>
              <FirstColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.id && truncateMiddle(item.id)}
                </TextStyled>
              </FirstColumnCell>
              <ColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {truncateMiddle(item.operatorId)}
                </TextStyled>
              </ColumnCell>

              <ColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.name}
                </TextStyled>
              </ColumnCell>
              <LastCell>
                <DeleteWrap>
                <Button
                
                  customMaxWidth={68}
                  size={ButtonSize.S}
                  disabled={!loadingDelete}
                  onClick={() => {
                    dispatch(deleteTag({ id: item.id }));
                  }}
                  fullWidth={true}
                >
                  Delete
                </Button>
                <div>
                  {errorMessageDelete && deletedTagId ===item.id && (
                    <Text size={TextSize.XXXS} color={Color.RED_HOT_HOOD}>{errorMessageDelete}</Text>
                  )}
                </div>
                </DeleteWrap>
              </LastCell>
              {/* <ColumnCell>
                <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.operatorId && truncateMiddle(item.operatorId)}
                </Text>
              </ColumnCell>
              <ColumnCell>
                <EditableField
                  id={item.id || "777"}
                  fieldName={"id"}
                  value={item.id}
                  updateValue={updateValue}
                  isLoading={item.id === loadingUpdateId}
                />
              </ColumnCell>
              <ColumnCell>
                <EditableField
                  id={item.id || "777"}
                  fieldName={"mintPercentage"}
                  value={item.mintPercentage}
                  updateValue={updateValue}
                  isLoading={item.id === loadingUpdateId}
                />
              </ColumnCell>
              <ColumnCell>
                <EditableField
                  id={item.id || "777"}
                  fieldName={"name"}
                  value={item.name}
                  updateValue={updateValue}
                  isLoading={item.id === loadingUpdateId}
                />
              </ColumnCell>
              <ColumnCell>
                <EditableField
                  id={item.id || "777"}
                  fieldName={"poolPercentage"}
                  value={item.poolPercentage}
                  updateValue={updateValue}
                  isLoading={item.id === loadingUpdateId}
                />
              </ColumnCell> */}
            </TableRow>
          ))}
        </TableWrapper>
      )}
      {loading === Loading.PENDING && (
        <LoaderWrap>
          <RewindLoader color={"#233447"} size={110} sizeUnit={"px"} />
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Loading date
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                Please take a deep breath
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {loading === Loading.ERROR && (
        <LoaderWrap>
          <Icon
            icon={IconType.error}
            size={80}
            color={Color.BRIGHT_GRAY}
          ></Icon>
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Something went wrong.
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                No data fetched. Please reload page or come back in few mins
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {showEditTagsModal && (
        <Modal>
          <TagAddForm />
        </Modal>
      )}
    </>
  );
}
