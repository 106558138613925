import rocket from './rocket';
import settings from './settings';
import close from './close';
import success from './success';
import loader from './loader';
import error from './error';
import arrow from './arrow';
import shevron from './shevron';
import lightning from './lightning';
import visible from './visible';
import visibleNot from './visibleNot';
import cross from './cross';
import checkmarkChecked from './checkmarkChecked';
import checkmarkPartial from './checkmarkPartial';
import editPencil from './editPencil';
import editCancel from './editCancel';

import editSubmit from './editSubmit';
import chevronDown from './chevronDown';
import exclamation from './Exclamation';
import chevronLeft from './chevronLeft';
import chevronRight from './chevronRight';
import calendar from './calendar';

const svgs = {
    rocket,
    settings,
    close,
    success,
    loader,
    error,
    arrow,
    shevron,
    lightning,
    visible,
    visibleNot,
    cross,
    checkmarkChecked,
    checkmarkPartial,
    editCancel,
    editSubmit,
    editPencil,
    chevronDown,
    exclamation,
    calendar,
    chevronLeft,
    chevronRight
};

export default svgs;