import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getAllOperatorsTags = createAsyncThunk(
  "tags/getAllOperatorsTags",
  async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/tags-by-operator`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const createTag = createAsyncThunk(
  "tags/createTag",
  async ({ name }: { name: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/operator/create-tag`,
        { name },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      return data;
    } catch (err) {
      //@ts-ignore
      if (!err.response) {
        throw err;
      }
      console.log(err, "DATE");
      //@ts-ignore
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const deleteTag = createAsyncThunk(
  "tags/deleteTag",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/operator/remove-tag`,
        { id },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      return data;
    } catch (err) {
      //@ts-ignore
      if (!err.response) {
        throw err;
      }
      //@ts-ignore
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const attachTag = createAsyncThunk(
  "tags/attachTag",
  async ({
    tagId,
    burningItemId,
  }: {
    tagId: string;
    burningItemId: string;
  }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/attach-tag`,
      { tagId, burningItemId },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const deAttachTag = createAsyncThunk(
  "tags/deAttachTag",
  async ({
    tagId,
    burningItemId,
  }: {
    tagId: string;
    burningItemId: string;
  }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/unattach-tag`,
      { tagId, burningItemId },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);
