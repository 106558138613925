import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { GetBonusesShema } from "../../Bonuses/redux/actions";

export const getFailedEvents = createAsyncThunk(
  "failed-events/getFailedEvents",
  async ({ page, limit }: GetBonusesShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/get-failed-events?limit=${limit}&page=${page}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);
