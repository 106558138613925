import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Input } from "../../../shared/components/Input";
import {
  Button,
  ButtonSize,
  ButtonType,
  HTMLButtonType,
} from "../../../shared/components/Button";

import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { PageCountEnum } from "../../../constants/PageCountEnum";

const InputWithMargin = styled(Input)`
  margin-top: 20px;
`;

const AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const AuthFormNameWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-top: 27px;
`;

const ErrorText = styled(Text)`
  margin-top: 10px;
`;

const ButtonWithLeftMargin = styled.div`
  margin-left: 16px;
`;

const editTranslationItemSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(250, "Maximum 250 symbols")
    .required("Title is required"),
  // foreignResourceId: Yup.string().required("Foreign Resource Id is required"),
});

export function TranslationAddForm() {
  const dispatch = useAppDispatch();
  const { errorMessageSubmit } = useAppSelector((state) => state.resources);

  // const editItem = useAppSelector(
  //   (state) => state.translation.editTranslateItem
  // );

  const initialValues = {
    name:  "",
    id: "",
    english: "",
    russian: "",
    // foreignResourceId: resourceItem?.foreignResourceId || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editTranslationItemSchema,
    onSubmit: async (values) => {
      const submitObject: {
        name: string;
        russian: string,
        english: string
      } = values;
      // if (!editItem?.id) {
      //   delete submitObject.id;
      // }
      // const data = await dispatch(createResource({ ...values }));
      // if (data?.payload?.hash) {
        dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
        // dispatch(getTranslations({ page: "1", limit: PageCountEnum.TWO_HUNDRED }));
      // }
    },
  });

  return (
    <AuthFormWrapper>
      <form onSubmit={formik.handleSubmit}>
        <AuthFormNameWrapper>
          <Text size={TextSize.M} weight={TextWeight.SEMIBOLD}>
            Create new translation
          </Text>
        </AuthFormNameWrapper>

        {/* <Input
          label="English"
          {...formik.getFieldProps("english")}
          placeholder={"English"}
          error={
            (formik.touched.english &&
              formik.errors.english) ||
            ""
          }
        /> */}
        <InputWithMargin
          label="Name"
          {...formik.getFieldProps("name")}
          placeholder={"Name"}
          error={(formik.touched.name && formik.errors.name) || ""}
        />

        <InputWithMargin
          label="English"
          {...formik.getFieldProps("english")}
          placeholder={"English"}
          error={(formik.touched.english && formik.errors.english) || ""}
        />

        <InputWithMargin
          label="Russian"
          {...formik.getFieldProps("russian")}
          placeholder={"Russian"}
          error={(formik.touched.russian && formik.errors.russian) || ""}
        />

        <ButtonWrapper>
          <Button
            fullWidth={true}
            customMaxWidth={140}
            size={ButtonSize.L}
            type={HTMLButtonType.SUBMIT}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Confirm
          </Button>
          <ButtonWithLeftMargin>
            <Button
              onClick={() =>
                dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }))
              }
              fullWidth={true}
              customMaxWidth={140}
              size={ButtonSize.L}
              buttonType={ButtonType.WHITE}
            >
              Cancel
            </Button>
          </ButtonWithLeftMargin>
        </ButtonWrapper>
        <div>
          {errorMessageSubmit && (
            <ErrorText color={Color.RED_HOT_HOOD}>
              {errorMessageSubmit}
            </ErrorText>
          )}
        </div>
      </form>
    </AuthFormWrapper>
  );
}
