import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Text } from "../../../shared/components/Text";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import { Loading } from "../../../constants/Loading";
import { Icon } from "../../../shared/components/Icon";
import {
  ScrollArea,
  ScrollAreaSize,
} from "../../../shared/components/ScrollArea";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { truncateMiddle } from "../../../utils/utils";
import {
  ITEMS_PER_PAGE,
  UsersListPagination,
} from "../../../shared/components/UsersListPagination";
import HiddenTooltip, {
  TooltipContent,
} from "../../../shared/components/HiddenTooltip";

const TableWrapper = styled.div`
  position: relative;
  min-width: 1200px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const FirstColumnCell = styled.div`
  display: flex;
  padding-left: 16px;
  width: 12%;
`;

const ColumnCell = styled.div`
  display: flex;
  width: 12%;
  padding-right: 12px;
  position: relative;
`;

const ColumnSmallCell = styled.div`
  display: flex;
  width: 5%;
  padding-right: 12px;
  position: relative;
`;

const ColumnLargeCell = styled.div`
  display: flex;
  width: 18%;
  padding-right: 12px;
  position: relative;
`;

const LastCell = styled.div`
  display: flex;
  justify-content: center;
  width: 18%;
  padding-right: 12px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  background-color: ${Color.SECONDARY};
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const LoaderWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
`;

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const TextStyled = styled(Text)`
  white-space: nowrap;
`;

const eventStatus: { [key: number]: { title: string } } = {
  1: { title: "Claimed" },
  2: { title: "Not Claimed" },
  3: { title: "Expired" },
  4: { title: "Sent To JackPot" },
  5: { title: "In Progress" },
  6: { title: "Failed" },
  7: { title: "Mined" },
  8: { title: "Claim Transaction" },
};

export function ClientJournalReport() {
  const { clientJournalEvents: tabletData } = useAppSelector(
    (state) => state.events
  );

  return (
    <>
      {tabletData.loading === Loading.SUCCESS && (
        <ScrollArea
          size={ScrollAreaSize.S}
          autoHeightMin={132}
          autoHeightMax={900}
          autoHeight
        >
          <TableWrapper>
            <TableHeader>
              <FirstColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Journal Id
                </Text>
              </FirstColumnCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Client Inner Id
                </Text>
              </ColumnCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Client Foreign Id
                </Text>
              </ColumnCell>
              <ColumnLargeCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Event Date
                </Text>
              </ColumnLargeCell>
              <ColumnLargeCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Amount
                </Text>
              </ColumnLargeCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Status
                </Text>
              </ColumnCell>
              <LastCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Created At
                </Text>
              </LastCell>
            </TableHeader>
            {tabletData.result &&
              tabletData.result.map((item, i) => (
                <TableRow key={"clientEventTableRow_" + i}>
                  <FirstColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {truncateMiddle(item.journalid)}
                    </TextStyled>
                  </FirstColumnCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {truncateMiddle(item.clientinnerid)}
                    </TextStyled>
                  </ColumnCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.clientforeignid}
                    </TextStyled>
                  </ColumnCell>

                  <ColumnLargeCell>
                    <HiddenTooltip
                      timerDuration={0}
                      content={
                        <TooltipContent
                          color={Color.SECONDARY}
                          size={TextSize.XS}
                          weight={TextWeight.MEDIUM}
                        >
                          {moment(item.eventdate).format("MM-DD-YYYY hh:mm a")}
                        </TooltipContent>
                      }
                    >
                      <TextStyled
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                        ellipsis
                      >
                        {moment(item.eventdate).format("MM-DD-YYYY")}
                      </TextStyled>
                    </HiddenTooltip>
                  </ColumnLargeCell>

                  <ColumnLargeCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.amount / 10e17}
                    </TextStyled>
                  </ColumnLargeCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.statusid && eventStatus[item.statusid].title}
                    </TextStyled>
                  </ColumnCell>

                  <LastCell>
                    <HiddenTooltip
                      timerDuration={0}
                      content={
                        <TooltipContent
                          color={Color.SECONDARY}
                          size={TextSize.XS}
                          weight={TextWeight.MEDIUM}
                        >
                          {moment(item.eventdate).format("MM-DD-YYYY hh:mm a")}
                        </TooltipContent>
                      }
                    >
                      <TextStyled
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                        ellipsis
                      >
                        {moment(item.createdat).format("MM-DD-YYYY")}
                      </TextStyled>
                    </HiddenTooltip>
                  </LastCell>
                </TableRow>
              ))}
          </TableWrapper>
        </ScrollArea>
      )}
      {tabletData.loading === Loading.PENDING && (
        <LoaderWrap>
          <RewindLoader color={"#233447"} size={110} sizeUnit={"px"} />
          <TextSection>
            <TextStyled size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Loading date
            </TextStyled>
            <div>
              <TextStyled size={TextSize.L} weight={TextWeight.MEDIUM}>
                Please take a deep breath
              </TextStyled>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {tabletData.loading === Loading.ERROR && (
        <LoaderWrap>
          <Icon
            icon={IconType.error}
            size={80}
            color={Color.BRIGHT_GRAY}
          ></Icon>
          <TextSection>
            <TextStyled size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Something went wrong.
            </TextStyled>
            <div>
              <TextStyled size={TextSize.L} weight={TextWeight.MEDIUM}>
                No data fetched. Please reload page or come back in few mins
              </TextStyled>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {!!tabletData.count && tabletData.count > ITEMS_PER_PAGE && (
        <UsersListPagination
          pathname="/events"
          total={Number(tabletData.count)}
        />
      )}
    </>
  );
}
