import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { testAction } from "./actions";

export type HeaderState = {
  isMobile: boolean;
};

export const initialState: HeaderState = {
  isMobile: false,
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setIsMobile: (state, action: PayloadAction<{ status: boolean }>) => {
      state.isMobile = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(testAction.pending, (state) => {});
  },
});

export const { setIsMobile } = headerSlice.actions;

export default headerSlice.reducer;
