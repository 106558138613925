import React from "react";

/* eslint-disable max-len */
export default (
  <svg
    width="115"
    height="18"
    viewBox="0 0 115 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.9569 6.40982C53.3034 5.86123 53.7526 5.43109 54.3045 5.11939C54.8693 4.80769 55.511 4.65184 56.2298 4.65184V7.40103H55.5175C54.6703 7.40103 54.0286 7.59428 53.5922 7.98079C53.1687 8.36729 52.9569 9.04056 52.9569 10.0006V15.1623H50.2615V4.80145H52.9569V6.40982Z"
      fill="#364159"
    />
    <path
      d="M67.9602 9.75747C67.9602 10.1315 67.9346 10.4681 67.8832 10.7674H60.086C60.1502 11.5155 60.4197 12.1015 60.8946 12.5254C61.3695 12.9493 61.9535 13.1612 62.6466 13.1612C63.6477 13.1612 64.36 12.7436 64.7836 11.9082H67.6907C67.3827 12.9056 66.7923 13.7285 65.9195 14.3769C65.0467 15.0127 63.975 15.3307 62.7043 15.3307C61.6775 15.3307 60.7534 15.1125 59.932 14.6761C59.1234 14.2272 58.488 13.5976 58.026 12.7872C57.5768 11.9768 57.3521 11.0417 57.3521 9.9819C57.3521 8.90965 57.5768 7.96832 58.026 7.1579C58.4752 6.34748 59.1041 5.72408 59.9127 5.2877C60.7213 4.85132 61.6519 4.63313 62.7043 4.63313C63.7183 4.63313 64.6232 4.84509 65.4189 5.269C66.2275 5.69291 66.85 6.29761 67.2864 7.08309C67.7356 7.85611 67.9602 8.74757 67.9602 9.75747ZM65.1686 9.0094C65.1558 8.33612 64.9055 7.8 64.4178 7.40103C63.9301 6.98958 63.3332 6.78386 62.6273 6.78386C61.9599 6.78386 61.3952 6.98335 60.9331 7.38232C60.4839 7.76883 60.2079 8.31119 60.1052 9.0094H65.1686Z"
      fill="#364159"
    />
    <path
      d="M84.7135 4.80145L81.5946 15.1623H78.6875L76.743 7.92468L74.7985 15.1623H71.8721L68.7339 4.80145H71.4678L73.3545 12.6937L75.3953 4.80145H78.2447L80.2469 12.675L82.1337 4.80145H84.7135Z"
      fill="#364159"
    />
    <path
      d="M89.1431 3.56712C88.6683 3.56712 88.2704 3.42374 87.9495 3.13698C87.6415 2.83775 87.4874 2.46994 87.4874 2.03356C87.4874 1.59718 87.6415 1.23561 87.9495 0.948847C88.2704 0.649616 88.6683 0.5 89.1431 0.5C89.618 0.5 90.0095 0.649616 90.3175 0.948847C90.6384 1.23561 90.7989 1.59718 90.7989 2.03356C90.7989 2.46994 90.6384 2.83775 90.3175 3.13698C90.0095 3.42374 89.618 3.56712 89.1431 3.56712ZM88.8672 4.80145V15.1623H86.1718V4.80145H88.8672Z"
      fill="#364159"
    />
    <path
      d="M97.4407 4.65184C98.7114 4.65184 99.7382 5.04458 100.521 5.83006C101.304 6.60308 101.695 7.68779 101.695 9.0842V15.1623H99.0001V9.43954C99.0001 8.61665 98.7884 7.98702 98.3648 7.55064C97.9413 7.10179 97.3637 6.87737 96.6321 6.87737C95.8877 6.87737 95.2973 7.10179 94.8609 7.55064C94.4373 7.98702 94.2255 8.61665 94.2255 9.43954V15.1623H91.5302V4.80145H94.2255V6.09189C94.5849 5.64304 95.0406 5.29394 95.5925 5.04458C96.1572 4.78275 96.7733 4.65184 97.4407 4.65184Z"
      fill="#364159"
    />
    <path
      d="M103.566 9.94449C103.566 8.89718 103.777 7.96832 104.201 7.1579C104.637 6.34748 105.228 5.72408 105.972 5.2877C106.717 4.85132 107.544 4.63313 108.456 4.63313C109.149 4.63313 109.81 4.78275 110.439 5.08198C111.068 5.36875 111.568 5.75525 111.94 6.2415V1.32289H114.674V15.1623H111.94V13.6288C111.607 14.14 111.138 14.5514 110.535 14.8631C109.932 15.1748 109.232 15.3307 108.436 15.3307C107.538 15.3307 106.717 15.1062 105.972 14.6574C105.228 14.2085 104.637 13.5789 104.201 12.7685C103.777 11.9456 103.566 11.0043 103.566 9.94449ZM111.96 9.9819C111.96 9.34603 111.831 8.80367 111.575 8.35483C111.318 7.89351 110.971 7.54441 110.535 7.30752C110.099 7.05816 109.63 6.93348 109.129 6.93348C108.629 6.93348 108.167 7.05192 107.743 7.28881C107.32 7.52571 106.973 7.87481 106.704 8.33612C106.447 8.78497 106.319 9.32109 106.319 9.94449C106.319 10.5679 106.447 11.1165 106.704 11.5903C106.973 12.0516 107.32 12.4069 107.743 12.6563C108.18 12.9056 108.642 13.0303 109.129 13.0303C109.63 13.0303 110.099 12.9119 110.535 12.675C110.971 12.4256 111.318 12.0765 111.575 11.6277C111.831 11.1664 111.96 10.6178 111.96 9.9819Z"
      fill="#364159"
    />
    <path
      opacity="0.2"
      d="M8.02179 9.70754C8.02179 5.40387 4.43029 1.91505 -5.8623e-05 1.91506L-5.72205e-05 17.5C4.43029 17.5 8.02179 14.0112 8.02179 9.70754Z"
      fill="#364159"
    />
    <path
      opacity="0.4"
      d="M19.2523 9.70754C19.2523 5.40387 15.6608 1.91505 11.2305 1.91505L11.2305 17.5C15.6608 17.5 19.2523 14.0112 19.2523 9.70754Z"
      fill="#364159"
    />
    <path
      opacity="0.6"
      d="M30.4828 9.70754C30.4828 5.40387 26.8913 1.91505 22.4609 1.91505L22.4609 17.5C26.8913 17.5 30.4828 14.0112 30.4828 9.70754Z"
      fill="#364159"
    />
    <path
      d="M41.7133 9.70754C41.7133 5.40387 38.1218 1.91505 33.6914 1.91505L33.6914 17.5C38.1218 17.5 41.7133 14.0112 41.7133 9.70754Z"
      fill="#364159"
    />
  </svg>
);
