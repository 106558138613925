import styled from "styled-components";
import { DateRangePicker, DateRangePosition, Range } from "./DateRangePicker";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setFromAndToDates } from "../redux/events.slice";
import { useState } from "react";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";

const DATERANGE_BUTTON = "dateRangeButton";
const DateRangePickerContainer = styled.div`
  & > div > div > div {
    align-self: end;
    input {
      cursor: default;
    }
  }
`;

const DatePicker = () => {
  const dispatch = useAppDispatch();
  const { from, to } = useAppSelector((state) => state.events);

  const [state, setState] = useState({
    startDate: from,
    endDate: to,
    key: "selection",
  });

  function onApply(range: Range) {
    if (range.startDate && range.endDate)
      dispatch(setFromAndToDates({ from: range.startDate, to: range.endDate }));
  }

  return (
    <DateRangePickerContainer>
      {from && to && (
        <DateRangePicker
          range={state}
          onApply={onApply}
          maxDate={new Date()}
          position={DateRangePosition.END}
          buttonClassName={DATERANGE_BUTTON}
        ></DateRangePicker>
      )}
    </DateRangePickerContainer>
  );
};
export default DatePicker;
