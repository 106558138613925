import { FC } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { RoutesTypes } from "../constants/RoutesTypes";
import { AuthForm } from "../features/auth/components/AuthForm";
import { BonusPage } from "../features/Bonuses/page/BonusPage";
import { DashboardPage } from "../features/dashboard/page/DashboardPage";
import { Events } from "../features/Events/components/Events";
import { FailedEventsTable } from "../features/FailedEvents/components/Table";
import { GradesTable } from "../features/Grades/components/GradesTable";
import { ResourcesTable } from "../features/Resources/components/Table";
import { TagsTable } from "../features/Tags/components/TagsTable";
import { TranslationPage } from "../features/Translation/page/DashboardPage";
import { WebhooksTable } from "../features/Webhooks/components/Table";
import { MainLayout } from "../layouts/Main";
import PrivateRoute from "./PrivateRoute";
import { UserManagement } from "../features/UserManagement/components/UserManagement";

const AppRoutes: FC = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<MainLayout />}>
            {/* <Route path={RoutesTypes.dashboard} element={<DashboardPage />} /> */}
            <Route path={RoutesTypes.cashback} element={<ResourcesTable />} />
            <Route path={RoutesTypes.grades} element={<GradesTable />} />
            <Route path={RoutesTypes.burning_items} element={<BonusPage />} />
            <Route
              path={RoutesTypes.failed_events}
              element={<FailedEventsTable />}
            />
            <Route
              path={RoutesTypes.translations}
              element={<TranslationPage />}
            />
            <Route
              path={RoutesTypes.user_management}
              element={<UserManagement />}
            />
            <Route path={RoutesTypes.webhooks} element={<WebhooksTable />} />
            <Route path={RoutesTypes.bonuses_tags} element={<TagsTable />} />
            <Route path={RoutesTypes.events} element={<Events />} />
          </Route>
        </Route>
        <Route element={<AuthForm />} path={RoutesTypes.auth} />
        <Route path="*" element={<Navigate to={RoutesTypes.auth} />} />
      </Routes>
    </Router>
  );
};

export { AppRoutes };
