const formatDate = (date: Date | string, time = false) => {
    if (!date) return '';

    let format: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour12: false,
    };

    if (time) {
        format = { ...format, ...{ hour: 'numeric', minute: 'numeric' } };
    }

    return new Intl.DateTimeFormat('en-US', format).format(new Date(date)).replace(/\//g, '.');
};

export default formatDate;
