import React, { useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Text } from "../../../shared/components/Text";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { PageCountEnum } from "../../../constants/PageCountEnum";
import { Icon } from "../../../shared/components/Icon";
import { Loading } from "../../../constants/Loading";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import { getFailedEvents } from "../redux/actions";
import { truncateMiddle } from "../../../utils/utils";
import moment from "moment";
import HiddenTooltip, {
  TooltipContent,
} from "../../../shared/components/HiddenTooltip";

const TableWrapper = styled.div`
  position: relative;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const FirstColumnCell = styled.div`
  display: flex;
  padding-left: 16px;
  width: 5%;
`;

const ColumnCell = styled.div`
  display: flex;
  width: 16%;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  background-color: ${Color.SECONDARY};
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const LoaderWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
`;

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const TextStyled = styled(Text)`
  white-space: nowrap;
`;

export function FailedEventsTable() {
  const dispatch = useAppDispatch();
  const { result, loading } = useAppSelector((state) => state.failedEvents);
  const [page, setPage] = useState("1");
  const [limit, setLimit] = useState(PageCountEnum.TWO_HUNDRED);

  useEffectSkipFirst(() => {
    dispatch(getFailedEvents({ page, limit }));

    //remove after implementation
    setPage("1");
    setLimit(PageCountEnum.TWO_HUNDRED);
  }, []);

  return (
    <>
      <TitleTextWrapper>
        <Text size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
          Failed Events
        </Text>
      </TitleTextWrapper>
      {loading === Loading.SUCCESS && (
        <TableWrapper>
          <TableHeader>
            <FirstColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                ID
              </Text>
            </FirstColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Created At
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Operator ID
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Event ID
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Amount
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Resource ID
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                User ID
              </Text>
            </ColumnCell>
          </TableHeader>
          {result.map((item, i) => (
            <TableRow key={"failedEventsRow_" + i}>
              <FirstColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.id}
                </TextStyled>
              </FirstColumnCell>
              <ColumnCell>
                <HiddenTooltip
                  timerDuration={0}
                  content={
                    <TooltipContent
                      color={Color.SECONDARY}
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                    >
                      {moment(item.created_at).format("MM-DD-YYYY hh:mm a")}
                    </TooltipContent>
                  }
                >
                  <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                    {moment(item.created_at).format("MM-DD-YYYY")}
                  </TextStyled>
                </HiddenTooltip>
              </ColumnCell>
              <ColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.operator_id && truncateMiddle(item.operator_id)}
                </TextStyled>
              </ColumnCell>
              <ColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.event_body.eventId &&
                    truncateMiddle(item.event_body.eventId)}
                </TextStyled>
              </ColumnCell>
              <ColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.event_body.amount}
                </TextStyled>
              </ColumnCell>
              <ColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.event_body.resourceId}
                </TextStyled>
              </ColumnCell>
              <ColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.event_body.userId &&
                    truncateMiddle(item.event_body.userId)}
                </TextStyled>
              </ColumnCell>
            </TableRow>
          ))}
        </TableWrapper>
      )}
      {loading === Loading.PENDING && (
        <LoaderWrap>
          <RewindLoader color={"#233447"} size={110} sizeUnit={"px"} />
          <TextSection>
            <TextStyled size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Loading date
            </TextStyled>
            <div>
              <TextStyled size={TextSize.L} weight={TextWeight.MEDIUM}>
                Please take a deep breath
              </TextStyled>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {loading === Loading.ERROR && (
        <LoaderWrap>
          <Icon
            icon={IconType.error}
            size={80}
            color={Color.BRIGHT_GRAY}
          ></Icon>
          <TextSection>
            <TextStyled size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Something went wrong.
            </TextStyled>
            <div>
              <TextStyled size={TextSize.L} weight={TextWeight.MEDIUM}>
                No data fetched. Please reload page or come back in few mins
              </TextStyled>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
    </>
  );
}
