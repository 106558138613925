import React, { useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Text } from "../../../shared/components/Text";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { PageCountEnum } from "../../../constants/PageCountEnum";
import { Icon } from "../../../shared/components/Icon";
import { Loading } from "../../../constants/Loading";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import { getWebhooks } from "../redux/actions";
import { truncateMiddle } from "../../../utils/utils";
import moment from "moment";
import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { Modal } from "../../../shared/components/Modal";
import { WebhookSchema } from "../redux/webhooks.slice";
import HiddenTooltip, {
  TooltipContent,
} from "../../../shared/components/HiddenTooltip";

const TableWrapper = styled.div`
  position: relative;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const FirstColumnCell = styled.div`
  display: flex;
  padding-left: 16px;
  width: 15%;
`;

const ColumnCell = styled.div`
  display: flex;
  width: 19%;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  background-color: ${Color.SECONDARY};
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const LastColumnCell = styled.div`
  width: 9%;
`;

const IconWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LoaderWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
`;

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const TextStyled = styled(Text)`
  white-space: nowrap;
`;

export function WebhooksTable() {
  const dispatch = useAppDispatch();
  const { result, loading } = useAppSelector((state) => state.webhooks);
  const [page, setPage] = useState("1");
  const [limit, setLimit] = useState(PageCountEnum.TWO_HUNDRED);
  const showWebhookInfoModal =
    useAppSelector((state) => state.modalHub.shownModal) ===
    ShowModalEnum.WEBHOOK_INFO;

  useEffectSkipFirst(() => {
    dispatch(getWebhooks({ page, limit }));

    //remove after implementation
    setPage("1");
    setLimit(PageCountEnum.TWO_HUNDRED);
  }, []);
  const [webhookInfo, setWebhookInfo] = useState<WebhookSchema>();

  return (
    <>
      <TitleTextWrapper>
        <Text size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
          Webhooks
        </Text>
      </TitleTextWrapper>
      {loading === Loading.SUCCESS && (
        <TableWrapper>
          <TableHeader>
            <FirstColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                ID
              </Text>
            </FirstColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Operator ID
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Timestamp
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Status code
              </Text>
            </ColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Webhook URL
              </Text>
            </ColumnCell>
            <LastColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                See details
              </Text>
            </LastColumnCell>
          </TableHeader>
          {result.map((item, i) => (
            <TableRow key={"webhooksRow_" + i}>
              <FirstColumnCell>
                <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.id && truncateMiddle(item.id)}
                </Text>
              </FirstColumnCell>
              <ColumnCell>
                <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.operator_id && truncateMiddle(item.operator_id)}
                </Text>
              </ColumnCell>
              <ColumnCell>
                <HiddenTooltip
                  timerDuration={0}
                  content={
                    <TooltipContent
                      color={Color.SECONDARY}
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                    >
                      {moment(item.timestamp).format("MM-DD-YYYY hh:mm a")}
                    </TooltipContent>
                  }
                >
                  <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                    {moment(item.timestamp).format("MM-DD-YYYY")}
                  </Text>
                </HiddenTooltip>
              </ColumnCell>
              <ColumnCell>
                <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.status_code}
                </Text>
              </ColumnCell>
              <ColumnCell>
                <TextStyled size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  {item.notification_url}
                </TextStyled>
              </ColumnCell>
              <LastColumnCell>
                <IconWrapper
                  onClick={() => {
                    setWebhookInfo(item);
                    dispatch(
                      setShownModal({
                        shownModal: ShowModalEnum.WEBHOOK_INFO,
                      })
                    );
                  }}
                >
                  <Icon icon={IconType.visible}></Icon>
                </IconWrapper>
              </LastColumnCell>
            </TableRow>
          ))}
        </TableWrapper>
      )}
      {loading === Loading.PENDING && (
        <LoaderWrap>
          <RewindLoader color={"#233447"} size={110} sizeUnit={"px"} />
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Loading date
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                Please take a deep breath
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {loading === Loading.ERROR && (
        <LoaderWrap>
          <Icon
            icon={IconType.error}
            size={80}
            color={Color.BRIGHT_GRAY}
          ></Icon>
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Something went wrong.
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                No data fetched. Please reload page or come back in few mins
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {showWebhookInfoModal && (
        <Modal>
          <>
            <Text size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
              Webhook Info
            </Text>
            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Request body
              </Text>
            </div>
            <>{webhookInfo?.request_body}</>

            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Request header
              </Text>
            </div>
            <>{webhookInfo?.request_header}</>
            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Response body
              </Text>
            </div>
            <>{webhookInfo?.response_body}</>
            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Response header
              </Text>
            </div>
            <>{webhookInfo?.response_header}</>
          </>
        </Modal>
      )}
    </>
  );
}
