import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { Loading } from "../../../constants/Loading";
import { parseJwt } from "../../../utils/utils";
import { setAuth } from "./actions";

export enum OperatorRole {
  MASTER = 'master',
  ANALYST = 'analyst',
  CRM = 'crm'
}

export interface IUserInfo {
  email: string;
  expiresIn: number;
  token: string;
  avatar?: string;
  role: OperatorRole;
}

export type AuthState = {
  loading: Loading;
  userInfo?: IUserInfo;
  errorMessage?: string;
};

export const initialState: AuthState = {
  loading: Loading.INITIAL,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {
      Cookies.remove('token');
      Cookies.remove('email');
      Cookies.remove('role');
      Cookies.remove('expires_in');
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAuth.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(setAuth.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      const { role  } = parseJwt(action.payload.token);
      console.log(parseJwt(action.payload.token), role, 'ROLE')
      state.userInfo = {...action.payload, role};
      Cookies.set('token', action.payload.token);
      Cookies.set('email', action.payload.email);
      Cookies.set('role', role);
      Cookies.set('expires_in', action.payload.expiresIn);
    });
    builder.addCase(setAuth.rejected, (state, action) => {
      state.errorMessage = action.error.message;
      state.loading = Loading.ERROR;
    });
  },
});

export const {logout} = authSlice.actions;

export default authSlice.reducer;
