import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Text } from './Text';

interface HiddenTooltipProps {
  content: JSX.Element;
  timerDuration: number;
  children: JSX.Element;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
//   width: fit-con;
  justify-content: flex-start;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: -51px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 100%;
`;

export const TooltipContent = styled(Text)`
  text-align: center;
  width: 160px;
  padding: 12px;
  border-radius: 4px;
  gap: 12px;
  background: rgba(10, 12, 20, 0.8);
`;


const HiddenTooltip: React.FC<HiddenTooltipProps> = ({ content, timerDuration, children }) => {
  const [showElement, setShowElement] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setShowElement(true);
  };

  const handleMouseLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setShowElement(false);
    }, timerDuration);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {showElement && <TooltipWrapper>{content}</TooltipWrapper>}
    </Wrapper>
  );
};

export default HiddenTooltip;
