import { createSlice } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { getWebhooks } from "./actions";

export type WebhookSchema = {
  created_at: Date;
  event_id: string;
  id: string;
  notification_url: string;
  operator_id: string;
  request_body: string;
  request_header: string;
  response_body: string;
  response_header: string;
  status_code: "SUCCESS" | "FAILED";
  timestamp: Date;
  updated_at: Date;
};

export type ResourcesState = {
  result: WebhookSchema[];
  count?: number;
  loading: Loading;
  errorMessage?: string;
};

export const initialState: ResourcesState = {
  result: [],
  loading: Loading.INITIAL,
};

export const webhooksSlice = createSlice({
  name: "webhooks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWebhooks.pending, (state, action) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getWebhooks.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.result = action.payload.result;
      state.count = action.payload.count;
    });
    builder.addCase(getWebhooks.rejected, (state, action) => {
      state.loading = Loading.ERROR;
      state.errorMessage = action.error.message;
    });
  },
});

export default webhooksSlice.reducer;
