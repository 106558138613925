import { createSlice } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { createTag, deleteTag, getAllOperatorsTags } from "./actions";

export type ITag = {
  createdAt: string
  id: string
  name: string
  operatorId: string
  updatedAt: string
}

export type TagsState = {
  loadingCreate: Loading;
  errorMessageCreate?: string;
  loadingDelete: Loading;
  errorMessageDelete?: string;
  deletedTagId?: string;
  loading: Loading;
  errorMessageGet?: string;
  list: ITag[]
};

export const initialState: TagsState = {
  list: [],
  loadingCreate: Loading.INITIAL,
  loadingDelete: Loading.INITIAL,
  loading: Loading.INITIAL,
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllOperatorsTags.pending, (state) => {
      state.loading = Loading.PENDING;
      state.errorMessageGet = "";
    });

    builder.addCase(getAllOperatorsTags.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.list = action.payload;
    });
    builder.addCase(getAllOperatorsTags.rejected, (state, action) => {
      state.loading = Loading.ERROR;

      if (typeof action.payload === 'string') {
        state.errorMessageGet = action.payload;
      }
    });


    builder.addCase(createTag.pending, (state) => {
      state.loadingCreate = Loading.PENDING;
      state.errorMessageCreate = "";
    });
    builder.addCase(createTag.fulfilled, (state, action) => {
      state.loadingCreate = Loading.SUCCESS;
      state.list = [...state.list, action.payload]
    });
    builder.addCase(createTag.rejected, (state, action) => {
      state.loadingCreate = Loading.ERROR;

      if (typeof action.payload === 'string') {
        state.errorMessageCreate = action.payload;
      }
    });

    builder.addCase(deleteTag.pending, (state, action) => {
      state.loadingDelete = Loading.PENDING;
      state.deletedTagId = action.meta.arg.id;
      state.errorMessageDelete = "";
    });
    builder.addCase(deleteTag.fulfilled, (state, action) => {
      state.loadingDelete = Loading.SUCCESS;
      const filteredState = state.list.filter(item => item.id !== action.meta.arg.id)
      state.list = filteredState;
    });
    builder.addCase(deleteTag.rejected, (state, action) => {
      state.loadingDelete = Loading.ERROR;

      if (typeof action.payload === 'string') {
        state.errorMessageDelete = action.payload;
      }
    });
    
    //attach - deattach
  },
});

export default tagsSlice.reducer;
