import React from "react";

export default (
  <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1a1 1 0 0 0-1 1v1H3.33A2.33 2.33 0 0 0 1 5.33v9.34A2.33 2.33 0 0 0 3.33 17h11.34A2.33 2.33 0 0 0 17 14.67V5.33A2.33 2.33 0 0 0 14.67 3H13V2a1 1 0 1 0-2 0v1H7V2a1 1 0 0 0-1-1Zm6 5a1 1 0 0 1-1-1H7a1 1 0 0 1-2 0H3.33a.33.33 0 0 0-.33.33V7h12V5.33a.33.33 0 0 0-.33-.33H13a1 1 0 0 1-1 1Zm3 3H3v5.67c0 .182.148.33.33.33h11.34a.33.33 0 0 0 .33-.33V9Z"
    />
    <path d="M11 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" fill="#1C1C1F" />
  </svg>
);
