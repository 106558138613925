import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconSize } from "../../../constants/IconSize";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import logo from "../../../resources/svgs/Logo";
import { Icon } from "../../../shared/components/Icon";
import { Text } from "../../../shared/components/Text";
import { useHeaderOptions } from "../hooks/useHeaderOptions";
import defaultAvatar from "../../../resources/images/default-avatar.png";
import { useNavigate } from "react-router";
import { logout } from "../../auth/redux/auth.slice";
import { useClickOutsideDropdown } from "../../../shared/hooks/useClickOutsideDropdown";
import { NavLink } from "react-router-dom";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { useLocation } from "react-router";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
const HEADER_HEIGHT = 48;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  position: relative;
  width: 100%;
  background: ${Color.SECONDARY};
`;

const Logo = styled.div`
  position: absolute;
  left: 24px;
  top: 17px;
  cursor: pointer;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25%;
`;

const MenuOptionWrapper = styled.div`
  display: flex;
  color: ${Color.PRIMARY};
  cursor: pointer;
  align-items: center;

  &:hover > span {
    color: ${Color.VIOLET};
  }

  &:hover > span > svg > path {
    fill: ${Color.VIOLET};
  }
`;

const MenuText = styled(Text)`
  margin-left: 8px;
  margin-right: 28px;
`;

const UserGreeting = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
`;

const UserGreetingAvatar = styled.img`
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  margin-left: 8px;
  background: ${Color.VIOLET};
`;

const UserSettingsDropdown = styled.div`
  position: absolute;
  top: 45px;
  right: 15px;
  width: 200px;
  height: 120px;
  background-color: ${Color.SECONDARY};
  border-radius: 0.475rem;
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
  padding-top: 20px;
`;

function Header() {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { headerOptions, headerOptionsState, setHeaderOptionsState } =
    useHeaderOptions();
  const navigate = useNavigate();
  const location = useLocation();

  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    const option = Object.values(headerOptions).find((value) => {
      return (
        value.route === location.pathname ||
        value.route.substring(0, value.route.indexOf("?")) === location.pathname
      );
    });

    if (option) {
      setHeaderOptionsState({
        ...headerOptions,
        [option.key]: {
          ...headerOptionsState[option.key],
          isActive: !headerOptionsState[option.key].isActive,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  useClickOutsideDropdown([ref, ref2], () => setOpenDropdown(false));

  return (
    <HeaderContainer>
      <Logo>
        <NavLink to={RoutesTypes.grades}>{logo}</NavLink>
        {/* <NavLink to={RoutesTypes.dashboard}>{logo}</NavLink> */}
      </Logo>

      <Menu>
        {Object.values(headerOptionsState).map((option, index) => (
          <MenuOptionWrapper
            key={option.title + "_" + index}
            onClick={() => {
              navigate(option.route);
            }}
          >
            <Icon
              icon={IconType.lightning}
              color={
                option.isActive || option.isFocus ? Color.VIOLET : Color.PRIMARY
              }
              size={IconSize.M}
            />
            <MenuText
              size={TextSize.XS}
              weight={TextWeight.SEMIBOLD}
              color={
                option.isActive || option.isFocus ? Color.VIOLET : Color.PRIMARY
              }
            >
              {option.title}
            </MenuText>
          </MenuOptionWrapper>
        ))}
      </Menu>

      <UserGreeting onClick={() => setOpenDropdown(!openDropdown)} ref={ref2}>
        <Text size={TextSize.S}>Hello,</Text>&nbsp;
        <Text size={TextSize.S} weight={TextWeight.MEDIUM}>
          {userInfo?.email || "Test"}
        </Text>
        <UserGreetingAvatar src={userInfo?.avatar || defaultAvatar} />
      </UserGreeting>
      {openDropdown && (
        <UserSettingsDropdown ref={ref}>
          <Button
            fullWidth={true}
            customMaxWidth={140}
            size={ButtonSize.L}
            buttonType={ButtonType.GHOST}
            onClick={() => {
              dispatch(logout());
              window.location.href = window.location.origin + RoutesTypes.auth;
            }}
          >
            Logout
          </Button>
        </UserSettingsDropdown>
      )}
    </HeaderContainer>
  );
}

export default Header;
