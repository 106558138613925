import React from "react";
import { TranslationTable } from "../components/TranslationTable";

export const TranslationPage = () => {
  return (
    <>
      <TranslationTable />
    </>
  );
};
