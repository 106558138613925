import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";


export enum StatusIndicatorEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

const Indicator = styled.div<{ status: StatusIndicatorEnum }>`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${({ status }) =>
    status === StatusIndicatorEnum.ACTIVE
      ? Color.GREEN
      : Color.INACTIVE_STATUS};
`;

export const StatusIndicator = ({
  status,
}: {
  status: StatusIndicatorEnum;
}) => {
  return <Indicator status={status} />;
};
