import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import {
  createGrade,
  getGrades,
  getHiddenGrades,
  updateGrade,
} from "./actions";

export type GradeSchema = {
  // eventAmount: number;
  // id?: string;
  // mintPercentage: string;
  // name: string;
  // operatorId: string;
  // operatorResourceId: string;
  // poolPercentage: string;
  // isHidden: boolean,
  // multiplier: number,
  // coefficientTo: number,
  // coefficientFrom: number,



  coefficientFrom: number;
  coefficientFromIncluded: boolean
  coefficientTo: number;
  coefficientToIncluded: boolean;

  eventAmount?: number;
  eventAmountFrom?: number;
  eventAmountIncluded?: boolean;
  eventAmountFromIncluded?: boolean;

  eventAmountTo: number;
  eventAmountToIncluded: boolean;
  id: string;
  isHidden: boolean;
  mintPercentage: string;
  multiplier: number
  name: string;
  operatorId: string;
  operatorResourceId?: string;
  poolPercentage: string;

  createdAt: string;
  updatedAt: string;
};

export type GradesState = {
  result: GradeSchema[];
  count?: number;
  loading: Loading;
  loadingCreate: Loading;
  loadingUpdate: { loading: Loading; id: string };
  errorMessage?: string;
  errorMessageSubmit?: string;
  editGradeItem?: GradeSchema;
};

export const initialState: GradesState = {
  result: [],
  loading: Loading.INITIAL,
  loadingCreate: Loading.INITIAL,

  loadingUpdate: { loading: Loading.INITIAL, id: "" },
};

export const gradesSlice = createSlice({
  name: "grades",
  initialState,
  reducers: {
    setEditGradeItem: (state, action: PayloadAction<{ item: GradeSchema }>) => {
      state.editGradeItem = action.payload.item;
    },
    resetEditGradeItem: (state) => {
      state.editGradeItem = undefined;
    },
    resetErrorMessageSubmit: (state) => {
      state.errorMessageSubmit = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGrades.pending, (state, action) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getGrades.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.result = action.payload.result;
      state.count = action.payload.count;
    });
    builder.addCase(getGrades.rejected, (state, action) => {
      state.loading = Loading.ERROR;
      state.errorMessage = action.error.message;
    });
    builder.addCase(getHiddenGrades.pending, (state, action) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getHiddenGrades.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.result = [...state.result, ...action.payload.result];
      // state.count = action.payload.count;
    });
    builder.addCase(getHiddenGrades.rejected, (state, action) => {
      state.loading = Loading.ERROR;
      state.errorMessage = action.error.message;
    });

    

    builder.addCase(createGrade.pending, (state) => {
      state.loadingCreate = Loading.PENDING;
      state.errorMessageSubmit = "";
    });
    builder.addCase(createGrade.fulfilled, (state, action) => {
      state.loadingCreate = Loading.SUCCESS;
      //add item to burning item list
      state.result = [action.meta.arg, ...state.result];
    });
    builder.addCase(createGrade.rejected, (state, action) => {
      state.loadingCreate = Loading.ERROR;
      if (typeof action.payload === 'string') {
        state.errorMessageSubmit = action.payload;
      }
    });

    builder.addCase(updateGrade.pending, (state, action) => {
      state.loadingUpdate.loading = Loading.PENDING;
      state.loadingUpdate.id = action.meta.arg.id;
      state.errorMessageSubmit = "";
    });
    builder.addCase(updateGrade.fulfilled, (state, action) => {
      state.loadingUpdate.loading = Loading.SUCCESS;
      state.loadingUpdate.id = "";
      state.result = state.result.map((res) =>
        res.id === action.meta.arg.id ? { ...res, ...action.meta.arg } : res
      );
    });
    builder.addCase(updateGrade.rejected, (state, action) => {
      state.loadingUpdate.loading = Loading.ERROR;
      state.loadingUpdate.id = "";
      if (typeof action.payload === 'string') {
        state.errorMessageSubmit = action.payload;
      }
    });
  },
});

export const { setEditGradeItem, resetEditGradeItem, resetErrorMessageSubmit } =
  gradesSlice.actions;

export default gradesSlice.reducer;
