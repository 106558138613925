import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { GetBonusesShema } from "../../Bonuses/redux/actions";

export const getGrades = createAsyncThunk(
  "grades/getGrades",
  async ({ page, limit }: GetBonusesShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/get-grades?limit=${limit}&page=${page}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const getHiddenGrades = createAsyncThunk(
  "grades/getHiddenGrades",
    async ({ page, limit }: GetBonusesShema) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/operator/get-hidden-grades?limit=${limit}&page=${page}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      return data;
    }
)

export const createGrade = createAsyncThunk(
  "bonuses/createGrade",
  async (value: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/operator/add-grade`,
        value,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      return data;
    } catch (err) {
      //@ts-ignore
      if (!err.response) {
        throw err;
      }
      console.log(err, "DATE");
      //@ts-ignore
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateGrade = createAsyncThunk(
  "bonuses/updateGrade",
  async (value: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/operator/edit-grade`,
        value,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      return data;
    } catch (err) {
      //@ts-ignore
      if (!err.response) {
        throw err;
      }
      console.log(err, "DATE");
      //@ts-ignore
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getValidationSchema = createAsyncThunk(
  "bonuses/getValidationSchema",
  async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/get-validation-info`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

interface IHideGrade {
  id: string, name: string
}

export const hideGrade = createAsyncThunk(
  "grades/hideGrade",
  async (value: IHideGrade) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/hide-grade`, value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

interface IRenameGrade {
  id: string, name: string
}

export const renameGrade = createAsyncThunk(
  "grades/rename-grade",
  async (value: IRenameGrade) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/rename-grade`, value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);
