import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Text } from "../../../shared/components/Text";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import { Loading } from "../../../constants/Loading";
import { Icon } from "../../../shared/components/Icon";
import {
  ScrollArea,
  ScrollAreaSize,
} from "../../../shared/components/ScrollArea";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { truncateMiddle } from "../../../utils/utils";
import {
  ITEMS_PER_PAGE,
  UsersListPagination,
} from "../../../shared/components/UsersListPagination";
import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { ClientBcEventsReportSchema } from "../redux/events.slice";
import { Modal } from "../../../shared/components/Modal";
import HiddenTooltip, {
  TooltipContent,
} from "../../../shared/components/HiddenTooltip";

const TableWrapper = styled.div`
  position: relative;
  min-width: 1500px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const FirstColumnCell = styled.div`
  display: flex;
  padding-left: 16px;
  width: 12%;
`;

const ColumnCell = styled.div`
  display: flex;
  width: 10%;
  padding-right: 12px;
  position: relative;
`;

const ColumnSmallCell = styled.div`
  display: flex;
  width: 6%;
  padding-right: 12px;
  position: relative;
`;

const ColumnLargeCell = styled.div`
  display: flex;
  width: 15%;
  padding-right: 12px;
  position: relative;
`;

const EditableFieldAbsolute = styled.div`
  margin-left: 20px;
`;

const LastCell = styled.div`
  display: flex;
  justify-content: center;
  width: 4%;
  padding-right: 12px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  background-color: ${Color.SECONDARY};
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const LoaderWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
`;

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const EyeIcon = styled.span`
  cursor: pointer;
`;

const TextStyled = styled(Text)`
  white-space: nowrap;
`;

// const Wrap = styled.div`
//   display:flex;
//   width: 90%;
//   flex-wrap: wrap;
// `

export function ClientBcEventsReport() {
  const { clientBcEventsReport: tabletData } = useAppSelector(
    (state) => state.events
  );
  const dispatch = useAppDispatch();
  const showBGInfoModal =
    useAppSelector((state) => state.modalHub.shownModal) ===
    ShowModalEnum.BG_INFO;

  const [info, setInfo] = useState<ClientBcEventsReportSchema>();

  return (
    <>
      {tabletData.loading === Loading.SUCCESS && (
        <ScrollArea
          size={ScrollAreaSize.S}
          autoHeightMin={132}
          autoHeightMax={900}
          autoHeight
        >
          <TableWrapper>
            <TableHeader>
              <FirstColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  BC event Id
                </Text>
              </FirstColumnCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Block
                </Text>
              </ColumnCell>
              <ColumnSmallCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Amount
                </Text>
              </ColumnSmallCell>
              <ColumnLargeCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Created At
                </Text>
              </ColumnLargeCell>
              <ColumnLargeCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Updated At
                </Text>
              </ColumnLargeCell>
              <ColumnSmallCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Notification Sent
                </Text>
              </ColumnSmallCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  User Inner Id
                </Text>
              </ColumnCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Client Foreign Id
                </Text>
              </ColumnCell>

              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Burning Item Id
                </Text>
              </ColumnCell>

              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Tx Hash
                </Text>
              </ColumnCell>

              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Address
                </Text>
              </ColumnCell>
              <LastCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  See more
                </Text>
              </LastCell>
            </TableHeader>
            {tabletData.result &&
              tabletData.result.map((item, i) => (
                <TableRow key={"clientEventTableRow_" + i}>
                  <FirstColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {truncateMiddle(item.bceventid)}
                    </TextStyled>
                  </FirstColumnCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.block}
                    </TextStyled>
                  </ColumnCell>
                  <ColumnSmallCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.amount}
                    </TextStyled>
                  </ColumnSmallCell>

                  <ColumnLargeCell>
                    <HiddenTooltip
                      timerDuration={0}
                      content={
                        <TooltipContent
                          color={Color.SECONDARY}
                          size={TextSize.XS}
                          weight={TextWeight.MEDIUM}
                        >
                          {moment(item.createdat).format("MM-DD-YYYY hh:mm a")}
                        </TooltipContent>
                      }
                    >
                      <TextStyled
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                        ellipsis
                      >
                        {moment(item.createdat).format("MM-DD-YYYY")}
                      </TextStyled>
                    </HiddenTooltip>
                  </ColumnLargeCell>
                  <ColumnLargeCell>
                    <HiddenTooltip
                      timerDuration={0}
                      content={
                        <TooltipContent
                          color={Color.SECONDARY}
                          size={TextSize.XS}
                          weight={TextWeight.MEDIUM}
                        >
                          {moment(item.updatedat).format("MM-DD-YYYY hh:mm a")}
                        </TooltipContent>
                      }
                    >
                      <TextStyled
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                        ellipsis
                      >
                        {moment(item.updatedat).format("MM-DD-YYYY")}
                      </TextStyled>
                    </HiddenTooltip>
                  </ColumnLargeCell>
                  <ColumnSmallCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.notificationsent ? "true" : "false"}
                    </TextStyled>
                  </ColumnSmallCell>

                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {truncateMiddle(item.userinnerid)}
                    </TextStyled>
                  </ColumnCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.clientforeignid}
                    </TextStyled>
                  </ColumnCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.burningitemid && truncateMiddle(item.burningitemid)}
                    </TextStyled>
                  </ColumnCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      <a
                        target="_blank"
                        href={`${process.env.REACT_APP_HASH_URL + item.txhash}`}
                        rel="noreferrer"
                      >
                        {truncateMiddle(item.txhash)}
                      </a>
                    </TextStyled>
                  </ColumnCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.address && truncateMiddle(item.address)}
                    </TextStyled>
                  </ColumnCell>
                  <LastCell>
                    <EyeIcon
                      onClick={() => {
                        setInfo(item);
                        dispatch(
                          setShownModal({
                            shownModal: ShowModalEnum.BG_INFO,
                          })
                        );
                      }}
                    >
                      <Icon icon={IconType.visible} />
                    </EyeIcon>
                  </LastCell>
                </TableRow>
              ))}
          </TableWrapper>
        </ScrollArea>
      )}
      {tabletData.loading === Loading.PENDING && (
        <LoaderWrap>
          <RewindLoader color={"#233447"} size={110} sizeUnit={"px"} />
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Loading date
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                Please take a deep breath
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {tabletData.loading === Loading.ERROR && (
        <LoaderWrap>
          <Icon
            icon={IconType.error}
            size={80}
            color={Color.BRIGHT_GRAY}
          ></Icon>
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Something went wrong.
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                No data fetched. Please reload page or come back in few mins
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {!!tabletData.count && tabletData.count > ITEMS_PER_PAGE && (
        <UsersListPagination
          pathname="/events"
          total={Number(tabletData.count)}
        />
      )}

      {showBGInfoModal && (
        <Modal>
          <>
            <Text size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
              Purchase Info
            </Text>
            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Data
              </Text>
            </div>
            <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
              {info?.event.data}
            </Text>

            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Event
              </Text>
            </div>
            <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
              {info?.event?.event}
            </Text>
            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Topics
              </Text>
            </div>

            <Text
              size={TextSize.XS}
              weight={TextWeight.MEDIUM}
              breakWords={true}
            >
              {info?.event?.topics.join(",")}
            </Text>

            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Address
              </Text>
            </div>
            <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
              {info?.event?.address}
            </Text>
            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Removed
              </Text>
            </div>
            <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
              {info?.event.removed ? "TRUE" : "FALSE"}
            </Text>

            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Log Index
              </Text>
            </div>
            <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
              {info?.event?.logIndex}
            </Text>

            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                BlockHash
              </Text>
            </div>
            <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
              {info?.event?.blockHash}
            </Text>

            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Block Number
              </Text>
            </div>
            <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
              {info?.event?.blockNumber}
            </Text>

            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Event Signature
              </Text>
            </div>
            <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
              {info?.event?.eventSignature}
            </Text>

            {info?.event?.transactionHash && (
              <>
                <div>
                  <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                    Transaction Hash
                  </Text>
                </div>

                <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
                  <a
                    target="_blank"
                    href={`${
                      process.env.REACT_APP_HASH_URL +
                      info?.event?.transactionHash
                    }`}
                    rel="noreferrer"
                  >
                    {info?.event?.transactionHash}
                  </a>
                </Text>
              </>
            )}
            <div>
              <Text size={TextSize.S} weight={TextWeight.SEMIBOLD}>
                Transaction Index
              </Text>
            </div>
            <Text size={TextSize.XS} weight={TextWeight.MEDIUM} ellipsis>
              {info?.event?.transactionIndex}
            </Text>
          </>
        </Modal>
      )}
    </>
  );
}
