import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { Langs } from "./bonus.slice";

export interface GetBonusesShema {
  page?: string;
  limit?: string;
}

export interface GetBonusesWithLangShema {
  page?: string;
  limit?: string;
  language?: Langs;
}

export const getBonuses = createAsyncThunk(
  "bonuses/getBonuses",
  async ({ page, limit }: GetBonusesShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/get-burning-items?limit=${limit}&page=${page}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const getBonusesWithLang = createAsyncThunk(
  "bonuses/getBonusesWithLang",
  async ({ page, limit, language }: GetBonusesWithLangShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/get-burning-items?limit=${limit}&page=${page}&language=${language}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const createBonus = createAsyncThunk(
  "bonuses/createBonus",
  async (value: any,   { rejectWithValue }) => {
    try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/add-burning-item`, value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
    } catch (err) {
      //@ts-ignore
      if (!err.response) {
        throw err;
      }
      //@ts-ignore
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateBonus = createAsyncThunk(
  "bonuses/updateBonus",
  async (value: any, { rejectWithValue }) => {
    try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/edit-burning-item`, value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;

  } catch (err) {
    //@ts-ignore
    if (!err.response) {
      throw err;
    }
    //@ts-ignore
    return rejectWithValue(err.response.data.message);
  }
  }
);

export const activateBonus = createAsyncThunk(
  "bonuses/activateBonus",
  async (value: {burningItemId: string}) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/activate-burning-item`, value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const deactivateBonus = createAsyncThunk(
  "bonuses/deactivateBonus",
  async (value: {burningItemId: string}) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/deactivate-burning-item`, value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export interface ICreateTranslation {
  burningItemId: string;
  title: string;
  description: string;
  language: string;
}

export const createBurningItemTranslation = createAsyncThunk(
  "bonuses/createBurningItemTranslation",
  async (value: ICreateTranslation) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/add-burning-item-language`, value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export interface IDeleteTranslation {
  burningItemId: string;
  language: string;
}


export const deleteTranslation = createAsyncThunk(
  "bonuses/deleteTranslation",
  async (value: IDeleteTranslation) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/delete-burning-item-language`, value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export interface IGetItemWithTranslation {
  id: string;
  language: string;
}

export const getItemWithTranslation = createAsyncThunk(
  "bonuses/getItemWithTranslation",
  async ({id, language}: IGetItemWithTranslation) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/get-single-burning-item?burningItemId=${id}&language=${language}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const updateBurningItemTranslation = createAsyncThunk(
  "bonuses/updateBurningItemTranslation",
  async (value: ICreateTranslation) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/edit-burning-item-language`, value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);
