import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { ITag } from "../../Tags/redux/tags.slice";
import { activateBonus, createBonus, deactivateBonus, getBonuses, getBonusesWithLang, getItemWithTranslation, updateBonus } from "./actions";

export enum Language {
  en = 'en',
  et = 'et',
  Iv = 'Iv',
  es = 'es',
  pt = 'pt',
  fr = 'fr',
  el = 'el',
  ja = 'ja',
  de = 'de',
  vi = 'vi',
  th = 'th',
  it = 'it',
}

export type Langs = {
  [key in Language]?: string;
};

export type BonusSchemaRes = {
  burningItemForeignId: string;
  description: string;
  foreignIdHash: string;
  iconUrl: string;
  id: string;
  isActive: boolean;
  operatorId: string;
  title: string;
  usdPrice: number;
  tags?: ITag[]
};

interface BonusBase {
  burningItemForeignId: string;
  foreignIdHash: string;
  iconUrl: string;
  id: string;
  isActive: boolean;
  operatorId: string;
  usdPrice: number;
  tags?: ITag[];
}

export type BonusSchema = BonusBase & {
  description: string;
  title: string;
};

export type BonusEditSchema = BonusBase & {
  description: Langs;
  title: Langs;
};

export type bonusesState = {
  result: BonusSchema[];
  count?: number;
  loading: Loading;
  loadingCreate?: Loading;
  errorMessage?: string;
  errorMessageSubmit?: string;
  editBonusItem?: BonusEditSchema;
  fetchTranslation: {
    loading: Loading;
  }
};

export const initialState: bonusesState = {
  result: [],
  loading: Loading.INITIAL,
  fetchTranslation: {
    loading: Loading.INITIAL,
  }
};

export const bonusesSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetErrorMessageSubmit: (state) => {
      state.errorMessageSubmit = undefined;
    },
    setEditBonusItem: (state, action: PayloadAction<{ item: BonusSchema, lang: Language }>) => {
      const { item, lang } = action.payload;
      console.log(item, 'item');
      state.editBonusItem = {
        ...item,
        title: { [lang]: item.title },
        description: { [lang]: item.description },
      };
    },
    resetEditBonusItem: (state) => {
      state.editBonusItem = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBonuses.pending, (state, action) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getBonuses.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.result = action.payload.dtos;
      state.count = action.payload.count;
    });
    builder.addCase(getBonuses.rejected, (state, action) => {
      state.loading = Loading.ERROR;
      state.errorMessage = action.error.message;
    });

    builder.addCase(getItemWithTranslation.rejected, (state, action) => {
      state.fetchTranslation.loading = Loading.ERROR;
    });
    builder.addCase(getItemWithTranslation.fulfilled, (state, action) => {
      state.fetchTranslation.loading = Loading.SUCCESS;
      const lang = (action.meta.arg.language || 'en') as string;
      if (state.editBonusItem) {
        const translationTitle = action.payload.title === null ? '' : action.payload.title;
        const translationDescription = action.payload.description === null ? '' : action.payload.description;

        state.editBonusItem = { ...state.editBonusItem, title: {...state.editBonusItem.title, [lang]: translationTitle}, description: {...state.editBonusItem.description,  [lang]: translationDescription} }
      }
    });
    builder.addCase(getItemWithTranslation.pending, (state, action) => {
      state.fetchTranslation.loading = Loading.PENDING;
    });
    builder.addCase(createBonus.pending, (state) => {
      state.loadingCreate = Loading.PENDING;
      state.errorMessageSubmit = "";
    });
    builder.addCase(createBonus.fulfilled, (state, action) => {
      state.loadingCreate = Loading.SUCCESS;
      //add item to burning item list
    });
    builder.addCase(createBonus.rejected, (state, action) => {
      state.loadingCreate = Loading.ERROR;

      if (typeof action.payload === 'string') {
        state.errorMessageSubmit = action.payload;
      }
    });


    builder.addCase(updateBonus.pending, (state) => {
      state.loadingCreate = Loading.PENDING;
      state.errorMessageSubmit = "";
    });
    builder.addCase(updateBonus.fulfilled, (state, action) => {
      state.loadingCreate = Loading.SUCCESS;
      //add item to burning item list
    });
    builder.addCase(updateBonus.rejected, (state, action) => {
      state.loadingCreate = Loading.ERROR;

      if (typeof action.payload === 'string') {
        state.errorMessageSubmit = action.payload;
      }
    });

    builder.addCase(activateBonus.fulfilled, (state, action) => {
      const stateUpdated = state.result.map(item => item.id === action.meta.arg.burningItemId ? {...item, isActive: true}: item);
      state.result = stateUpdated;
    });

    builder.addCase(deactivateBonus.fulfilled, (state, action) => {
      const stateUpdated = state.result.map(item => item.id === action.meta.arg.burningItemId ? {...item, isActive: false}: item);
      state.result = stateUpdated;
    });
  },
});

export const { resetErrorMessageSubmit, setEditBonusItem, resetEditBonusItem } = bonusesSlice.actions;

export default bonusesSlice.reducer;
