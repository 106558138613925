// @ts-nocheck
import Cookies from "js-cookie";
import { useMemo, useState } from "react";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { OperatorRole } from "../../auth/redux/auth.slice";
import { EventTypes } from "../../Events/components/Events";

export enum HeaderOptionsEnum {
  // dasboard = 'dasboard',
  bonuses = "bonuses",
  cashback = "cashback",
  grades = "grades",
  failed_events = "failed_events",
  webhooks = "webhooks",
  bonuses_tags = "bonuses_tags",
  events = "events",
  user_management = "user_management",
  // translations = 'translations'
}

interface Options {
  key: HeaderOptionsEnum;
  title: string;
  route: RoutesTypes | string;
  isActive: boolean;
  isFocus: boolean;
}

export const useHeaderOptions = () => {
  const role = Cookies.get("role");

  const headerOptions = useMemo(() => {
    const defaultOptions: Record<HeaderOptionsEnum, Options> = {
      [HeaderOptionsEnum.bonuses]: {
        key: HeaderOptionsEnum.bonuses,
        title: "Bonuses",
        route: RoutesTypes.burning_items,
        isActive: false,
        isFocus: false,
      },
      [HeaderOptionsEnum.bonuses_tags]: {
        key: HeaderOptionsEnum.bonuses_tags,
        title: "Bonus Tags",
        route: RoutesTypes.bonuses_tags,
        isActive: false,
        isFocus: false,
      },
      [HeaderOptionsEnum.cashback]: {
        key: HeaderOptionsEnum.cashback,
        title: "Resources",
        route: RoutesTypes.cashback,
        isActive: false,
        isFocus: false,
      },
      [HeaderOptionsEnum.grades]: {
        key: HeaderOptionsEnum.grades,
        title: "Grades",
        route: RoutesTypes.grades,
        isActive: false,
        isFocus: false,
      },
      [HeaderOptionsEnum.webhooks]: {
        key: HeaderOptionsEnum.webhooks,
        title: "Webhooks logging",
        route: RoutesTypes.webhooks,
        isActive: false,
        isFocus: false,
      },
      [HeaderOptionsEnum.failed_events]: {
        key: HeaderOptionsEnum.failed_events,
        title: "Failed Events",
        route: RoutesTypes.failed_events,
        isActive: false,
        isFocus: false,
      },
      [HeaderOptionsEnum.events]: {
        key: HeaderOptionsEnum.events,
        title: "Events",
        route: RoutesTypes.events + `?eventType=${EventTypes.CLIENT_EVENTS}`,
        isActive: false,
        isFocus: false,
      },
      [HeaderOptionsEnum.user_management]: {
        key: HeaderOptionsEnum.user_management,
        title: "User reset",
        route: RoutesTypes.user_management,
        isActive: false,
        isFocus: false,
      },
    };

    const filterOptions = (options: Record<HeaderOptionsEnum, Options>) => {
      const filteredOptions: Record<HeaderOptionsEnum, Options> = {};

      for (const key in options) {
        if (options.hasOwnProperty(key) && options[key] !== undefined) {
          filteredOptions[key] = options[key];
        }
      }

      return filteredOptions;
    };

    let filteredOptions: Record<HeaderOptionsEnum, Options> = filterOptions(defaultOptions);

    switch (role) {
      case OperatorRole.MASTER:
        break;
      case OperatorRole.ANALYST:
        delete filteredOptions[HeaderOptionsEnum.cashback];
        break;
      case OperatorRole.CRM:
        delete filteredOptions[HeaderOptionsEnum.cashback];
        delete filteredOptions[HeaderOptionsEnum.grades];
        delete filteredOptions[HeaderOptionsEnum.failed_events];
        delete filteredOptions[HeaderOptionsEnum.events];
        break;
    }

    return filteredOptions;
  }, [role]);

  const [headerOptionsState, setHeaderOptionsState] = useState(headerOptions);

  return { headerOptions, headerOptionsState, setHeaderOptionsState };
};
