import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { createResource, getResources, updateResource } from "./actions";

export type ResourceSchema = {
  foreignResourceId: string;
  id?: string;
  name: string;
  operatorId: string;
};

export type ResourcesState = {
  result: ResourceSchema[];
  count?: number;
  loading: Loading;
  loadingCreate: Loading;
  errorMessage?: string;
  errorMessageSubmit?: string;
  editResourceItem?: ResourceSchema;
  loadingUpdate:{loading: Loading, id: string};
};

export const initialState: ResourcesState = {
  result: [],
  loading: Loading.INITIAL,
  loadingCreate: Loading.INITIAL,
  loadingUpdate: { loading: Loading.INITIAL, id: "" },
};

export const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {
    setEditResourceItem: (state, action: PayloadAction<{ item: ResourceSchema }>) => {
      state.editResourceItem = action.payload.item;
    },
    resetEditResourceItem: (state) => {
      state.editResourceItem = undefined;
    },
    resetErrorMessageSubmit: (state) => {
      state.errorMessageSubmit = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResources.pending, (state, action) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getResources.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.result = action.payload.result;
      state.count = action.payload.count;
    });
    builder.addCase(getResources.rejected, (state, action) => {
      state.loading = Loading.ERROR;
      state.errorMessage = action.error.message;
    });

    builder.addCase(createResource.pending, (state) => {
      state.loadingCreate = Loading.PENDING;
      state.errorMessageSubmit = '';
    });
    builder.addCase(createResource.fulfilled, (state, action) => {
      state.loadingCreate = Loading.SUCCESS;
      //add item to burning item list
    });
    builder.addCase(createResource.rejected, (state, action) => {
      state.loadingCreate = Loading.ERROR;
      state.errorMessageSubmit = action.error.message;
    });

    builder.addCase(updateResource.pending, (state, action) => {
      state.loadingUpdate.loading = Loading.PENDING;
      state.loadingUpdate.id = action.meta.arg.id;
    });
    builder.addCase(updateResource.fulfilled, (state, action) => {
      state.loadingUpdate.loading = Loading.SUCCESS;
      state.loadingUpdate.id = "";
      state.result = state.result.map((res) =>
        res.id === action.meta.arg.id ? { ...res, ...action.meta.arg } : res
      );
    });
    builder.addCase(updateResource.rejected, (state, action) => {
      state.loadingUpdate.loading = Loading.ERROR;
      state.loadingUpdate.id = "";
    });
  },
});

export const { setEditResourceItem, resetEditResourceItem, resetErrorMessageSubmit } = resourcesSlice.actions;

export default resourcesSlice.reducer;
