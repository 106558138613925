import React from "react";

export default (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 12.9844V15H10.9688V12.9844H9ZM4.3125 4.3125C5.90625 2.75 7.79688 1.96875 9.98438 1.96875C12.1719 1.96875 14.0469 2.75 15.6094 4.3125C17.2031 5.90625 18 7.79688 18 9.98438C18 12.1719 17.2031 14.0469 15.6094 15.6094C14.0469 17.2031 12.1719 18 9.98438 18C7.79688 18 5.90625 17.2031 4.3125 15.6094C2.75 14.0469 1.96875 12.1719 1.96875 9.98438C1.96875 7.79688 2.75 5.90625 4.3125 4.3125ZM2.90625 17.0156C4.875 18.9844 7.23438 19.9688 9.98438 19.9688C12.7344 19.9688 15.0781 18.9844 17.0156 17.0156C18.9844 15.0781 19.9688 12.7344 19.9688 9.98438C19.9688 7.23438 18.9844 4.875 17.0156 2.90625C15.0781 0.96875 12.7344 0 9.98438 0C7.23438 0 4.875 0.96875 2.90625 2.90625C0.96875 4.875 0 7.23438 0 9.98438C0 12.7344 0.96875 15.0781 2.90625 17.0156ZM9 4.96875V10.9688H10.9688V4.96875H9Z"
      fill="#364159"
      fill-opacity="0.8"
    />
  </svg>
);
