import React, {
  forwardRef,
  useState,
  KeyboardEvent,
  useLayoutEffect,
  useRef,
} from "react";
import styled from "styled-components";
import { Text } from "./Text";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { Icon } from "./Icon";
import { InputLabel } from "./InputLabel";
import Key from "../../constants/key";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";

export enum InputHeight {
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
}

export enum AccentColor {
  DEFAULT = Color.PRIMARY10,
  RED = Color.RED_HOT_HOOD,
  GREEN = Color.GREEN,
  BLUE = Color.BLUEBERRY,
}

export const HeightValues = {
  S: {
    height: "29px",
  },
  M: {
    height: "36px",
  },
  L: {
    height: "4px",
  },
  XL: {
    height: "64px",
  },
};

export type InputRef = HTMLInputElement;

export type InputProps = {
  type?: string;
  name?: string;
  dataId?: string | number;
  dataTestid?: string | number;
  dataRecurly?: string;
  value?: string;
  placeholder?: string;
  height?: InputHeight;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  error?: string;
  autoComplete?: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (ev: React.ClipboardEvent<HTMLInputElement>) => void;
  onClick?: (ev: React.MouseEvent<HTMLInputElement>) => void;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  rightText?: string;
  defaultValue?: string | number;
  maxLength?: number;
  autoFocus?: boolean;
  allowDecimals?: boolean;
  invertColors?: boolean;
  accentColor?: AccentColor;
  label?: string;
  required?: boolean;
  leftPadding?: number;
};

type InputPropsExtended = InputProps & {
  passwordVisibilityEnabled?: boolean;
};

const InputContainer = styled.input<InputPropsExtended>`
  display: block;
  box-sizing: border-box;
  font-family: inherit;
  font-size: ${TextSize.S}px;
  font-weight: ${TextWeight.MEDIUM};
  border-radius: 4px;
  padding: ${({ rightText, passwordVisibilityEnabled, leftPadding }) => {
    let rightPadding = 16;
    if (rightText) {
      rightPadding = rightPadding + rightText.length * 8;
    }
    rightPadding = passwordVisibilityEnabled ? rightPadding + 26 : rightPadding;

    return `0 ${rightPadding}px 0 ${leftPadding || 16}px`;
  }};
  ${({ invertColors }) =>
    invertColors
      ? `
        color: ${Color.SECONDARY};
        border: 1px solid  ${Color.SECONDARY10};
        background-color: ${Color.SECONDARY10};
        ::placeholder {
            color: ${Color.SECONDARY40};
        }
        `
      : `
            border: 1px solid ${Color.PRIMARY10};
            color: ${Color.PRIMARY};

            ::placeholder {
                color: ${Color.PRIMARY40};
            }
    `}

  width: 100%;
  height: ${({ height = InputHeight.M }) => HeightValues[height].height};
  pointer-events: ${({ disabled, readOnly }) =>
    disabled || readOnly ? "none" : "auto"};

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }

  :hover {
    border-color: ${Color.PRIMARY40};
  }
  :focus {
    outline: none;
    border-color: ${Color.ACCENT};
  }

  ${({ accentColor }) =>
    accentColor && accentColor !== AccentColor.DEFAULT
      ? `border-color: ${accentColor} !important;`
      : ""}
`;

const Group = styled.div`
  position: relative;
  width: 100%;
`;

const RightTextContainer = styled.div<InputPropsExtended>`
  display: flex;
  position: absolute;
  right: ${({ passwordVisibilityEnabled }) =>
    `${passwordVisibilityEnabled ? 40 : 12}px`};
  top: 50%;
  transform: translateY(-50%);
`;

const InputError = styled.span`
  display: flex;
  color: ${AccentColor.RED};
  font-size: ${TextSize.XXXS}px;
  font-weight: ${TextWeight.MEDIUM};
  line-height: 14px;
  text-align: left;
  margin-top: 4px;
`;

const EyeIcon = styled.span<InputPropsExtended>`
  display: flex;
  position: absolute;
  right: 12px;
  top: 38px;
  // top: ${({ error }) => (error ? "35%" : "50%")};
  transform: translateY(-50%);
  cursor: pointer;
`;

/**
 * This component is used to add input in the application
 */
export const Input = forwardRef<InputRef, InputProps>(
  (
    {
      type = "text",
      dataId,
      dataTestid,
      dataRecurly,
      name,
      value,
      placeholder,
      height = InputHeight.M,
      disabled = false,
      readOnly = false,
      className,
      error,
      autoComplete,
      rightText,
      onChange,
      onFocus,
      onBlur,
      onPaste,
      onClick,
      onKeyDown = () => {},
      defaultValue,
      maxLength,
      autoFocus,
      allowDecimals,
      invertColors,
      accentColor,
      label,
      required = false,
      leftPadding,
    }: InputProps,
    ref
  ) => {
    let inputType = type;
    let passwordVisibilityEnabled = false;
    let eyeIcon = null;
    const [inputValue, setInputValue] = useState<string>("");
    const innerRef = useRef<InputRef>(null);
    const [passwordShown, setPasswordShown] = useState(false);

    useLayoutEffect(() => {
      if (
        inputType === "number" &&
        innerRef.current &&
        "addEventListener" in innerRef.current
      ) {
        const wheelEventHandler = innerRef.current.addEventListener(
          "wheel",
          (ev: WheelEvent) => {
            ev.preventDefault();
          }
        );

        return () => {
          window.removeEventListener(
            "wheel",
            wheelEventHandler as keyof EventListener
          );
        };
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const togglePasswordVisibility = () => {
      setPasswordShown((value) => !value);
    };
    if (type === "password") {
      if (inputValue.length !== 0) {
        eyeIcon = (
          <EyeIcon onClick={togglePasswordVisibility} error={error}>
            {passwordShown ? (
              <Icon icon={IconType.visibleNot} />
            ) : (
              <Icon icon={IconType.visible} />
            )}
          </EyeIcon>
        );
      }
      inputType = passwordShown ? "text" : "password";
      passwordVisibilityEnabled = true;
    }

    const rightTextContainer = rightText ? (
      <RightTextContainer passwordVisibilityEnabled={passwordVisibilityEnabled}>
        <Text color={Color.PRIMARY40} size={TextSize.S}>
          {rightText}
        </Text>
      </RightTextContainer>
    ) : null;

    const onKeyUp = (ev: KeyboardEvent<HTMLInputElement>) => {
      const { key, currentTarget } = ev;
      if (key === Key.Escape || key === Key.Enter) {
        currentTarget.blur();
      }
    };

    const onKeyDownHandler = (ev: KeyboardEvent<HTMLInputElement>) => {
      onKeyDown(ev);

      if (inputType === "number") {
        if (!allowDecimals) {
          if (ev.key === "." || ev.key === ",") {
            ev.preventDefault();
          }
        }
        if (ev.key === "e" || ev.key === "E" || ev.key === "-") {
          ev.preventDefault();
        }
      }
    };

    const onPasteHandle = (ev: any) => {
      if (onPaste) {
        return onPaste(ev);
      }
      if (inputType === "number") {
        if (!allowDecimals) {
          const str = ev.clipboardData?.getData("text");
          const newStr = str?.replace(/[.,e-]/g, "");
          if (str !== newStr) {
            ev.preventDefault();
          }
        }
      }
    };

    const getAccentColor = () => {
      if (accentColor !== undefined) return accentColor;
      if (error) return AccentColor.RED;

      return AccentColor.DEFAULT;
    };

    return (
      <Group className={className}>
        {label && <InputLabel text={label} textSize={10} required={required} />}
        <InputContainer
          type={inputType}
          data-id={dataId}
          data-testid={dataTestid}
          data-recurly={dataRecurly}
          name={name}
          value={value}
          placeholder={placeholder}
          height={height}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onChange={(ev) => {
            setInputValue(ev.target.value);
            if (!(disabled || readOnly)) {
              onChange && onChange(ev);
            }
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          rightText={rightText}
          defaultValue={defaultValue}
          passwordVisibilityEnabled={passwordVisibilityEnabled}
          maxLength={maxLength}
          onKeyUp={onKeyUp}
          autoFocus={autoFocus}
          onKeyDown={onKeyDownHandler}
          onPaste={onPasteHandle}
          onClick={onClick}
          invertColors={invertColors}
          accentColor={getAccentColor()}
          leftPadding={leftPadding}
        />
        {rightTextContainer}
        {error ? <InputError>{error}</InputError> : null}
        {eyeIcon}
      </Group>
    );
  }
);
