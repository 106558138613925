// import moment from "moment";
import { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
// import { CellValue } from "react-table";

export const truncateMiddle = (word: string) => {
  const tooLongChars = 15; // arbitrary
  if (!word) return;
  if (word.length < tooLongChars) {
    return word;
  }

  const ellipsis = "...";
  const charsOnEitherSide = Math.floor(tooLongChars / 2) - ellipsis.length;

  return (
    word.slice(0, charsOnEitherSide) + ellipsis + word.slice(-charsOnEitherSide)
  );
};

export const isUUID = (uuid: string) => {
  return !!("" + uuid).match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
  );
};

export const useQueryParams = (): { [key: string]: string } | any => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  return new Proxy(params, {
    get(target, prop) {
      if (typeof prop === "string") {
        return target.get(prop);
      }
    },
  });
};

export const LinkForGrades = ({
  param,
  text,
  type,
}: {
  param: string;
  text: string;
  type: string;
}) => {
  return type === "userId" ? (
    <NavLink to={`/grades?operatorId=${param}`}>{text}</NavLink>
  ) : (
    <div>{text}</div>
  );
};

export const getError = (error: {
  response: { data: { message: string | string[] } };
}) => {
  const { message } = error?.response?.data;
  return typeof message === "string" ? message : message?.join(",");
};

export function hasOnlyIdAndNameKeys(obj: any) {
  const keys = Object.keys(obj);
  return keys.length === 2 && keys.includes("id") && keys.includes("name");
}

export function hasOnlyIdAndIsHiddenKeys(obj: any) {
  const keys = Object.keys(obj);
  return keys.length === 2 && keys.includes("id") && keys.includes("isHidden");
}

export function hasOnlyIdIsHiddenAndNameKeys(obj: any) {
  const keys = Object.keys(obj);
  return keys.length === 3 && keys.includes("id") && keys.includes("isHidden") && keys.includes("name");
}

export function parseJwt (token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}