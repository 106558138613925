import Cookies from "js-cookie";
import { Outlet, Navigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { logout } from "../features/auth/redux/auth.slice";

const PrivateRoute = () => {
  const dispatch = useAppDispatch();

  const token = Cookies.get("token");
  const expiresIn = Cookies.get("expires_in");

  if (token && expiresIn) {
    return <Outlet />;
  } else {
    dispatch(logout());

    return <Navigate to="/auth" />;
  }
};

export default PrivateRoute;
