import { createSlice } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { getFailedEvents } from "./actions";

export type IEventBody = {
  amount: number;
  userId: string;
  resourceId: string;
  eventId: string;
};

export type FailedEventSchema = {
  created_at: Date;
  event_body: IEventBody;
  amount: number;
  eventId: string;
  resourceId: string;
  userId: string;
  id: string | number;
  operator_id: string;
  updated_at: string;
};

export type ResourcesState = {
  result: FailedEventSchema[];
  count?: number;
  loading: Loading;
  errorMessage?: string;
};

export const initialState: ResourcesState = {
  result: [],
  loading: Loading.INITIAL,
};

export const failedEventsSlice = createSlice({
  name: "failedEvents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFailedEvents.pending, (state, action) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getFailedEvents.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.result = action.payload.result;
      state.count = action.payload.count;
    });
    builder.addCase(getFailedEvents.rejected, (state, action) => {
      state.loading = Loading.ERROR;
      state.errorMessage = action.error.message;
    });
  },
});

export default failedEventsSlice.reducer;
