import { createSlice } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { getTranslations, createTranslation, updateTranslation } from "./actions";

export type TranslateSchema = {
  id?: string;
  name: string;
  english: string;
  russian: string;
};

export type ResourcesState = {
  result: TranslateSchema[];
  count?: number;
  loading: Loading;
  loadingCreate: Loading;
  errorMessage?: string;
  errorMessageSubmit?: string;
  // editTranslateItem?: TranslateSchema;
  loadingUpdate:{loading: Loading, id: string};
};

export const initialState: ResourcesState = {
  result: [],
  loading: Loading.INITIAL,
  loadingCreate: Loading.INITIAL,
  loadingUpdate: { loading: Loading.INITIAL, id: "" },
};

export const translationSlice = createSlice({
  name: "translation",
  initialState,
  reducers: {
    resetErrorMessageSubmit: (state) => {
      state.errorMessageSubmit = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTranslations.pending, (state, action) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getTranslations.fulfilled, (state, action) => {
      state.loading = Loading.SUCCESS;
      state.result = action.payload.result;
      state.count = action.payload.count;
    });
    builder.addCase(getTranslations.rejected, (state, action) => {
      state.loading = Loading.ERROR;
      state.errorMessage = action.error.message;
    });

    builder.addCase(createTranslation.pending, (state) => {
      state.loadingCreate = Loading.PENDING;
      state.errorMessageSubmit = '';
    });
    builder.addCase(createTranslation.fulfilled, (state, action) => {
      state.loadingCreate = Loading.SUCCESS;
      //add item to burning item list
    });
    builder.addCase(createTranslation.rejected, (state, action) => {
      state.loadingCreate = Loading.ERROR;
      state.errorMessageSubmit = action.error.message;
    });

    builder.addCase(updateTranslation.pending, (state, action) => {
      state.loadingUpdate.loading = Loading.PENDING;
      state.loadingUpdate.id = action.meta.arg.id;
    });
    builder.addCase(updateTranslation.fulfilled, (state, action) => {
      state.loadingUpdate.loading = Loading.SUCCESS;
      state.loadingUpdate.id = "";
      state.result = state.result.map((res) =>
        res.id === action.meta.arg.id ? { ...res, ...action.meta.arg } : res
      );
    });
    builder.addCase(updateTranslation.rejected, (state, action) => {
      state.loadingUpdate.loading = Loading.ERROR;
      state.loadingUpdate.id = "";
    });
  },
});

export const { resetErrorMessageSubmit } = translationSlice.actions;

export default translationSlice.reducer;
