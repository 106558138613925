import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export type GetClientEventsShema = {
  page: string;
  limit: string | number;
  clientForeignId?: string;
  dateMin?: string;
  dateMax?: string;
}

export const getClientEvents = createAsyncThunk(
  "grades/getClientEvents",
  async ({ page, limit, clientForeignId, dateMin, dateMax }: GetClientEventsShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/client-event-report?clientForeignId=${clientForeignId}&limit=${limit}&page=${page}&dateMin=${dateMin}&dateMax=${dateMax}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const getClientJournaReportlEvents = createAsyncThunk(
  "grades/getClientJournaReportlEvents",
  async ({ page, limit, clientForeignId, dateMin, dateMax }: GetClientEventsShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/client-journal-report/?clientForeignId=${clientForeignId}&limit=${limit}&page=${page}&dateMin=${dateMin}&dateMax=${dateMax}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);


export const getClientBcReportEvents = createAsyncThunk(
  "grades/getClientBcReportEvents",
  async ({ page, limit, clientForeignId, dateMin, dateMax }: GetClientEventsShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/client-bc-events-report?clientForeignId=${clientForeignId}&limit=${limit}&page=${page}&dateMin=${dateMin}&dateMax=${dateMax}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

