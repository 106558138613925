import { createStaticRanges, StaticRange } from 'react-date-range';
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    addYears,
    startOfYear,
    endOfYear,
} from 'date-fns';

const dateConstants = {
    startOfWeek: startOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastYear: startOfYear(addYears(new Date(), -1)),
    endOfLastYear: endOfYear(addYears(new Date(), -1)),
};

const staticRanges = createStaticRanges([
    {
        label: 'Today',
        range: () => ({
            startDate: dateConstants.startOfToday,
            endDate: dateConstants.endOfToday,
        }),
    },
    {
        label: 'Yesterday',
        range: () => ({
            startDate: dateConstants.startOfYesterday,
            endDate: dateConstants.endOfYesterday,
        }),
    },

    {
        label: 'Last week',
        range: () => ({
            startDate: dateConstants.startOfLastWeek,
            endDate: dateConstants.endOfLastWeek,
        }),
    },
    {
        label: 'Last month',
        range: () => ({
            startDate: dateConstants.startOfLastMonth,
            endDate: dateConstants.endOfLastMonth,
        }),
    },
    {
        label: 'Last year',
        range: () => ({
            startDate: dateConstants.startOfLastYear,
            endDate: dateConstants.endOfLastYear,
        }),
    },
] as StaticRange[]);

export default staticRanges;
