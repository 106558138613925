import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Input } from "../../../shared/components/Input";
import {
  Button,
  ButtonSize,
  ButtonType,
  HTMLButtonType,
} from "../../../shared/components/Button";

import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import {
  createGrade,
  getGrades,
  getHiddenGrades,
  hideGrade,
  renameGrade,
  updateGrade,
} from "../redux/actions";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { getResources } from "../../Resources/redux/actions";
import { Dropdown, DropdownType } from "../../../shared/components/Dropdown";
import { DropdownSize } from "../../../shared/components/DropdownList";
import { InputLabel } from "../../../shared/components/InputLabel";
import { Checkbox } from "../../../shared/components/Checkbox";
import { GradeSchema, resetEditGradeItem } from "../redux/grades.slice";
import {
  hasOnlyIdAndIsHiddenKeys,
  hasOnlyIdAndNameKeys,
  hasOnlyIdIsHiddenAndNameKeys,
} from "../../../utils/utils";
import { IconType } from "../../../constants/IconType";
import { Icon } from "../../../shared/components/Icon";
import TextAlign from "../../../constants/TextAlign";

const DropdownWrapper = styled.section`
  margin-top: 24px;
`;

const AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const AuthFormNameWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-top: 32px;
`;

const ErrorText = styled(Text)`
  margin-top: 10px;
`;

const ButtonWithLeftMargin = styled.div`
  margin-left: 16px;
`;

const CheckboxWrapper = styled.div`
  display flex;
  align-items: center;
  margin-top: 16px;
`;

const CheckboxText = styled(Text)`
  margin-left: 8px;
`;

const InputWithSelector = styled.div``;

const WrapperCoefficient = styled.div`
  position: relative;
  display: flex;
  margin-top: 42px;
`;

const InputLabelWrapper = styled.div`
  position: absolute;
  top: -22px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 165px;
`;

const InputWithRightMargin = styled(Input)`
  margin-left: 8px;
`;

const Separator = styled.div`
  display: flex;
  margin: 27px 11px 0;
`;

const Exclamation = styled.div`
  display: flex;

  margin: 12px 0 0 2px;
  align-items: center;
`;

const TextWithLeftMargin = styled(Text)`
  margin-left: 12px;
  width: 360px;
`;

export function GradeAddForm() {
  const dispatch = useAppDispatch();
  const { errorMessageSubmit, loadingCreate, result } = useAppSelector(
    (state) => state.grades
  );
  const { result: resources } = useAppSelector((state) => state.resources);

  const editGradeItem = useAppSelector((state) => state.grades.editGradeItem);

  const initialValues = {
    id: editGradeItem?.id || "",
    name: editGradeItem?.name || "",
    eventAmount: editGradeItem?.eventAmount || 0,
    eventAmountIncluded: editGradeItem
      ? editGradeItem.eventAmountIncluded
      : true,
    eventAmountTo: editGradeItem?.eventAmountTo === null ?  undefined : editGradeItem?.eventAmountTo,
    eventAmountToIncluded: editGradeItem
      ? editGradeItem?.eventAmountToIncluded
      : true,

    operatorResourceId: editGradeItem?.operatorResourceId || "",
    // mintPercentage: editGradeItem?.mintPercentage || 0,
    // poolPercentage: editGradeItem?.poolPercentage || 0,
    isHidden: editGradeItem?.isHidden || false,
    multiplier: editGradeItem?.multiplier || 0,
    coefficientFrom: editGradeItem?.coefficientFrom,
    coefficientTo: editGradeItem?.coefficientTo,

    coefficientFromIncluded: editGradeItem
      ? editGradeItem?.coefficientFromIncluded
      : true,
    coefficientToIncluded: editGradeItem
      ? editGradeItem?.coefficientToIncluded
      : true,
  };

  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

  const editGradeSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(250, "Maximum 250 symbols")
      .required("Name is required"),
    eventAmountTo: Yup.number()
      .when("eventAmount", {
        is: (FieldA: any) => FieldA !== 0,
        then: Yup.number().moreThan(
          Yup.ref("eventAmount"),
          "Event Amount To should be > Event Amount"
        ),
      })
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum two digits after comma",
        (val: any) => {
          if (val != undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .nullable()
      .optional(),

    eventAmount: Yup.number()
      .required()
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum two digits after comma",
        (val: any) => {
          if (val != undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      ),
    // eventAmount: Yup.number().required("Event Amount is required"),
    coefficientTo: Yup.number()
      .when("coefficientFrom", {
        is: (FieldA: any) => FieldA !== 0,
        then: Yup.number().moreThan(
          Yup.ref("coefficientFrom"),
          "coefficient To should be > coefficient From"
        ),
      })
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum two digits after comma",
        (val: any) => {
          if (val != undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      ),
    coefficientFrom: Yup.number()
      .notRequired()
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum two digits after comma",
        (val: any) => {
          if (val != undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      ),
    operatorResourceId: Yup.string().required(
      "Operator Resource Id is required"
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: editGradeSchema,
    onSubmit: async (values) => {
      //@ts-ignore
      const submitObject: {
        id?: string;
        name: string;
        eventAmount?: number;
        eventAmountIncluded?: boolean;

        eventAmountFrom?: number;
        eventAmountFromIncluded?: boolean;

        eventAmountTo?: number;
        eventAmountToIncluded?: string;
        operatorResourceId?: string;
        multiplier?: number;
        coefficientTo?: number;
        coefficientFrom?: number;

        coefficientFromIncluded?: boolean;
        coefficientToIncluded?: boolean;
        isHidden?: boolean;

        // mintPercentage: string | number;
        // poolPercentage: string | number;
      } = {
        ...values,
        operatorResourceId:
          resources.find((res) => res.name === values.operatorResourceId)?.id ||
          "",
      };

      if (submitObject.coefficientTo) {
        submitObject.coefficientFrom = Number(submitObject.coefficientFrom);
        submitObject.coefficientTo = Number(submitObject.coefficientTo);
      } else {
        delete submitObject.coefficientFromIncluded;
        delete submitObject.coefficientToIncluded;
      }

      if (submitObject.eventAmountTo) {
        submitObject.eventAmountFrom = Number(submitObject.eventAmount);

        submitObject.eventAmountFromIncluded =
          !!submitObject.eventAmountIncluded;
        delete submitObject.eventAmount;
        delete submitObject.eventAmountIncluded;
        submitObject.eventAmountTo = Number(submitObject.eventAmountTo);
      } else if (!submitObject.eventAmountTo && submitObject.eventAmount) {
        submitObject.eventAmountFrom = Number(submitObject.eventAmount);
        submitObject.eventAmountFromIncluded =
          !!submitObject.eventAmountIncluded;
        delete submitObject.eventAmount;
        delete submitObject.eventAmountIncluded;
        delete submitObject.eventAmountTo;
        delete submitObject.eventAmountToIncluded;
      } else {
        delete submitObject.eventAmount;
        delete submitObject.eventAmountIncluded;
        delete submitObject.eventAmountFromIncluded;
        delete submitObject.eventAmountTo;
        delete submitObject.eventAmountToIncluded;
      }

      submitObject.multiplier = Number(submitObject.multiplier);

      if (!editGradeItem?.id) {
        if (
          Number(submitObject.coefficientTo) === 0 &&
          Number(submitObject.coefficientFrom) === 0
        ) {
          delete submitObject.coefficientFrom;
          delete submitObject.coefficientTo;
        }
        delete submitObject.id;
        delete submitObject.isHidden;

        dispatch(createGrade(submitObject)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
            dispatch(getGrades({ page: "1", limit: "9999" })).then(() => {
              dispatch(getHiddenGrades({ page: "1", limit: "9999" }));
            });
          }
        });
      } else {
        const editGradeItemDeepCopy = { ...editGradeItem };

        editGradeItemDeepCopy.eventAmountFrom = editGradeItem.eventAmount;
        delete editGradeItemDeepCopy.eventAmount;
        editGradeItemDeepCopy.eventAmountFromIncluded =
          editGradeItem.eventAmountIncluded;
        delete editGradeItemDeepCopy.eventAmountIncluded;
        delete submitObject.operatorResourceId;

        const resultSubmitObject = Object.entries(submitObject).reduce(
          (result: any, [key, value]) => {
            if (key === "operatorResourceId") {
              return result;
              //@ts-ignore
            } else if (key === "id" || editGradeItemDeepCopy[key] !== value) {
              result[key] = value;
              return result;
            } else {
              return result;
            }
          },
          {}
        );
        delete submitObject.operatorResourceId;

        const isRenameCase = hasOnlyIdAndNameKeys(resultSubmitObject);
        const isHiddenCase = hasOnlyIdAndIsHiddenKeys(resultSubmitObject);
        const isRenameHidenCase =
          hasOnlyIdIsHiddenAndNameKeys(resultSubmitObject);

        if (isRenameCase) {
          dispatch(renameGrade(resultSubmitObject)).then((data) => {
            if (data.meta.requestStatus === "fulfilled") {
              dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
              dispatch(getGrades({ page: "1", limit: "9999" })).then(() => {
                dispatch(getHiddenGrades({ page: "1", limit: "9999" }));
              });
            }
          });
        } else if (isRenameHidenCase) {
          dispatch(renameGrade(resultSubmitObject)).then((data) => {
            if (data.meta.requestStatus === "fulfilled") {
              dispatch(
                hideGrade({
                  name: editGradeItem.name,
                  id: editGradeItem.id,
                })
              ).then(() => {
                dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
                dispatch(getGrades({ page: "1", limit: "9999" })).then(() => {
                  dispatch(getHiddenGrades({ page: "1", limit: "9999" }));
                });
              });
            }
          });
        } else {
          if (isHiddenCase) {
            dispatch(
              hideGrade({
                name: editGradeItem.name,
                id: editGradeItem.id,
              })
            ).then(() => {
              dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
              dispatch(getGrades({ page: "1", limit: "9999" })).then(() => {
                dispatch(getHiddenGrades({ page: "1", limit: "9999" }));
              });
            });
          } else if (resultSubmitObject.isHidden) {
            delete resultSubmitObject.isHidden;
            dispatch(updateGrade(resultSubmitObject)).then((data) => {
              if (data.meta.requestStatus === "fulfilled") {
                dispatch(getGrades({ page: "1", limit: "9999" })).then(
                  (data) => {
                    const updatedGradeId = data.payload.result.find(
                      (item: GradeSchema) =>
                        item.createdAt === editGradeItem.createdAt &&
                        item.name ===
                          (resultSubmitObject.name || editGradeItem.name)
                    ).id;
                    updatedGradeId &&
                      dispatch(
                        hideGrade({
                          name: resultSubmitObject.name || editGradeItem.name,
                          id: updatedGradeId,
                        })
                      ).then(() => {
                        dispatch(
                          setShownModal({ shownModal: ShowModalEnum.NONE })
                        );
                        dispatch(getGrades({ page: "1", limit: "9999" })).then(
                          () => {
                            dispatch(
                              getHiddenGrades({ page: "1", limit: "9999" })
                            );
                          }
                        );
                      });
                  }
                );
              }
            });
          } else {
            dispatch(updateGrade(resultSubmitObject)).then((data) => {
              if (data.meta.requestStatus === "fulfilled") {
                dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
                dispatch(getGrades({ page: "1", limit: "9999" })).then(() => {
                  dispatch(getHiddenGrades({ page: "1", limit: "9999" }));
                });
              }
            });
          }
        }
      }
    },
  });

  useEffectSkipFirst(() => {
    dispatch(getResources({ page: "1", limit: "9999" }));
  }, []);

  return (
    <AuthFormWrapper>
      <form onSubmit={formik.handleSubmit}>
        <AuthFormNameWrapper>
          <Text size={TextSize.M} weight={TextWeight.SEMIBOLD}>
            Grades settings
          </Text>
        </AuthFormNameWrapper>
        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <InputLabel text={"Name"} textSize={12} required={true} />

        <Input
          {...formik.getFieldProps("name")}
          placeholder={"Name"}
          error={(formik.touched.name && formik.errors.name) || ""}
          required={true}
        />
        {/* <InputWithMargin
          label="Event Amount"
          {...formik.getFieldProps("eventAmount")}
          placeholder={"Event Amount"}
          error={
            (formik.touched.eventAmount && formik.errors.eventAmount) || ""
          }
          required={true}
        /> */}

        <WrapperCoefficient>
          <InputLabelWrapper>
            <InputLabel
              text={"Transaction amount"}
              textSize={12}
              required={true}
            />
          </InputLabelWrapper>

          <InputWithSelector>
            <InputLabel text={"From"} textSize={10} required={true} />

            <Flex>
              <Dropdown
                items={[
                  { value: "more", content: { text: "(" } },
                  { value: "moreAndEqual", content: { text: "[" } },
                ]}
                width={100}
                listWidth={100}
                size={DropdownSize.S}
                onItemClick={(value) => {
                  //@ts-ignore
                  // setFromValue(value);
                  formik.setFieldValue(
                    "eventAmountIncluded",
                    value === "moreAndEqual"
                  );
                }}
                selectedValue={
                  !!formik.values.eventAmountIncluded ? "moreAndEqual" : "more"
                }
                type={DropdownType.OUTLINED}
                maxVisibleItems={2}
                padding={"8px 8px 8px 16px"}
              />
              <InputWithRightMargin
                {...formik.getFieldProps("eventAmount")}
                placeholder={"From"}
                error={
                  (formik.touched.eventAmount && formik.errors.eventAmount) ||
                  ""
                }
              />
            </Flex>
          </InputWithSelector>
          <Separator>-</Separator>
          <InputWithSelector>
            <InputLabel text={"To"} textSize={10} />
            <Flex>
              <Dropdown
                items={[
                  { value: "more", content: { text: ")" } },
                  { value: "moreAndEqual", content: { text: "]" } },
                ]}
                width={100}
                listWidth={100}
                size={DropdownSize.S}
                onItemClick={
                  (value) => {
                    //@ts-ignore
                    // setToValue(value);
                    formik.setFieldValue(
                      "eventAmountToIncluded",
                      value === "moreAndEqual"
                    );
                  }
                  // formik.setFieldValue("operatorResourceId", value)
                }
                selectedValue={
                  !!formik.values.eventAmountToIncluded
                    ? "moreAndEqual"
                    : "more"
                }
                type={DropdownType.OUTLINED}
                maxVisibleItems={2}
                padding={"8px 8px 8px 16px"}
              />
              <InputWithRightMargin
                {...formik.getFieldProps("eventAmountTo")}
                placeholder={"To"}
                error={
                  (formik.touched.eventAmountTo &&
                    formik.errors.eventAmountTo) ||
                  ""
                }
              />
            </Flex>
          </InputWithSelector>
        </WrapperCoefficient>

        <WrapperCoefficient>
          <InputLabelWrapper>
            <InputLabel
              text={"Sport Coefficient"}
              textSize={12}
              required={true}
            />
          </InputLabelWrapper>

          <InputWithSelector>
            <InputLabel text={"From"} textSize={10} />

            <Flex>
              <Dropdown
                items={[
                  { value: "more", content: { text: "(" } },
                  { value: "moreAndEqual", content: { text: "[" } },
                ]}
                width={100}
                listWidth={100}
                size={DropdownSize.S}
                onItemClick={
                  (value) => {
                    //@ts-ignore
                    // setFromValue(value);

                    formik.setFieldValue(
                      "coefficientFromIncluded",
                      value === "moreAndEqual"
                    );
                  }
                  // formik.setFieldValue("operatorResourceId", value)
                }
                selectedValue={
                  !!formik.values.coefficientFromIncluded
                    ? "moreAndEqual"
                    : "more"
                }
                type={DropdownType.OUTLINED}
                maxVisibleItems={2}
                padding={"8px 8px 8px 16px"}
              />
              <InputWithRightMargin
                {...formik.getFieldProps("coefficientFrom")}
                placeholder={"From"}
                error={
                  (formik.touched.coefficientFrom &&
                    formik.errors.coefficientFrom) ||
                  ""
                }
              />
            </Flex>
          </InputWithSelector>
          <Separator>-</Separator>
          <InputWithSelector>
            <InputLabel text={"To"} textSize={10} />
            <Flex>
              <Dropdown
                items={[
                  { value: "more", content: { text: ")" } },
                  { value: "moreAndEqual", content: { text: "]" } },
                ]}
                width={100}
                listWidth={100}
                size={DropdownSize.S}
                onItemClick={
                  (value) => {
                    //@ts-ignore
                    // setToValue(value);
                    formik.setFieldValue(
                      "coefficientToIncluded",
                      value === "moreAndEqual"
                    );
                  }
                  // formik.setFieldValue("operatorResourceId", value)
                }
                selectedValue={
                  !!formik.values.coefficientToIncluded
                    ? "moreAndEqual"
                    : "more"
                }
                type={DropdownType.OUTLINED}
                maxVisibleItems={2}
                padding={"8px 8px 8px 16px"}
              />
              <InputWithRightMargin
                {...formik.getFieldProps("coefficientTo")}
                placeholder={"To"}
                error={
                  (formik.touched.coefficientTo &&
                    formik.errors.coefficientTo) ||
                  ""
                }
              />
            </Flex>
          </InputWithSelector>
        </WrapperCoefficient>

        {!editGradeItem?.id && (
          <DropdownWrapper>
            <InputLabel
              text={"Operator Resource Id"}
              textSize={10}
              required={true}
            />
            <Dropdown
              items={resources.map((item) => ({
                key: item.id,
                value: item.name,
                content: { text: item.name },
              }))}
              width={347}
              listWidth={347}
              size={DropdownSize.S}
              onItemClick={(value) =>
                formik.setFieldValue("operatorResourceId", value)
              }
              selectedValue={formik.values.operatorResourceId}
              type={DropdownType.OUTLINED}
              maxVisibleItems={5}
              error={
                formik.touched.operatorResourceId &&
                formik.errors.operatorResourceId
                  ? formik.errors.operatorResourceId
                  : ""
              }
              // width={DropdownListWidth[DropdownSize.M]}
            />
          </DropdownWrapper>
        )}
        <DropdownWrapper>
          <InputLabel text={"Multiplier"} textSize={12} required={true} />
          <Input
            {...formik.getFieldProps("multiplier")}
            placeholder={"Multiplier"}
            error={
              (formik.touched.multiplier && formik.errors.multiplier) || ""
            }
            required={true}
          />
        </DropdownWrapper>
        {editGradeItem?.id && (
          <>
            <DropdownWrapper>
              <InputLabel
                text={"Advanced settings"}
                textSize={12}
                required={true}
              />

              <CheckboxWrapper>
                <Checkbox
                  checked={formik.values.isHidden}
                  onClick={() => {
                    if (editGradeItem?.id) {
                      formik.setFieldValue("isHidden", !formik.values.isHidden);
                    }
                  }}
                />

                <CheckboxText size={TextSize.XS} weight={TextWeight.MEDIUM}>
                  Grade Is Hidden
                </CheckboxText>
              </CheckboxWrapper>
            </DropdownWrapper>

            <Exclamation>
              <Icon icon={IconType.exclamation} size={20}></Icon>
              <TextWithLeftMargin
                size={TextSize.XS}
                color={Color.PRIMARY80}
                align={TextAlign.LEFT}
              >
                When a grade is hidden, the admin panel user will no longer be
                able to interact with it in any way, only view it in the hidden
                tab.
              </TextWithLeftMargin>
            </Exclamation>
          </>
        )}

        <ButtonWrapper>
          <Button
            fullWidth={true}
            customMaxWidth={140}
            size={ButtonSize.L}
            type={HTMLButtonType.SUBMIT}
            disabled={
              editGradeItem && !!editGradeItem.id && !!editGradeItem.isHidden
            }
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            Confirm
          </Button>
          <ButtonWithLeftMargin>
            <Button
              onClick={() => {
                dispatch(resetEditGradeItem());
                dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
              }}
              fullWidth={true}
              customMaxWidth={140}
              size={ButtonSize.L}
              buttonType={ButtonType.WHITE}
            >
              Cancel
            </Button>
          </ButtonWithLeftMargin>
        </ButtonWrapper>
        <div>
          {errorMessageSubmit && (
            <ErrorText color={Color.RED_HOT_HOOD}>
              {errorMessageSubmit}
            </ErrorText>
          )}
        </div>
      </form>
    </AuthFormWrapper>
  );
}
