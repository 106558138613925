import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { Icon } from "./Icon";

const CheckboxContainer = styled.div<{ disabled?: boolean, checked: boolean }>`
  position: relative;
  border: ${({ checked }) => (checked ? `1px solid ${Color.GREEN}` : `1px solid ${Color.BORDER_GRAY}`)};
  width: 28px;
  height: 28px;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  span {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  svg {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  }
`;

type onClickType = (
  ev: React.MouseEvent<HTMLDivElement, MouseEvent>
) => void | false;

export type CheckboxProps = {
  checked?: boolean;
  partial?: boolean;
  disabled?: boolean;
  transparent?: boolean;
  onClick: onClickType;
};
// export function Checkbox({ checked = false, partial = false, transparent = false, onClick, disabled }: CheckboxProps) {
export function Checkbox({
  checked = false,
  partial = false,
  transparent = false,
  onClick,
  disabled,
}: CheckboxProps) {
  return (
    <CheckboxContainer
      onClick={(ev) => !disabled && onClick(ev)}
      disabled={disabled}
      checked={checked}
    >
      {checked && <Icon icon={IconType.checkmarkChecked} color={Color.SECONDARY}/>}
    </CheckboxContainer>
  );
}
