import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip, TooltipPlacement } from './Tooltip';
import { Color } from '../../constants/ColorEnum';

export enum DropdownSize {
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
}

const TOOLTIP_MAX_WIDTH = 191;

export const SizeValues = {
    [DropdownSize.S]: {
        height: 36,
        padding: '0 12px',
        marginBottom: 4,
    },
    [DropdownSize.M]: {
        height: 36,
        padding: '0 12px',
        marginBottom: 4,
    },
    [DropdownSize.L]: {
        height: 48,
        padding: '0 8px',
        marginBottom: 4,
    },
    [DropdownSize.XL]: {
        height: 54,
        padding: '8px 12px',
        marginBottom: 4,
    },
};

export type DropDownItemStyleProps = {
    value: string | number;
    selected?: boolean;
    tmpSelected?: boolean;
    size: DropdownSize;
    disabled?: boolean;
    itemAsLink?: boolean;
    borderRadius?: number;
    disableHover?: boolean;
    hideBackgroundColorOnSelected?: boolean;
};

const Item = styled.div<DropDownItemStyleProps>`
    box-sizing: border-box;
    ${({ borderRadius }) => css`
        border-radius: ${borderRadius ? `${borderRadius}px` : '6px'};
    `};
    display: flex;
    align-items: center;
    transition: background-color 0.1s ease-in-out;
    ${({ disabled }) => css`
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
    `};
    position: relative;
    width: 100%;

    > * {
        margin-right: 12px;
    }
    > *:last-child {
        margin-right: 0;
    }

    ${({ size, selected, hideBackgroundColorOnSelected }) => css`
        height: ${SizeValues[size].height}px;
        padding: ${SizeValues[size].padding};
        &:not(:last-child) {
            margin-bottom: ${SizeValues[size].marginBottom}px;
        }
        background-color: ${selected && !hideBackgroundColorOnSelected ? Color.ACCENT10 : ''};
        .sub-actions {
            opacity: ${selected ? 1 : 0};
        }
    `}

    ${({ itemAsLink }) =>
        itemAsLink &&
        css`
            padding: 0;
        `}


    ${({ tmpSelected }) =>
        tmpSelected &&
        css`
            background-color: ${Color.PRIMARY05};
        `}

    &:hover {
        ${({ disableHover }) => css`
            background-color: ${disableHover ? 'transparent' : Color.PRIMARY05};
        `}
        .sub-actions {
            opacity: 1;
        }
    }
`;

export type DropDownItemProps = {
    value: string | number;
    children?: React.ReactElement[];
    selected?: boolean;
    disabled?: boolean;
    tooltipText?: string;
    tooltipTextPlacement?: TooltipPlacement;
    tmpSelected?: boolean;
    size: DropdownSize;
    onClick?: (value: string | number) => void;
    onMouseDown?: (value: string | number, e: React.MouseEvent) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    itemAsLink?: boolean;
    className?: string;
    showTooltipOnDisabled?: boolean;
    borderRadius?: number;
    getRef?: (ref: HTMLDivElement | null) => void;
    hideBackgroundColorOnSelected?: boolean;
};

export function DropdownListItem({
    value,
    children,
    size,
    onClick,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    selected = false,
    disabled = false,
    tmpSelected = false,
    itemAsLink,
    tooltipText = '',
    className = '',
    tooltipTextPlacement = TooltipPlacement.LEFT,
    showTooltipOnDisabled = false,
    borderRadius,
    hideBackgroundColorOnSelected,
    getRef,
}: DropDownItemProps) {
    function onItemClick(e: React.MouseEvent) {
        e.stopPropagation();
        onClick && !disabled && onClick(value);
    }

    function onItemMouseDown(e: React.MouseEvent) {
        onMouseDown && onMouseDown(value, e);
    }

    const renderItem = () => (
        <Item
            data-testid="listItem"
            ref={(ref) => {
                getRef && getRef(ref);
            }}
            className={className}
            key={value}
            value={value}
            size={size}
            selected={selected}
            onClick={onClick && onItemClick}
            tmpSelected={tmpSelected}
            onMouseDown={onItemMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            disabled={!showTooltipOnDisabled && disabled}
            itemAsLink={itemAsLink}
            borderRadius={borderRadius}
            disableHover={disabled}
            hideBackgroundColorOnSelected={hideBackgroundColorOnSelected}
        >
            {children}
        </Item>
    );

    return tooltipText ? (
        <Tooltip placement={tooltipTextPlacement} value={tooltipText} maxWidth={TOOLTIP_MAX_WIDTH}>
            {renderItem()}
        </Tooltip>
    ) : (
        renderItem()
    );
}
