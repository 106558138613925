import { useEffect, RefObject } from 'react';

export function useClickOutsideDropdown<T extends HTMLElement = HTMLElement>(
    refArray: RefObject<T>[],
    handler: (event: MouseEvent) => void,
): void {
    useEffect(() => {
        const listener = (event: MouseEvent) => {

            const containTarget = refArray.find((ref) => !ref.current ||
                // @ts-expect-error
                ref.current.contains(event.target));
            if (
                containTarget
            ) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...refArray, handler]);
}
