import React, { useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { Button, ButtonSize } from "../../../shared/components/Button";
import { Text } from "../../../shared/components/Text";
import { Modal } from "../../../shared/components/Modal";
import { GradeAddForm } from "./GradeAddForm";
import { getGrades, getHiddenGrades, updateGrade } from "../redux/actions";
import { PageCountEnum } from "../../../constants/PageCountEnum";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import { Loading } from "../../../constants/Loading";
import { Icon } from "../../../shared/components/Icon";
import { UpdateValueType } from "../../../shared/components/EditableField";
import { getResources } from "../../Resources/redux/actions";
import { Checkbox } from "../../../shared/components/Checkbox";
import { resetEditGradeItem, setEditGradeItem } from "../redux/grades.slice";
import {
  ScrollArea,
  ScrollAreaSize,
} from "../../../shared/components/ScrollArea";
import { Sorting } from "../../../shared/components/Sorting";
import { InputLabel } from "../../../shared/components/InputLabel";
import { Dropdown, DropdownType } from "../../../shared/components/Dropdown";
import { DropdownSize } from "../../../shared/components/DropdownList";
import { Input } from "../../../shared/components/Input";
import moment from "moment";
import TextAlign from "../../../constants/TextAlign";
import { Tooltip, TooltipPlacement } from "../../../shared/components/Tooltip";
import HiddenTooltip, {
  TooltipContent,
} from "../../../shared/components/HiddenTooltip";

const TableWrapper = styled.div`
  position: relative;
  min-width: 1000px;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const FirstColumnCell = styled.div`
  display: flex;
  margin-left: 16px;
  width: calc(13.5% - 16px);
  white-space: nowrap;
`;

const ColumnCell = styled.div`
  display: flex;
  width: 13.5%;
  margin-right: 12px;
  position: relative;
  justify-content: center;
`;

const LastCell = styled.div`
  display: flex;
  justify-content: center;
  width: calc(5.5% - 12px);
  margin-right: 12px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  background-color: ${Color.SECONDARY};
  border-bottom: 1px solid ${Color.PRIMARY20};
  cursor: pointer;
`;

const LoaderWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
`;

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const StatsFiltersWrapper = styled.div`
  display: flex;
`;

const DropdownWrapper = styled.section`
  margin-top: 16px;
`;

const Flex = styled.div`
  display: flex;
`;

const InputWithRightMargin = styled(Input)`
  margin-top: 16px;
  margin-right: 8px;
`;

const TextStyled = styled(Text)`
  white-space: nowrap;
`;

export function GradesTable() {
  const dispatch = useAppDispatch();
  const showEditGradesModal =
    useAppSelector((state) => state.modalHub.shownModal) ===
    ShowModalEnum.GRADE_EDIT_MODAL;
  const {
    result,
    loading,
    loadingUpdate: { id: loadingUpdateId },
  } = useAppSelector((state) => state.grades);
  const [page] = useState("1");
  const [limit] = useState(PageCountEnum.TWO_HUNDRED);
  const resources = useAppSelector((state) => state.resources.result);

  useEffectSkipFirst(() => {
    dispatch(getGrades({ page, limit })).then(() => {
      dispatch(getHiddenGrades({ page: "1", limit: "9999" }));
    });

    if (!resources.length) {
      dispatch(getResources({ page: "1", limit: "9999" }));
    }
  }, []);

  const updateValue = ({ value, fieldName, id }: UpdateValueType) => {
    dispatch(updateGrade({ id, [fieldName]: value }));
  };

  const updateCoef = ({
    coefficientFrom,
    coefficientTo,
    id,
  }: {
    coefficientFrom: number;
    coefficientTo: number;
    id: string;
  }) => {
    dispatch(
      updateGrade({
        id,
        coefficientFrom: coefficientFrom || 0.001,
        coefficientTo: coefficientTo || coefficientFrom + 0.001,
      })
    );
  };

  const [sortingItems, setSortingItems] = useState([
    { title: "Active", isActive: true },
    { title: "Hidden", isActive: false },
  ]);

  const onSortItemClick = (value: any) => {
    console.log(value, "vvv");
    const updatedValue = sortingItems.map((item) =>
      item.title === value.title
        ? { ...item, isActive: true }
        : { ...item, isActive: false }
    );
    setSortingItems(updatedValue);
  };

  const [operatorResourceId, setOperatorResourceId] = useState("all_operators");

  const initialValues = {
    operatorResourceId: "",
  };

  const [filterByName, setFilterByName] = useState("");

  return (
    <>
      <TitleTextWrapper>
        <Text size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
          Grades
        </Text>
        <Button
          icon={IconType.lightning}
          size={ButtonSize.L}
          onClick={() => {
            dispatch(resetEditGradeItem());
            dispatch(
              setShownModal({ shownModal: ShowModalEnum.GRADE_EDIT_MODAL })
            );
          }}
        >
          Add new
        </Button>
      </TitleTextWrapper>

      <StatsFiltersWrapper>
        <Sorting items={sortingItems} onSortItemClick={onSortItemClick} />
      </StatsFiltersWrapper>
      <Flex>
        <InputWithRightMargin
          value={filterByName}
          label="Name includes"
          onChange={(e) => setFilterByName(e.target.value)}
          placeholder={"Filter name"}
        />
        <StatsFiltersWrapper>
          <DropdownWrapper>
            <InputLabel text={"Operator Resource Id"} textSize={10} />
            <Dropdown
              items={[{ id: "all_operators", name: "All" }, ...resources].map(
                (item) => ({
                  key: item.id,
                  value: item.id,
                  content: { text: item.name },
                })
              )}
              width={347}
              listWidth={347}
              size={DropdownSize.S}
              onItemClick={(value) => {
                //@ts-ignore
                setOperatorResourceId(value);
              }}
              selectedValue={operatorResourceId}
              type={DropdownType.OUTLINED}
              maxVisibleItems={5}

              // width={DropdownListWidth[DropdownSize.M]}
            />
          </DropdownWrapper>
        </StatsFiltersWrapper>
      </Flex>
      {/* <FilterButtonWrap>
        <Button
          icon={IconType.lightning}
          size={ButtonSize.L}
          onClick={() => {}}
        >
          Filter
        </Button>
      </FilterButtonWrap> */}
      {loading === Loading.SUCCESS && (
        <ScrollArea
          size={ScrollAreaSize.S}
          autoHeightMin={132}
          autoHeightMax={900}
          autoHeight
        >
          <TableWrapper>
            <TableHeader>
              {/* <FirstColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                ID
              </Text>
            </FirstColumnCell>
            <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Operator Id
              </Text>
            </ColumnCell> */}
              <FirstColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Operator Resource Id
                </Text>
              </FirstColumnCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Created At
                </Text>
              </ColumnCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Updated At
                </Text>
              </ColumnCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Event Amount
                </Text>
              </ColumnCell>
              {/* <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Event Amount To
                </Text>
              </ColumnCell> */}
              {/* <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Mint Percentage
              </Text>
            </ColumnCell> */}
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Name
                </Text>
              </ColumnCell>
              {/* <ColumnCell>
              <Text
                color={Color.PRIMARY60}
                size={TextSize.XXS}
                weight={TextWeight.MEDIUM}
              >
                Pool Percentage
              </Text>
            </ColumnCell> */}
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Multiplier
                </Text>
              </ColumnCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Coefficient
                </Text>
              </ColumnCell>
              {/* <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Coefficient To
                </Text>
              </ColumnCell> */}
              <LastCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Is Hidden
                </Text>
              </LastCell>
            </TableHeader>
            {result
              .filter((e) =>
                operatorResourceId !== "all_operators"
                  ? e.operatorResourceId === operatorResourceId
                  : true
              )
              .filter((e) =>
                sortingItems.find((item) => item.isActive)?.title === "Active"
                  ? !e.isHidden
                  : e.isHidden
              )
              .filter((e) => e.name.includes(filterByName))
              .map((item, i) => (
                <TableRow
                  key={"gradesTableRow_" + i}
                  onClick={() => {
                    dispatch(setEditGradeItem({ item }));
                    dispatch(
                      setShownModal({
                        shownModal: ShowModalEnum.GRADE_EDIT_MODAL,
                      })
                    );
                  }}
                >
                  <FirstColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      align={TextAlign.LEFT}

                      ellipsis
                    >
                      {item.operatorResourceId &&
                        resources.find(
                          (res) => res.id === item.operatorResourceId
                        )?.name}
                    </TextStyled>
                  </FirstColumnCell>

                  <ColumnCell>
                    <HiddenTooltip
                      timerDuration={0}
                      content={
                        <TooltipContent
                          color={Color.SECONDARY}
                          size={TextSize.XS}
                          weight={TextWeight.MEDIUM}
                        >
                          {moment(item.createdAt).format("MM-DD-YYYY hh:mm a")}
                        </TooltipContent>
                      }
                    >
                      <Text
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                        align={TextAlign.CENTER}
                        ellipsis
                      >
                        {moment(item.createdAt).format("MM-DD-YYYY")}
                      </Text>
                    </HiddenTooltip>
                  </ColumnCell>

                  <ColumnCell>
                    <HiddenTooltip
                      timerDuration={0}
                      content={
                        <TooltipContent
                          color={Color.SECONDARY}
                          size={TextSize.XS}
                          weight={TextWeight.MEDIUM}
                          align={TextAlign.CENTER}
                        >
                          {moment(item.updatedAt).format("MM-DD-YYYY hh:mm a")}
                        </TooltipContent>
                      }
                    >
                      <Text
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                        align={TextAlign.CENTER}
                        ellipsis
                      >
                        {moment(item.updatedAt).format("MM-DD-YYYY")}
                      </Text>
                    </HiddenTooltip>
                  </ColumnCell>

                  <ColumnCell>
                    <Text
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.eventAmountIncluded ? "[ " : "("}
                      {item.eventAmount || "0"},&nbsp;
                      {item.eventAmountTo || "∞"}&nbsp;
                      {item.eventAmountToIncluded ? "]" : ")"}
                    </Text>
                  </ColumnCell>

                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.name}
                    </TextStyled>
                  </ColumnCell>
                  <ColumnCell>
                    <Text
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.multiplier}
                    </Text>
                  </ColumnCell>
                  <ColumnCell>
                    <Text
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.coefficientFromIncluded ? "[ " : "("}&nbsp;
                      {item.coefficientFrom || (item.coefficientFrom === 0 ? "0" :  "-")},&nbsp;
                      {item.coefficientTo || (item.coefficientTo === 0 ? "0" :  "-")}&nbsp;
                      {item.coefficientToIncluded ? "]" : ")"}
                    </Text>
                  </ColumnCell>

                  <LastCell>
                    {/* <Checkbox checked={item.isHidden} onClick={(value) => {}} /> */}
                    {item.isHidden ? (
                      <Icon icon={IconType.visibleNot} />
                    ) : (
                      <Icon icon={IconType.visible} />
                    )}
                  </LastCell>
                </TableRow>
              ))}
          </TableWrapper>
        </ScrollArea>
      )}
      {loading === Loading.PENDING && (
        <LoaderWrap>
          <RewindLoader color={"#233447"} size={110} sizeUnit={"px"} />
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Loading date
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                Please take a deep breath
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {loading === Loading.ERROR && (
        <LoaderWrap>
          <Icon
            icon={IconType.error}
            size={80}
            color={Color.BRIGHT_GRAY}
          ></Icon>
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Something went wrong.
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                No data fetched. Please reload page or come back in few mins
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {showEditGradesModal && (
        <Modal>
          <GradeAddForm />
        </Modal>
      )}
    </>
  );
}
