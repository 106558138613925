import React, { useRef, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setAuth } from "../redux/actions";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import logo from "../../../resources/svgs/logo.svg";
import { AccentColor, Input } from "../../../shared/components/Input";
import { Button, HTMLButtonType } from "../../../shared/components/Button";
import { Navigate, useNavigate } from "react-router";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import Cookies from "js-cookie";
import { logout } from "../redux/auth.slice";
import ReCAPTCHA from "react-google-recaptcha";

const AuthFormLogo = styled.img`
  width: 215px;
`;

const InputWithMargin = styled(Input)`
  margin-top: 20px;
`;

const AuthFormWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // height: 501px;
  width: 459px;
  margin: 40px auto;
  border-radius: 8px;
  padding: 48px 56px 48px 56px;
  background: ${Color.SECONDARY};
  box-shadow: 0px 14px 24px rgba(73, 83, 105, 0.2);
  border-radius: 8px;
`;

const AuthFormNameWrapper = styled.div`
  margin: 16px 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 27px;
`;

const ContactUsText = styled(Text)`
  margin-top: 27px;
`;

const ErrorText = styled(Text)`
  margin-top: 10px;
`;

const ReCAPTCHAWrapper = styled.div`
  height: 70px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
`;

const InputError = styled.span`
  display: flex;
  color: ${AccentColor.RED};
  font-size: ${TextSize.XXXS}px;
  font-weight: ${TextWeight.MEDIUM};
  line-height: 14px;
  text-align: left;
  margin-top: 4px;
`;

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(250, "Maximum 250 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(250, "Maximum 250 symbols")
    .required("Password is required"),
  recaptcha: Yup.string().required("Please, fill recaptcha"),
});

const initialValues = {
  email: "",
  password: "",
  recaptcha: "",
};

export function AuthForm() {
  const dispatch = useAppDispatch();
  const { errorMessage } = useAppSelector((state) => state.auth);
  const navigation = useNavigate();
  const captchaRef = useRef(null);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async ({ email, password, recaptcha }) => {
      setError("");
      if (!captchaRef.current) {
        return;
      }

      //@ts-ignore
      captchaRef.current.reset();
      const result = await dispatch(setAuth({ email, password, recaptcha }));

      if (result.type === "auth/setAuth/fulfilled") {
        navigation(RoutesTypes.dashboard);
      } else if (
        result.type === "auth/setAuth/rejected" &&
        result.payload &&
        typeof result.payload === "string"
      ) {
        setError(result.payload);
      }
    },
  });

  const token = Cookies.get("token");
  const expiresIn = Cookies.get("expires_in");

  if (token
    && expiresIn 
    // && Number(expiresIn) > new Date().getTime()
    ) {
    // return <Navigate to={RoutesTypes.dashboard} />
    return <Navigate to={RoutesTypes.grades} />;
  } else {
    dispatch(logout());

    return (
      <AuthFormWrapper>
        <form onSubmit={formik.handleSubmit}>
          <AuthFormLogo src={logo} />
          <AuthFormNameWrapper>
            <Text size={TextSize.M} weight={TextWeight.SEMIBOLD}>
              Admin system
            </Text>
          </AuthFormNameWrapper>
          {formik.status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <Input
            label="Login"
            {...formik.getFieldProps("email")}
            placeholder={"username@mail.com"}
            error={(formik.touched.email && formik.errors.email) || ""}
            required={true}
          />
          <InputWithMargin
            label="Password"
            type={"password"}
            {...formik.getFieldProps("password")}
            placeholder={"*******"}
            error={(formik.touched.password && formik.errors.password) || ""}
            required={true}
          />
          <ReCAPTCHAWrapper>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
              onChange={(recaptcha) => {
                formik.setFieldValue("recaptcha", recaptcha);
              }}
              ref={captchaRef}
            />
          </ReCAPTCHAWrapper>
          {formik.touched.recaptcha && formik.errors.recaptcha ? (
            <InputError>{formik.errors.recaptcha}</InputError>
          ) : null}
          <ButtonWrapper>
            <Button
              key="cancel"
              fullWidth={true}
              type={HTMLButtonType.SUBMIT}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Login
            </Button>
          </ButtonWrapper>
          <div>
            {errorMessage && (
              <ErrorText color={Color.RED_HOT_HOOD}>{errorMessage}</ErrorText>
            )}
            {error && <ErrorText color={Color.RED_HOT_HOOD}>{error}</ErrorText>}
          </div>
          <ContactUsText size={TextSize.XS} weight={TextWeight.MEDIUM}>
            No account? Contact us
          </ContactUsText>
        </form>
      </AuthFormWrapper>
    );
  }
}
