import React, { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Input } from "../../../shared/components/Input";
import {
  Button,
  ButtonSize,
  ButtonType,
  HTMLButtonType,
} from "../../../shared/components/Button";

import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { PageCountEnum } from "../../../constants/PageCountEnum";
import { createResource, getResources } from "../redux/actions";

const InputWithMargin = styled(Input)`
  margin-top: 20px;
`;

const AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const AuthFormNameWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-top: 27px;
`;

const ErrorText = styled(Text)`
  margin-top: 10px;
`;

const ButtonWithLeftMargin = styled.div`
  margin-left: 16px;
`;

const editResourceItemSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(250, "Maximum 250 symbols")
    .required("Title is required"),
  foreignResourceId: Yup.string().required("Foreign Resource Id is required"),
});

export function ResourceAddForm() {
  const dispatch = useAppDispatch();
  // const { errorMessageSubmit } = useAppSelector((state) => state.resources);
  const [errorMessageSubmit, setErrorMessageSubmit] = useState('');

  const resourceItem = useAppSelector(
    (state) => state.resources.editResourceItem
  );

  const initialValues = {
    name: resourceItem?.name || "",
    id: resourceItem?.id || "",
    foreignResourceId: resourceItem?.foreignResourceId || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editResourceItemSchema,
    onSubmit: async (values) => {
      const submitObject: {
        name: string;
        id?: string;
        foreignResourceId: string;
      } = values;
      setErrorMessageSubmit('');
      if (!resourceItem?.id) {
        delete submitObject.id;
      }
      dispatch(createResource({ ...values })).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
          dispatch(getResources({ page: "1", limit: "9999" }));
        }

        if (data.meta.requestStatus === 'rejected') {
          console.log(data);
          setErrorMessageSubmit(data.payload)
        }
      })
    },
  });

  return (
    <AuthFormWrapper>
      <form onSubmit={formik.handleSubmit}>
        <AuthFormNameWrapper>
          <Text size={TextSize.M} weight={TextWeight.SEMIBOLD}>
            Resource settings
          </Text>
        </AuthFormNameWrapper>
        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <Input
          label="Foreign Resource Id"
          {...formik.getFieldProps("foreignResourceId")}
          placeholder={"Foreign Resource Id"}
          error={
            (formik.touched.foreignResourceId &&
              formik.errors.foreignResourceId) ||
            ""
          }
          required={true}
        />
        <InputWithMargin
          label="Name"
          {...formik.getFieldProps("name")}
          placeholder={"Name"}
          error={(formik.touched.name && formik.errors.name) || ""}
          required={true}
        />

        <ButtonWrapper>
          <Button
            fullWidth={true}
            customMaxWidth={140}
            size={ButtonSize.L}
            type={HTMLButtonType.SUBMIT}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Confirm
          </Button>
          <ButtonWithLeftMargin>
            <Button
              onClick={() =>
                dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }))
              }
              fullWidth={true}
              customMaxWidth={140}
              size={ButtonSize.L}
              buttonType={ButtonType.WHITE}
            >
              Cancel
            </Button>
          </ButtonWithLeftMargin>
        </ButtonWrapper>
        <div>
          {errorMessageSubmit && (
            <ErrorText color={Color.RED_HOT_HOOD}>
              {errorMessageSubmit}
            </ErrorText>
          )}
        </div>
      </form>
    </AuthFormWrapper>
  );
}
