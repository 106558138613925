export enum IconType {
  rocket = "rocket",
  settings = "settings",
  close = "close",
  success = "success",
  loader = "loader",
  error = "error",
  arrow = "arrow",
  shevron = "shevron",
  lightning = "lightning",
  visible = "visible",
  visibleNot = "visibleNot",
  cross = "cross",
  checkmarkPartial = "checkmarkPartial",
  checkmarkChecked = "checkmarkChecked",
  editCancel = 'editCancel',
  editPencil = 'editPencil',
  editSubmit = 'editSubmit',
  chevronDown = 'chevronDown',
  exclamation = 'exclamation',
  chevronRight = 'chevronRight',
  chevronLeft = 'chevronLeft',
  calendar = 'calendar',
}
