import React from 'react';
import styled from 'styled-components';
import { Color } from '../../constants/ColorEnum';

export enum SeparatorDirection {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

const StyledSeparatorContent = styled.div`
    ${({ direction, width, height }) => {
        if (direction === SeparatorDirection.VERTICAL) {
            const h = height ? `${height}px` : '100%';
            return `
                    width: 1px;
                    height: ${h};
            `;
        } else {
            const w = width ? `${width}px` : '100%';
            return `
                    height: 1px;
                    width: ${w};
            `;
        }
    }};
    background: ${({ color }: SeparatorProps) => color};
`;
const StyledSeparator = styled.div`
    padding: ${({ padding }: SeparatorProps) => `${padding}`};
`;

export type SeparatorProps = {
    width?: number; //for Horizontal
    height?: number; //for Vertical
    color?: Color;
    padding?: string;
    direction?: SeparatorDirection;
};

/**
 * This component is used to add separator line in the application
 */
export function Separator({
    width = undefined,
    height = undefined,
    color = Color.PRIMARY10,
    padding = '0 14px',
    direction,
}: SeparatorProps) {
    return (
        <StyledSeparator padding={padding}>
            <StyledSeparatorContent width={width} height={height} direction={direction} color={color} />
        </StyledSeparator>
    );
}