import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { GetBonusesShema } from "../../Bonuses/redux/actions";

//TO DO
export const getTranslations = createAsyncThunk(
  "translation/getTranslations",
  async ({ page, limit }: GetBonusesShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/operator/get-resources?limit=${limit}&page=${page}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const createTranslation = createAsyncThunk(
  "translation/createTranslation",
  async (value: any) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/edit-resource`,
      value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const updateTranslation = createAsyncThunk(
  "translation/updateTranslation",
  async (value: any) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/edit-resource`,
      value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

export const resetUserWallet = createAsyncThunk(
  "userManagement/resetUserWallet",
  async (value: any) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/operator/remove-client-wallet`,
      value,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    );
    return data;
  }
);

