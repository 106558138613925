import React, { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Input } from "../../../shared/components/Input";
import {
  Button,
  ButtonSize,
  ButtonType,
  HTMLButtonType,
} from "../../../shared/components/Button";

import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { PageCountEnum } from "../../../constants/PageCountEnum";
import { resetUserWallet } from "../redux/actions";

const InputWithMargin = styled(Input)`
  margin-top: 20px;
`;

const AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const AuthFormNameWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-top: 27px;
`;

const ErrorText = styled(Text)`
  margin-top: 10px;
`;

const ButtonWithLeftMargin = styled.div`
  margin-left: 16px;
`;

const editTranslationItemSchema = Yup.object().shape({
  userForeignId: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(250, "Maximum 250 symbols")
    .required("Foreign Id is required"),
});

export function UserManagementForm() {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    userForeignId:  "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editTranslationItemSchema,
    onSubmit: async (values) => {
      setErrorMessage("");
      const submitObject: {
        userForeignId: string;
        // russian: string,
        // english: string
      } = values;
      // if (!editItem?.id) {
      //   delete submitObject.id;
      // }
      const data = await dispatch(resetUserWallet({ ...values }));
      console.log(data, 'DATA')
      if (data?.type === "userManagement/resetUserWallet/fulfilled") {
        dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
        // dispatch(getTranslations({ page: "1", limit: PageCountEnum.TWO_HUNDRED }));
      }

      if (data?.type === "userManagement/resetUserWallet/rejected") {
        //@ts-ignore
        setErrorMessage(data.error.message);
        console.log('REJECTED')
        
      }
    },
  });

  return (
    <AuthFormWrapper>
      <form onSubmit={formik.handleSubmit}>
        <AuthFormNameWrapper>
          <Text size={TextSize.M} weight={TextWeight.SEMIBOLD}>
            Reset user wallet
          </Text>
        </AuthFormNameWrapper>

        {/* <Input
          label="English"
          {...formik.getFieldProps("english")}
          placeholder={"English"}
          error={
            (formik.touched.english &&
              formik.errors.english) ||
            ""
          }
        /> */}
        <InputWithMargin
          label="User's foreign Id"
          {...formik.getFieldProps("userForeignId")}
          placeholder={"User's foreign Id"}
          error={(formik.touched.userForeignId && formik.errors.userForeignId) || ""}
        />

        {/* <InputWithMargin
          label="English"
          {...formik.getFieldProps("english")}
          placeholder={"English"}
          error={(formik.touched.english && formik.errors.english) || ""}
        />

        <InputWithMargin
          label="Russian"
          {...formik.getFieldProps("russian")}
          placeholder={"Russian"}
          error={(formik.touched.russian && formik.errors.russian) || ""}
        /> */}

        <ButtonWrapper>
          <Button
            fullWidth={true}
            customMaxWidth={140}
            size={ButtonSize.L}
            type={HTMLButtonType.SUBMIT}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Confirm
          </Button>
          <ButtonWithLeftMargin>
            <Button
              onClick={() =>
                dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }))
              }
              fullWidth={true}
              customMaxWidth={140}
              size={ButtonSize.L}
              buttonType={ButtonType.WHITE}
            >
              Cancel
            </Button>
          </ButtonWithLeftMargin>
        </ButtonWrapper>
        <div>
          {errorMessage && (
            <ErrorText color={Color.RED_HOT_HOOD}>
              {errorMessage}
            </ErrorText>
          )}
        </div>
      </form>
    </AuthFormWrapper>
  );
}
