import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { Icon } from "./Icon";
import { Input, InputHeight } from "./Input";
import { RewindLoader } from "./RewindLoader";
import { Text } from "./Text";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { HTMLButtonType } from "./Button";
import TextAlign from "../../constants/TextAlign";

enum EditableFieldStateEnum {
  INITIAL = "INITIAL",
  EDIT = "EDIT",
}

export type UpdateValueType = {
  value: string | number | boolean;
  fieldName: string;
  id: string;
};

export type EditableFieldProps = {
  id: string;
  fieldName: string;
  value: string | number;
  maxWidth?: string;
  updateValue?: (value: UpdateValueType) => void;
  isLoading?: boolean;
};

const EditFieldInitial = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrap = styled.button`
  display: flex;
  margin-left: 7px;
  cursor: pointer;

  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background-color: ${Color.SECONDARY};
`;

const editValueSchema = Yup.object().shape({
  value: Yup.string().required("Value is required"),
});

export const EditableField = ({
  id,
  fieldName,
  value,
  maxWidth,
  updateValue,
  isLoading,
}: EditableFieldProps) => {
  const [EditableFieldState, setEditableFieldState] = useState(
    EditableFieldStateEnum.INITIAL
  );

  const initialValues = {
    value: value || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editValueSchema,
    onSubmit: ({ value }) => {
      updateValue && updateValue({ value, fieldName, id });
      setEditableFieldState(EditableFieldStateEnum.INITIAL);
    },
  });

  return (
    <>
      {!isLoading ? (
        <>
          {EditableFieldState === EditableFieldStateEnum.INITIAL && (
            <EditFieldInitial>
              <Text size={TextSize.XS} weight={TextWeight.MEDIUM} align={TextAlign.LEFT} maxWidth={maxWidth || '100px'} ellipsis>
                {value}
              </Text>
              <ButtonWrap
                onClick={() =>
                  setEditableFieldState(EditableFieldStateEnum.EDIT)
                }
              >
                <Icon icon={IconType.editPencil} color={Color.SECONDARY} />
              </ButtonWrap>
            </EditFieldInitial>
          )}
          {EditableFieldState === EditableFieldStateEnum.EDIT && (
            <form onSubmit={formik.handleSubmit}>
              <EditFieldInitial>
                <Input
                  height={InputHeight.S}
                  leftPadding={4}
                  {...formik.getFieldProps("value")}
                  placeholder={"Value"}
                  error={(formik.touched.value && formik.errors.value) || ""}
                  maxLength={30}
                />
                <ButtonWrap type={HTMLButtonType.SUBMIT}>
                  <Icon icon={IconType.editSubmit} color={Color.GREEN} />
                </ButtonWrap>
                <ButtonWrap
                  onClick={() =>
                    setEditableFieldState(EditableFieldStateEnum.INITIAL)
                  }
                >
                  <Icon icon={IconType.editCancel} color={Color.SECONDARY} />
                </ButtonWrap>
              </EditFieldInitial>
            </form>
          )}
        </>
      ) : (
        <RewindLoader color={"#233447"} size={46} sizeUnit={"px"} />
      )}
    </>
  );
};
