export enum RoutesTypes {
  home = "/",

  resources = "/resources",
  dashboard = "/dashboard",
  cashback = "/cashback",
  burning_items = "/burning-items",
  grades = "/grades",
  failed_events = "/failed-events",
  webhooks = "/webhooks",
  auth = "/auth",
  translations = "/translations",
  bonuses_tags = "/bonuses-tags",
  events = "/events",
  user_management = "/user_management",

  app = "/app",
  modal = "/app/modal",
  modal_bonus = "/app/modal/bonus-list",
  modal_bonus_terms = "/app/modal/bonus-terms",
  modal_bonus_loading = "/app/modal/bonus-loading",
  modal_bonus_success = "/app/modal/bonus-success",
  iframe = "/app/iframe",
  carousel = `/app/iframe/carousel`,
  info = "/app/iframe/info",
  connect = "/app/iframe/connect",
  convert = "/app/iframe/convert",
  error = "/app/iframe/error",
  mobile_bonus = "/app/iframe/mobile-bonus",
}
