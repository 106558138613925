import React, { ReactElement } from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../app/hooks";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { setShownModal, ShowModalEnum } from "../redux/modalHub.slice";
import { Icon } from "./Icon";

const ModalWrapper = styled.div`
  position: relative;
  width: 697px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  background-color: ${Color.SECONDARY};
  padding: 24px;
  margin-top: 40px;
`;

const ModalLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Center modal vertically */
  background-color: rgba(54, 65, 89, 0.9);
`;

const CloseModal = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

export function Modal({ children }: { children: ReactElement }) {
  const dispatch = useAppDispatch();

  return (
    <ModalLayout>
      <ModalWrapper>
        {children}
        <CloseModal
          onClick={() =>
            dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }))
          }
        >
          <Icon icon={IconType.cross}></Icon>
        </CloseModal>
      </ModalWrapper>
    </ModalLayout>
  );
}
