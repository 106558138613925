import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Text } from "../../../shared/components/Text";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import { Loading } from "../../../constants/Loading";
import { Icon } from "../../../shared/components/Icon";
import {
  ScrollArea,
  ScrollAreaSize,
} from "../../../shared/components/ScrollArea";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { truncateMiddle } from "../../../utils/utils";
import {
  ITEMS_PER_PAGE,
  UsersListPagination,
} from "../../../shared/components/UsersListPagination";
import HiddenTooltip, {
  TooltipContent,
} from "../../../shared/components/HiddenTooltip";

const TableWrapper = styled.div`
  position: relative;
  min-width: 1600px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const FirstColumnCell = styled.div`
  display: flex;
  padding-left: 16px;
  width: 8%;
`;

const ColumnCell = styled.div`
  display: flex;
  width: 8%;
  padding-right: 12px;
  position: relative;
`;

const ColumnSmallCell = styled.div`
  display: flex;
  width: 6%;
  padding-right: 12px;
  position: relative;
`;

const ColumnLargeCell = styled.div`
  display: flex;
  width: 12%;
  padding-right: 12px;
  position: relative;
`;

const EditableFieldAbsolute = styled.div`
  margin-left: 20px;
`;

const LastCell = styled.div`
  display: flex;
  justify-content: center;
  width: 10%;
  padding-right: 12px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  min-height: 44px;
  background-color: ${Color.SECONDARY};
  border-bottom: 1px solid ${Color.PRIMARY20};
`;

const LoaderWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
`;

const TextStyled = styled(Text)`
  white-space: nowrap;
`;

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

//4 -ColumnCell
//1 first
//4 - ColumnLargeCell
//2- ColumnSmallCell

export function ClientEvents() {
  const { clientsEvents: tabletData } = useAppSelector((state) => state.events);

  return (
    <>
      {tabletData.loading === Loading.SUCCESS && (
        <ScrollArea
          size={ScrollAreaSize.S}
          autoHeightMin={132}
          autoHeightMax={900}
          autoHeight
        >
          <TableWrapper>
            <TableHeader>
              <FirstColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Event Amount
                </Text>
              </FirstColumnCell>

              <ColumnLargeCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Client Foreign Id
                </Text>
              </ColumnLargeCell>
              <ColumnLargeCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Event Date
                </Text>
              </ColumnLargeCell>
              <ColumnLargeCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Event Foreign Id
                </Text>
              </ColumnLargeCell>
              <ColumnSmallCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Multiplier
                </Text>
              </ColumnSmallCell>
              <ColumnSmallCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Processed
                </Text>
              </ColumnSmallCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Grade Id
                </Text>
              </ColumnCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Grade Name
                </Text>
              </ColumnCell>
              <ColumnLargeCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Mint Amount
                </Text>
              </ColumnLargeCell>
              <ColumnCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Pool Amount
                </Text>
              </ColumnCell>
              <LastCell>
                <Text
                  color={Color.PRIMARY60}
                  size={TextSize.XXS}
                  weight={TextWeight.MEDIUM}
                >
                  Resource Foreign Id
                </Text>
              </LastCell>
            </TableHeader>
            {tabletData.result &&
              tabletData.result.map((item, i) => (
                <TableRow key={"clientEventTableRow_" + i}>
                  <FirstColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.eventamount / 10e5}
                    </TextStyled>
                  </FirstColumnCell>
                  <ColumnLargeCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.clientforeignid}
                    </TextStyled>
                  </ColumnLargeCell>
                  <ColumnLargeCell>
                    <HiddenTooltip
                      timerDuration={0}
                      content={
                        <TooltipContent
                          color={Color.SECONDARY}
                          size={TextSize.XS}
                          weight={TextWeight.MEDIUM}
                        >
                          {moment(item.eventdate).format("MM-DD-YYYY hh:mm a")}
                        </TooltipContent>
                      }
                    >
                      <TextStyled
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                        ellipsis
                      >
                        {moment(item.eventdate).format("MM-DD-YYYY")}
                      </TextStyled>
                    </HiddenTooltip>
                  </ColumnLargeCell>
                  <ColumnLargeCell>
                    <EditableFieldAbsolute>
                      <TextStyled
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                        ellipsis
                      >
                        {item.eventforeignid}
                      </TextStyled>
                    </EditableFieldAbsolute>
                  </ColumnLargeCell>

                  <ColumnSmallCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.eventmultiplier}
                    </TextStyled>
                  </ColumnSmallCell>
                  <ColumnSmallCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.eventprocessed ? "ok" : "Failed"}
                    </TextStyled>
                  </ColumnSmallCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.gradeid && truncateMiddle(item.gradeid)}
                    </TextStyled>
                  </ColumnCell>

                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.gradename}
                    </TextStyled>
                  </ColumnCell>
                  <ColumnLargeCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.mintamount / 10e17}
                    </TextStyled>
                  </ColumnLargeCell>
                  <ColumnCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.poolamount / 10e5}
                    </TextStyled>
                  </ColumnCell>
                  <LastCell>
                    <TextStyled
                      size={TextSize.XS}
                      weight={TextWeight.MEDIUM}
                      ellipsis
                    >
                      {item.resourceforeignid}
                    </TextStyled>
                  </LastCell>
                </TableRow>
              ))}
          </TableWrapper>
        </ScrollArea>
      )}
      {tabletData.loading === Loading.PENDING && (
        <LoaderWrap>
          <RewindLoader color={"#233447"} size={110} sizeUnit={"px"} />
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Loading date
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                Please take a deep breath
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {tabletData.loading === Loading.ERROR && (
        <LoaderWrap>
          <Icon
            icon={IconType.error}
            size={80}
            color={Color.BRIGHT_GRAY}
          ></Icon>
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Something went wrong.
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                No data fetched. Please reload page or come back in few mins
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {!!tabletData.count && tabletData.count > ITEMS_PER_PAGE && (
        <UsersListPagination
          pathname="/events"
          total={Number(tabletData.count)}
        />
      )}
    </>
  );
}
