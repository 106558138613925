/* eslint-disable no-useless-escape */
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import TextAlign from "../../../constants/TextAlign";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Text } from "../../../shared/components/Text";
import { setShownModal, ShowModalEnum } from "../../../shared/redux/modalHub.slice";
import {
  StatusIndicator,
  StatusIndicatorEnum,
} from "../../dashboard/components/StatusIndicator";
import { BonusSchema, Language, setEditBonusItem } from "../redux/bonus.slice";

export enum BonusCardUsageEnum {
  SELL_CARD = "SELL_CARD",
  LOADING_PURCHASE = "LOADING_PURCHASE",
  SUCCESS_PURCHASE = "SUCCESS_PURCHASE",
}

export interface BonusCardProps {
  id?: string;
  title?: string;
  save?: string;
  burningItemForeignId?: string;
  description?: string;
  usdPrice: number;
  iconUrl?: string;
  isActive?: boolean;
  // operatorId: string;
  usageType?: BonusCardUsageEnum;
  foreignIdHash?: string;
  purchaseItem?: any;
  percentage?: string;
}

const BonusCardWrapper = styled.div<{ isActive: boolean }>`
  width: 300px;
  padding: 16px;
  background: ${Color.SECONDARY};
  border-radius: 8px;
  margin: 0 10px 20px;
  opacity: ${({ isActive }) => (isActive ? "1" : ".4")};
`;

const BonusCardTitle = styled(Text)`
  line-height: 27px;
`;

const BonusCardStatus = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 18px;
`;

const ImageWrapper = styled.div<{ backgroundImage: string }>`
  height: 115px;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${Color.GREY_LIGHT};
`;

const ParamLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const TextManage = styled(Text)`
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: auto;
  text-decoration-line: underline;
`;

const StatusIndicatorWrapper = styled.div`
  margin: 8px 0 0 30px;
`;

export const BonusCard = (card: BonusSchema) => {
  const dispatch = useDispatch();
  const { title, usdPrice, iconUrl, isActive } = card;
  const expression =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const regex = new RegExp(expression);

  return (
    <BonusCardWrapper isActive={!!isActive}>
      <BonusCardStatus>
        <BonusCardTitle
          size={TextSize.XL}
          weight={TextWeight.SEMIBOLD}
          align={TextAlign.LEFT}
        >
          {title || ""}
        </BonusCardTitle>
        <StatusIndicatorWrapper>
          <StatusIndicator
            status={
              isActive
                ? StatusIndicatorEnum.ACTIVE
                : StatusIndicatorEnum.INACTIVE
            }
          />
        </StatusIndicatorWrapper>
      </BonusCardStatus>
      <ParamLine>
        <Text size={TextSize.XS} weight={TextWeight.MEDIUM}>
          Price (USDT)
        </Text>

        <Text size={TextSize.XS} weight={TextWeight.MEDIUM}>
          {usdPrice}
        </Text>
      </ParamLine>
      <ParamLine>
        {/* <Text size={TextSize.XS} weight={TextWeight.MEDIUM}>
          Saving (USDT)
        </Text> */}
        {/* <Text size={TextSize.XS} weight={TextWeight.MEDIUM}>
          {percentage}
        </Text> */}
      </ParamLine>
      <ParamLine onClick={() => {
        dispatch(setEditBonusItem({item: card, lang: Language.en}))
        dispatch(setShownModal({ shownModal: ShowModalEnum.BONUS_EDIT_MODAL }));
      }}>
        <TextManage
          size={TextSize.XS}
          weight={TextWeight.MEDIUM}
          color={Color.VIOLET}
          underline={true}
          align={TextAlign.LEFT}
        >
          Manage
        </TextManage>
      </ParamLine>
      <ImageWrapper
        backgroundImage={
          iconUrl?.match(regex)
            ? iconUrl
            : "https://i.ibb.co/kG5Ynb0/Screenshot-2022-10-22-at-18-22-35.png"
        }
      />
    </BonusCardWrapper>
  );
};
