import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { Text } from "./Text";

const SortingWrapper = styled.div`
  display: flex;
`;

const SortingItemWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  background-color: ${({ isActive }) =>
    isActive ? Color.VIOLET : Color.SECONDARY};
  border: ${({ isActive }) =>
    isActive ? "none" : `1px solid ${Color.BORDER_GRAY}`};
  border-radius: 20px;
  cursor: pointer;
  margin-left: 16px;
`;

export interface SortingItem {
  title: string;
  isActive: boolean;
}

export interface SortingProps {
  items: SortingItem[];
  onSortItemClick: (item: SortingItem) => void;
}

export const Sorting = ({ items, onSortItemClick }: SortingProps) => {
  return (
    <SortingWrapper>
      {items.map((item, i) => (
        <SortingItemWrapper isActive={item.isActive} key={item.title + "_SortingItem_" + i} onClick={() => onSortItemClick(item)}>
          <Text
            size={TextSize.XL}
            weight={TextWeight.SEMIBOLD}
            color={item.isActive ? Color.SECONDARY : Color.PRIMARY}
          >
            {item.title}
          </Text>
        </SortingItemWrapper>
      ))}
    </SortingWrapper>
  );
};
