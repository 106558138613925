import React from 'react';

export default (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29289 4.29289C7.68342 3.90237 8.31658 3.90237 8.70711 4.29289L12.7071 8.29289C13.0976 8.68342 13.0976 9.31658 12.7071 9.70711L8.70711 13.7071C8.31658 14.0976 7.68342 14.0976 7.29289 13.7071C6.90237 13.3166 6.90237 12.6834 7.29289 12.2929L10.5858 9L7.29289 5.70711C6.90237 5.31658 6.90237 4.68342 7.29289 4.29289Z"
        />
    </svg>
);
