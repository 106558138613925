import React, { KeyboardEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
// import Color from '../constants/Color';
// import { Text } from '../Text';
// import TextSize from '../constants/TextSize';
// import TextWeight from '../constants/TextWeight';
// import IconType from '../constants/IconType';
// import { Key } from '../constants/Key';
// import { Input } from '../Input';
// import { FlexAlignItems, FlexBox, FlexDirection, HorizontalLayout } from '../Layout';
// import { ScrollArea, ScrollAreaSize } from '../ScrollArea';
// import BoxShadow from '../constants/BoxShadow';
import { Scrollbars } from 'react-custom-scrollbars';
// import { Icon } from '../Icon';
// import { DELETE_ICON_BUTTON } from '../utils/DataTestid';
// import WhiteSpace from '../constants/WhiteSpace';
import { Color } from '../../constants/ColorEnum';
import { Text } from './Text';
import { Input } from './Input';
import { HorizontalLayout } from './HorizontalLayout';
import { FlexAlignItems, FlexBox, FlexDirection } from './FlexBox';
import BoxShadow from './BoxShadow';
import Key from '../../constants/key';
import { Icon } from './Icon';
import { IconType } from '../../constants/IconType';
import { TextWeight } from '../../constants/TextWeight';
import { TextSize } from '../../constants/TextSize';
import { ScrollArea, ScrollAreaSize } from './ScrollArea';
import { WhiteSpace } from '../../constants/WhiteSpace';

const Wrapper = styled.div`
    position: relative;
`;

const InputTagWrapper = styled.div<{ focusBorder: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: ${({ focusBorder }) => `1px solid ${focusBorder ? Color.ACCENT : Color.PRIMARY10}`};
    border-radius: 8px;
    padding: 7px 8px 3px 12px;
    width: 100%;
`;

const TagsWrapper = styled.div<{ marginLeft: string }>`
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    & > :last-child {
        margin-left: ${({ marginLeft }) => marginLeft};
    }
`;

const Tag = styled(HorizontalLayout)<{ disabled: boolean }>`
    background-color: ${Color.PRIMARY10};
    padding: 5px 6px;
    border-radius: 4px;
    margin: 0 4px 4px 0;
    ${({ disabled }) => {
        const style = disabled ? 'opacity: 0.4; pointer-events: none;' : 'cursor: pointer;';

        return css`
            ${style}
        `;
    }}
`;

const IconWrapper = styled.div`
    display: flex;
    margin-left: 4px;
`;

const TagValue = styled(Text)`
    -ms-word-break: break-all;
    word-break: break-all;
`;
const Search = styled(Input)`
    display: inline;
    max-width: 150px;
    min-width: 100px;
    width: auto;
    margin: 0 12px 4px 0;
    input {
        padding: 0;
        min-width: 100px;
        max-width: 150px;
        border: none;
        height: 28px;
    }
`;

const DropdownWrapper = styled(FlexBox)<{ top: number; borderBottom: boolean }>`
    background-color: ${Color.SECONDARY};
    position: absolute;
    left: 0;
    top: ${({ top }) => `${top}px`};
    width: 100%;
    max-height: 272px;
    padding: 8px;
    border: 1px solid ${Color.PRIMARY10};
    border-bottom: ${({ borderBottom }) =>
        borderBottom ? `6px solid ${Color.ACCENT}` : `1px solid ${Color.PRIMARY10}`};
    box-shadow: ${BoxShadow.M};
    border-radius: 8px;
    z-index: 1;
`;

const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    height: 48px;

    &:hover {
        background-color: ${Color.PRIMARY05};
        border-radius: 8px;
        cursor: pointer;
    }

    &:not(:last-child) {
        margin-bottom: 4px;
    }
`;

const ItemLabel = styled(Text)`
    flex: 1 1 0;
    margin-left: 12px;
`;

const ImageWrapper = styled.div`
    width: 104px;
    height: 36px;
    margin: 0 6px 0 13px;
    display: flex;
    align-items: center;
    img {
        width: 100%;
        height: auto;
        max-height: 36px;
        object-fit: contain;
    }
`;

const NoFoundWrapper = styled.div`
    padding: 8px 4px;
`;

export interface ItemDropdown {
    id: string;
    label: string;
    thumb: string;
}

export interface SelectedItem {
    id: string;
    disabled: boolean;
}

export type InputTagsProps = {
    items: ItemDropdown[];
    selectedItems: SelectedItem[];
    placeholder: string;
    unselectItem: (id: string) => void;
    selectItem: (id: string) => void;
};

/**
 * This component is used to add input tags in the application
 */
export function InputTags({ items, selectedItems, placeholder, unselectItem, selectItem }: InputTagsProps) {
    // const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [dropdownTop, setDropdownTop] = useState<number>(42);
    const [focusBorder, setFocusBorder] = useState<boolean>(false);
    const prevSelectedItems = useRef<SelectedItem[]>([]);

    const inputTagRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
    const refScroll = useRef<Scrollbars>(null);

    const handleClickOutside = useCallback(({ target }: MouseEvent) => {
        if (target && !inputTagRef.current?.contains(target as Node)) {
            setOpenDropdown(false);
        }
    }, []);

    useEffect(() => {
        if (refScroll.current && prevSelectedItems.current.length < selectedItems.length) {
            refScroll.current.scrollToBottom();
        }

        prevSelectedItems.current = selectedItems;
    }, [selectedItems]);

    useEffect(() => {
        if (openDropdown) {
            document.addEventListener('mousedown', handleClickOutside, true);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [openDropdown, handleClickOutside]);

    const handleClickItem = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (searchTerm.length) {
            setSearchTerm('');
        }
        selectItem(id);
    };

    const handleKeyDownInput = (ev: KeyboardEvent) => {
        if (ev.key === Key.Escape) {
            setOpenDropdown(false);
        }
    };

    const onClickInput = (ev: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        ev.preventDefault();
        ev.stopPropagation();
        setOpenDropdown(true);
    };

    const onBlurInput = () => {
        setFocusBorder(false);
    };

    const getSelectedItems = useCallback(() => {
        return selectedItems.reduce((acc: React.ReactNode[], selectedItem: SelectedItem, index: number) => {
            const item = items.find(({ id: idItem }) => idItem === selectedItem.id);

            if (item) {
                acc.push(
                    <Tag
                        dataTestid={`${item.label}TagComponent`}
                        alignItems={FlexAlignItems.CENTER}
                        key={`${index}-${item.label}`}
                        disabled={selectedItem.disabled}
                    >
                        <TagValue size={TextSize.XS} weight={TextWeight.MEDIUM}>
                            {item.label}
                        </TagValue>
                        <IconWrapper onClick={() => unselectItem(item.id)}>
                            <Icon icon={IconType.close} />
                        </IconWrapper>
                    </Tag>,
                );
            }

            return acc;
        }, []);
    }, [selectedItems, items, unselectItem]);

    useEffect(() => {
        setDropdownTop(inputTagRef.current?.offsetHeight + 4);
    }, [inputTagRef, selectedItems, getSelectedItems]);

    const dropdownItems = items
        .filter(
            (item) =>
                !selectedItems.some((selectedItem) => selectedItem.id === item.id) &&
                item.label.toLowerCase().includes(searchTerm.toLocaleLowerCase()),
        )
        .map(({ id, label, thumb }, index) => (
            <DropdownItem
                data-testid={`${label}ListItem`}
                onClick={(ev) => handleClickItem(ev, id)}
                key={`${index}-${label}`}
            >
                <ItemLabel weight={TextWeight.MEDIUM} size={TextSize.S} ellipsis whiteSpace={WhiteSpace.NOWRAP}>
                    {label}
                </ItemLabel>
                {/* <ImageWrapper>
                    <img src={thumb} alt={label} />
                </ImageWrapper> */}
            </DropdownItem>
        ));

    return (
        <Wrapper>
            <InputTagWrapper ref={inputTagRef} focusBorder={focusBorder}>
                <ScrollArea autoHeight 
                  // ref={refScroll}
                  autoHeightMax={94} size={ScrollAreaSize.S} autoHide>
                    <TagsWrapper
                        marginLeft={items.length !== selectedItems.length ? '12px' : '0px'}
                        onClick={() => {
                            setOpenDropdown(false);
                        }}
                    >
                        {getSelectedItems()}
                        {items.length !== selectedItems.length ? (
                            <Search
                                value={searchTerm}
                                maxLength={50}
                                onChange={(ev) => setSearchTerm(ev.currentTarget.value)}
                                onClick={onClickInput}
                                onKeyDown={handleKeyDownInput}
                                onFocus={() => setFocusBorder(true)}
                                onBlur={onBlurInput}
                                placeholder={placeholder}
                            ></Search>
                        ) : null}
                    </TagsWrapper>
                </ScrollArea>

                {items.length !== selectedItems.length && openDropdown ? (
                    <DropdownWrapper
                        distance="4px"
                        direction={FlexDirection.COLUMN}
                        top={dropdownTop}
                        borderBottom={!dropdownItems.length}
                    >
                        <ScrollArea autoHeight autoHeightMax={150} size={ScrollAreaSize.S} autoHide>
                            {dropdownItems.length ? (
                                dropdownItems
                            ) : (
                                <NoFoundWrapper>
                                    <Text size={TextSize.S} color={Color.PRIMARY60} weight={TextWeight.REGULAR}>
                                    </Text>
                                    <Text size={TextSize.S} weight={TextWeight.MEDIUM}>
                                        &nbsp;{`“${searchTerm}” - tag NOT FOUND`}
                                    </Text>
                                </NoFoundWrapper>
                            )}
                        </ScrollArea>
                    </DropdownWrapper>
                ) : null}
            </InputTagWrapper>
        </Wrapper>
    );
}
