import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Button, ButtonSize } from "../../../shared/components/Button";
import { Modal } from "../../../shared/components/Modal";
import { Text } from "../../../shared/components/Text";
import { setShownModal, ShowModalEnum } from "../../../shared/redux/modalHub.slice";
import { TranslationAddForm } from "./TranslationAddForm";

const TitleTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

export const TranslationTable = () => {
  const dispatch = useAppDispatch();
  const showTranslationModal =
  useAppSelector((state) => state.modalHub.shownModal) ===
    ShowModalEnum.TRANSLATION_MODAL;

  return (
    <>
      <TitleTextWrapper>
        <Text size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
          Translation
        </Text>
        <Button
          icon={IconType.lightning}
          size={ButtonSize.L}
          onClick={() =>
            dispatch(
              setShownModal({ shownModal: ShowModalEnum.TRANSLATION_MODAL })
            )
          }
        >
          Add new
        </Button>
      </TitleTextWrapper>
      {showTranslationModal && (
        <Modal>
          <TranslationAddForm />
          
        </Modal>
      )}
    </>
  );
};
