import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface LoginShema {
  email: string;
  password: string;
  recaptcha: string;
}

export const setAuth = createAsyncThunk(
  "auth/setAuth",
  async ({ email, password, recaptcha }: LoginShema, { rejectWithValue }) => {
    const headers: { [key: string]: string } = {};
    headers["x-recaptcha"] = recaptcha;
    try {
      const {
        data: { token, expiresIn },
      } = await axios.post(
        `${process.env.REACT_APP_API_URL}/operator/login`,
        {
          email,
          password,
        },
        {
          headers,
        }
      );

      return { token, expiresIn, email };
    } catch (err) {
      //@ts-ignore
      if (!err.response) {
        throw err;
      }
      console.log(err, "DATE");
      //@ts-ignore
      return rejectWithValue(err.response.data.message);
    }
  }
);
