import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ShowModalEnum {
  BONUS_EDIT_MODAL = "BONUS_EDIT_MODAL",
  RESOURCE_EDIT_MODAL = "RESOURCE_EDIT_MODAL",
  GRADE_EDIT_MODAL = "GRADE_EDIT_MODAL",
  WEBHOOK_INFO = "WEBHOOK_INFO",
  TRANSLATION_MODAL = "TRANSLATION_MODAL",
  TAG_EDIT_MODAL = 'TAG_EDIT_MODAL',
  NONE = "NONE",
  BG_INFO = 'BG_INFO',
}

export type ModalHubState = {
  shownModal: ShowModalEnum;
};

export type ModalHubActionPayload = {
  shownModal: ShowModalEnum;
};

export const initialState: ModalHubState = {
  shownModal: ShowModalEnum.NONE,
};

export const modalHubSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setShownModal: (state, action: PayloadAction<ModalHubActionPayload>) => {
      state.shownModal = action.payload.shownModal;
    },
  },
});

export const { setShownModal } = modalHubSlice.actions;

export default modalHubSlice.reducer;
