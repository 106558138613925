import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import headerReducer from '../features/header/redux/header.slice';
import authReducer from '../features/auth/redux/auth.slice';
import modalHubReducer from '../shared/redux/modalHub.slice';
import bonusesReducer from '../features/Bonuses/redux/bonus.slice';
import gradesReducer from '../features/Grades/redux/grades.slice';
import resourcesReducer from '../features/Resources/redux/resources.slice';
import failedEventsReducer from '../features/FailedEvents/redux/failedEvents.slice';
import webhooksReducer from '../features/Webhooks/redux/webhooks.slice';
import translationReducer from '../features/Translation/redux/translation.slice';
import tagsReducer from '../features/Tags/redux/tags.slice';
import eventsReducer from '../features/Events/redux/events.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    counter: counterReducer,
    header: headerReducer,
    modalHub: modalHubReducer,
    bonuses: bonusesReducer,
    grades: gradesReducer,
    resources: resourcesReducer,
    failedEvents: failedEventsReducer,
    webhooks: webhooksReducer,
    translation: translationReducer,
    tags: tagsReducer,
    events: eventsReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
