import React, { useState, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../constants/ColorEnum';
import { IconType } from '../../constants/IconType';
import useDropdownClickOutside from '../hooks/useDropdownClickOutside';
import { Button, ButtonSize, ButtonType } from './Button';
import { DropdownList, DropdownListItemValue, DropdownListSelectionMode, DropdownListWidth, DropdownPosition, DropdownSize, Item } from './DropdownList';


export function getStyleForPosition(position: DropdownPosition) {
    switch (position) {
        case DropdownPosition.RIGHT:
            return css`
                left: 0;
            `;
        case DropdownPosition.LEFT:
            return css`
                right: 0;
            `;
        case DropdownPosition.CENTER:
            return css`
                right: auto;
                left: 50%;
                transform: translate(-50%, 0);
            `;
    }
}

const DropdownWrapper = styled.div<{ width?: number; position: DropdownPosition }>`
    position: absolute;
    ${({ position }) => getStyleForPosition(position)};
    z-index: 1;
    width: ${({ width }) => `${width}px`};
    background-color: ${Color.SECONDARY};
    border-radius: 8px;
`;

const ButtonDropdownWrapper = styled.div`
    display: inline-block;
    position: relative;
`;

export type ButtonDropdownProps = {
    dataTestid?: string;
    children: string;
    icon?: IconType;
    iconReverse?: boolean;
    title?: string;
    disabled?: boolean;
    disableValue?: DropdownListItemValue;
    size?: ButtonSize;
    buttonType?: ButtonType;
    invertColors?: boolean;
    lockColors?: boolean;
    onItemClick: (value: DropdownListItemValue) => void;
    items: Item[];
    maxVisibleItems?: number;
    dropdownPosition?: DropdownPosition;
    dropdownSize?: DropdownSize;
    dropdownListWidth?: DropdownListWidth;
    onShowDropdown?: Function;
    selectionMode?: DropdownListSelectionMode;
    closeDropdownOnClickItem?: boolean;
};

/**
 * This component uses Button and DropdownList to create a Button with Dropdown
 */
export function ButtonDropdown(props: ButtonDropdownProps) {
    const {
        dataTestid,
        children,
        icon,
        iconReverse,
        title,
        size,
        disabled,
        buttonType,
        items,
        maxVisibleItems,
        onItemClick,
        dropdownPosition = DropdownPosition.RIGHT,
        dropdownSize = DropdownSize.M,
        dropdownListWidth,
        invertColors = false,
        lockColors = false,
        disableValue,
        onShowDropdown = () => {},
        selectionMode,
        closeDropdownOnClickItem = true,
    } = props;

    const [dropdownIsVisible, setDropdownIsVisible] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    const handleHideDropdown = useCallback(
        (visibility = false) => {
            onShowDropdown(visibility);
            setDropdownIsVisible(visibility);
        },
        [onShowDropdown],
    );

    useDropdownClickOutside(buttonRef, dropdownRef, dropdownIsVisible, handleHideDropdown);

    const dropdown =
        dropdownIsVisible && items.length ? (
            <DropdownList
                items={items}
                size={dropdownSize}
                width={dropdownListWidth}
                onItemClick={(value) => {
                    if (closeDropdownOnClickItem) {
                        setDropdownIsVisible(false);
                    }
                    onItemClick(value);
                }}
                title={title}
                disableValue={disableValue}
                hideDropdown={() => setDropdownIsVisible(false)}
                maxVisibleItems={maxVisibleItems}
                selectionMode={selectionMode}
            />
        ) : null;

    const onIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleHideDropdown(!dropdownIsVisible);
    };

    return (
        <ButtonDropdownWrapper>
            <div ref={buttonRef} data-testid={dataTestid}>
                <Button
                    onClick={onIconClick}
                    icon={icon}
                    iconReverse={iconReverse}
                    size={size}
                    active={dropdownIsVisible}
                    disabled={disabled}
                    buttonType={buttonType}
                    invertColors={invertColors}
                    lockColors={lockColors}
                >
                    {children}
                </Button>
            </div>
            <DropdownWrapper width={DropdownListWidth[dropdownSize]} position={dropdownPosition} ref={dropdownRef}>
                {dropdown}
            </DropdownWrapper>
        </ButtonDropdownWrapper>
    );
}
