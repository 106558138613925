import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { Loading } from "../../../constants/Loading";
import {
  getClientBcReportEvents,
  getClientEvents,
  getClientJournaReportlEvents,
} from "./actions";
import { setUrlQueryParams as setUrlQueryString } from './../utils/utils';

export type ClientEventSchema = {
  clientforeignid: string;
  eventamount: number;
  eventdate: string;
  eventforeignid: string;
  eventmultiplier: number;
  eventprocessed: boolean;
  gradeid: string;
  gradename: string;
  mintamount: number;
  poolamount: number;
  resourceforeignid: string;
};

export type ClientJournalSchema = {
  journalid: string;
  clientinnerid: string;
  clientforeignid: string;
  eventdate: string;
  amount: number;
  statusid: number;
  createdat: string;
};

export type BGEventSchema = {
  args: Array<
    | string
    | {
        hex: string;
        type: string;
      }
  >;
  data: string;
  event: string;
  topics: Array<string>;
  address: string;
  removed: boolean;
  logIndex: number;
  blockHash: string;
  blockNumber: number;
  eventSignature: string;
  transactionHash: string;
  transactionIndex: number;
};

export type ClientBcEventsReportSchema = {
  bceventid: string;
  block: number;
  amount: string;
  event: any;
  createdat: string;
  updatedat: string;
  notificationsent: boolean;
  userinnerid: string;
  clientforeignid: string;
  burningitemid: string | null;
  txhash: string;
  address: string | null;
};

export type EventsState = {
  clientsEvents: {
    result?: ClientEventSchema[];
    count?: number;
    loading: Loading;
    errorMessage?: string;
  };
  clientJournalEvents: {
    result?: ClientJournalSchema[];
    count?: number;
    loading: Loading;
    errorMessage?: string;
  };
  clientBcEventsReport: {
    result?: ClientBcEventsReportSchema[];
    count?: number;
    loading: Loading;
    errorMessage?: string;
  };

  from?: Date;
  to?: Date;
};

export const initialState: EventsState = {
  clientsEvents: {
    loading: Loading.INITIAL,
  },
  clientJournalEvents: {
    loading: Loading.INITIAL,
  },
  clientBcEventsReport: {
    loading: Loading.INITIAL,
  },
  from: moment().subtract(1, 'months').startOf('D').toDate(),
  to: moment().startOf('D').toDate(),
};

// const changeUrlParams = (state?: EventsState) => {
//   if (!state) return setUrlQueryString();

//   const obj: { [key: string]: unknown[] | unknown } = {};

//   if (state.from) obj.dateMin = moment(state.from).toISOString(true);

//   if (state.to) obj.dateMax = moment(state.to).toISOString(true);

//   // if (state.breakdowns && state.breakdowns.length) obj.breakdowns = state.breakdowns;

//   // if (state.report?.chartBreakdowns?.length) obj.chartBreakdowns = state.report.chartBreakdowns;

//   // if (projectsAdapter.getSelectors().selectAll(state.projects)?.length)
//   //     obj.projects = projectsAdapter.getSelectors().selectAll(state.projects);

//   // if (networksAdapter.getSelectors().selectAll(state.networks)?.length)
//   //     obj.networks = networksAdapter.getSelectors().selectAll(state.networks);

//   // if (selectedDesignsAdapter.getSelectors().selectAll(state.designs.items)?.length)
//   //     obj.designs = selectedDesignsAdapter.getSelectors().selectAll(state.designs.selected);

//   setUrlQueryString(obj);
// };

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setFromAndToDates: (
      state,
      action: { payload: { from: Date; to: Date } }
    ) => {
      console.log('SET_TIME', action.payload.from, action.payload.to)
      state.from = action.payload.from || new Date();
      state.to = action.payload.to || new Date();
      // changeUrlParams(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientEvents.pending, (state, action) => {
      state.clientsEvents.loading = Loading.PENDING;
    });
    builder.addCase(getClientEvents.fulfilled, (state, action) => {
      state.clientsEvents.loading = Loading.SUCCESS;
      state.clientsEvents.result = action.payload.result;
      state.clientsEvents.count = action.payload.total;
    });
    builder.addCase(getClientEvents.rejected, (state, action) => {
      state.clientsEvents.loading = Loading.ERROR;
      state.clientsEvents.errorMessage = action.error.message;
    });

    builder.addCase(getClientJournaReportlEvents.pending, (state, action) => {
      state.clientJournalEvents.loading = Loading.PENDING;
    });
    builder.addCase(getClientJournaReportlEvents.fulfilled, (state, action) => {
      state.clientJournalEvents.loading = Loading.SUCCESS;
      state.clientJournalEvents.result = action.payload.result;
      state.clientJournalEvents.count = action.payload.total;
    });
    builder.addCase(getClientJournaReportlEvents.rejected, (state, action) => {
      state.clientJournalEvents.loading = Loading.ERROR;
      state.clientJournalEvents.errorMessage = action.error.message;
    });

    builder.addCase(getClientBcReportEvents.pending, (state, action) => {
      state.clientBcEventsReport.loading = Loading.PENDING;
    });
    builder.addCase(getClientBcReportEvents.fulfilled, (state, action) => {
      state.clientBcEventsReport.loading = Loading.SUCCESS;
      state.clientBcEventsReport.result = action.payload.result;
      state.clientBcEventsReport.count = action.payload.total;
    });
    builder.addCase(getClientBcReportEvents.rejected, (state, action) => {
      state.clientBcEventsReport.loading = Loading.ERROR;
      state.clientBcEventsReport.errorMessage = action.error.message;
    });
  },
});

export const { setFromAndToDates } = eventsSlice.actions;

export default eventsSlice.reducer;
