import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { Text } from "../../../shared/components/Text";
import {
  getClientBcReportEvents,
  getClientEvents,
  GetClientEventsShema,
  getClientJournaReportlEvents,
} from "../redux/actions";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange, DateRangePicker } from "react-date-range";
import { Sorting } from "../../../shared/components/Sorting";
import { Input } from "../../../shared/components/Input";
import moment from "moment";
import { useQueryParams } from "../../../utils/utils";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { ITEMS_PER_PAGE } from "../../../shared/components/UsersListPagination";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { ClientEvents } from "./ClientEvents";
import { ClientJournalReport } from "./ClientJournalReport";
import { ClientBcEventsReport } from "./ClientBcEventsReport";
import DatePicker from "./DatePicker";
import { setFromAndToDates } from "../redux/events.slice";

const TitleTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const StatsFiltersWrapper = styled.div`
  display: flex;
`;

const Flex = styled.div`
  display: flex;
`;

const FlexEnd = styled.div`
  display: flex;
  align-items: flex-end;
`;

const InputWithRightMargin = styled(Input)`
  margin-top: 16px;
  margin-right: 8px;
`;

const FilterButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`;

const ButtonWithMargin = styled(Button)`
  margin-right: 10px;
`;

export type PickerRangeType = {
  selection: { endDate: Date; startDate: Date; key: "selection" };
};

export enum EventTypes {
  CLIENT_EVENTS = "CLIENT_EVENTS",
  CLIENT_JOURNAL_REPORT = "CLIENT_JOURNAL_REPORT",
  CLIENT_BC_EVENTS_REPORT = "CLIENT_BC_EVENTS_REPORT",
}

export function Events() {
  const dispatch = useAppDispatch();
  const { from, to } = useAppSelector((state) => state.events);

  const [sortingItems, setSortingItems] = useState([
    { title: "Client Events", enum: EventTypes.CLIENT_EVENTS, isActive: true },
    {
      title: "Claim Status",
      enum: EventTypes.CLIENT_JOURNAL_REPORT,
      isActive: false,
    },
    {
      title: "Purchases",
      enum: EventTypes.CLIENT_BC_EVENTS_REPORT,
      isActive: false,
    },
  ]);

  const activePage = useMemo(() => {
    const activeItem = sortingItems.find((i) => i.isActive);
    return activeItem?.enum;
  }, [sortingItems]);

  const onSortItemClick = (value: any) => {
    const updatedValue = sortingItems.map((item) =>
      item.enum === value.enum
        ? { ...item, isActive: true }
        : { ...item, isActive: false }
    );
    setSortingItems(updatedValue);
  };

  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  const { page, limit, clientForeignId, dateMin, dateMax, eventType } =
    useQueryParams();

  const [filterByClientForeignId, setFilterByClientForeignId] = useState("");

  useEffect(() => {
    setFilterByClientForeignId(clientForeignId);
    dateMax && setEndDate(new Date(dateMax));
    dateMin && setStartDate(new Date(dateMin));
    onSortItemClick({ enum: eventType || EventTypes.CLIENT_EVENTS });
    const submitObj: GetClientEventsShema = {
      clientForeignId: filterByClientForeignId || "",
      page: "1",
      limit: ITEMS_PER_PAGE,
    };

    submitObj.dateMin =
      dateMin && dateMin !== dateMax ? moment.utc(dateMin).format() : "";
    submitObj.dateMax =
      dateMax && dateMin !== dateMax ? moment.utc(dateMax).format() : "";

      dispatch(setFromAndToDates({ from: dateMin, to: dateMax }))

    if (eventType === EventTypes.CLIENT_EVENTS) {
      dispatch(getClientEvents(submitObj));
    } else if (eventType === EventTypes.CLIENT_JOURNAL_REPORT) {
      dispatch(getClientJournaReportlEvents(submitObj));
    } else if (eventType === EventTypes.CLIENT_BC_EVENTS_REPORT) {
      dispatch(getClientBcReportEvents(submitObj));
    }
  }, [page, limit, clientForeignId, dateMin, dateMax, eventType]);

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  // useEffectSkipFirst(() => {
  //   setState([{ startDate, endDate, key: "selection" }]);
  // }, [endDate, startDate]);

  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  // const { from, to } = useAppSelector((state) => state.events);

  // const setTimeSelection = (item: PickerRangeType) => {
  //   let { endDate, startDate } = item.selection;

  //   startDate = new Date(startDate);
  //   endDate = new Date(
  //     endDate.setHours(
  //       endDate.getHours() + 23,
  //       endDate.getMinutes() + 59,
  //       endDate.getSeconds() + 59
  //     )
  //   );

  //   setStartDate(startDate);
  //   setEndDate(endDate);
  //   setState([item.selection]);
  // };

  return (
    <>
      <TitleTextWrapper>
        <Text size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
          Events
        </Text>
      </TitleTextWrapper>

      <StatsFiltersWrapper>
        <Sorting items={sortingItems} onSortItemClick={onSortItemClick} />
      </StatsFiltersWrapper>
      <FlexEnd>
        <InputWithRightMargin
          value={filterByClientForeignId}
          label="Client Foreign Id"
          onChange={(e) => setFilterByClientForeignId(e.target.value)}
          placeholder={"Filter name"}
          required={true}
        />
                {/* <DateRangePicker
                    range={state}
                    onApply={onApply}
                    maxDate={new Date()}
                    position={DateRangePosition.END}
                    buttonClassName={DATERANGE_BUTTON}
                ></DateRangePicker> */}
        <DatePicker />
      </FlexEnd>

      <Flex></Flex>
      <FilterButtonWrap>
        <ButtonWithMargin
          buttonType={ButtonType.SECONDARY}
          size={ButtonSize.L}
          onClick={() => {
            navigateWithSearchParams(
              { eventType: eventType || EventTypes.CLIENT_EVENTS },
              "/events"
            );
            setStartDate(new Date());
            setEndDate(new Date());
            setFilterByClientForeignId("");
          }}
        >
          Reset
        </ButtonWithMargin>
        <Button
          icon={IconType.lightning}
          size={ButtonSize.L}
          onClick={() => {
            const submitObj: {
              clientForeignId: string;
              page: string;
              limit: number;
              eventType?: EventTypes;
              dateMin?: Date;
              dateMax?: Date;
            } = {
              clientForeignId: filterByClientForeignId || "",
              page: "1",
              limit: ITEMS_PER_PAGE,
              eventType: activePage,
            };

            startDate &&
              from !== to &&
              (submitObj.dateMin = from);
            endDate &&
            from !== to &&
              (submitObj.dateMax = to);

            navigateWithSearchParams(submitObj, "/events");
          }}
        >
          Filter
        </Button>
      </FilterButtonWrap>
      {((eventType === EventTypes.CLIENT_EVENTS || !eventType) && (
        <ClientEvents />
      )) ||
        (eventType === EventTypes.CLIENT_JOURNAL_REPORT && (
          <ClientJournalReport />
        )) ||
        (eventType === EventTypes.CLIENT_BC_EVENTS_REPORT && (
          <ClientBcEventsReport />
        ))}
    </>
  );
}
