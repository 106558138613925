import { DateRangePicker } from 'react-date-range';
import styled from 'styled-components';

// import { EditableTextFontFamily as TextFontFamily } from '../EditableText';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { TextWeight } from '../../../constants/TextWeight';
import { TextSize } from '../../../constants/TextSize';
import { Color } from '../../../constants/ColorEnum';

const StyledDateRange = styled(DateRangePicker)`
    margin-top: 70px;
    align-self: center;
    box-sizing: border-box;

    * {
        // font-family: ${'Roboto'};
        font-weight: ${TextWeight.REGULAR};
        box-sizing: inherit;
    }
    .rdrMonths.rdrMonthsHorizontal {
        width: 550px;
    }

    .rdrDefinedRangesWrapper,
    .rdrCalendarWrapper {
        font-size: ${TextSize.S}px;
        color: ${Color.PRIMARY};
    }
    .rdrDefinedRangesWrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 24px;
        border-right: none;
        background: none;
        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 24px;
            right: 24px;
            height: 1px;
            background-color: ${Color.PRIMARY10};
        }
    }

    .rdrDefinedRangesWrapper .rdrStaticRange,
    .rdrDefinedRangesWrapper .rdrStaticRange .rdrStaticRangeLabel {
        border: none;
        color: ${Color.PRIMARY};
        font-weight: ${TextWeight.SEMIBOLD};
        font-size: ${TextSize.XS}px;
        text-align: center;
        padding: 0;
    }
    .rdrStaticRange:hover .rdrStaticRangeLabel,
    .rdrStaticRange:focus .rdrStaticRangeLabel {
        background: none;
    }
    .rdrDefinedRangesWrapper .rdrStaticRange {
        padding: 6px 24px;
    }
    .rdrDefinedRangesWrapper .rdrStaticRange:hover,
    .rdrDefinedRangesWrapper .rdrStaticRangeSelected,
    .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
        background-color: ${Color.ACCENT10};
        font-weight: ${TextWeight.SEMIBOLD};
        color: ${Color.PRIMARY} !important;
        border-radius: 4px;
    }
    .rdrDefinedRangesWrapper .rdrStaticRanges {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .rdrDefinedRangesWrapper .rdrInputRanges {
        display: none;
    }
    .rdrMonths {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 48px;
        width: 100%;

        .rdrMonth {
            flex-grow: 0;
            order: 2;
            padding: 0;
        }
    }
    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
        background-color: ${Color.ACCENT};
    }

    .rdrDateDisplayWrapper {
        display: none;
    }
    .rdrMonthAndYearPickers {
        display: none;
    }
    .rdrMonths .rdrMonth .rdrMonthName {
        text-align: center;
        padding: 0;
        position: absolute;
        top: 100px;
        color: ${Color.PRIMARY};
        font-size: ${TextSize.S}px;
        font-weight: ${TextWeight.MEDIUM};
    }

    .rdrMonths .rdrMonth:first-child .rdrMonthName {
        left: 130px;
    }

    .rdrMonths .rdrMonth:last-child .rdrMonthName {
        left: 410px;
    }
    .rdrDayDisabled .rdrDayNumber span {
        color: ${Color.PRIMARY40};
    }
    .rdrWeekDay {
        font-size: ${TextSize.S}px;
        color: ${Color.PRIMARY60};
    }
    .rdrDayNumber span {
        font-size: ${TextSize.S}px;
        color: ${Color.PRIMARY};
    }
    .rdrStartEdge {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .rdrDay .rdrInRange {
        background-color: ${Color.ACCENT10};
    }
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
        font-weight: ${TextWeight.SEMIBOLD};
        color: ${Color.SECONDARY};
    }
    .rdrDayPassive {
        opacity: 0;
    }
    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
        color: ${Color.PRIMARY};
    }

    .rdrEndEdge {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .rdrSelected {
        border-radius: 4px;
    }
    .rdrDayToday .rdrDayNumber span:after {
        background-color: ${Color.ACCENT};
    }
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        opacity: 0.4;
        position: absolute;
        top: 5px;
        left: 2px;
        right: 2px;
        bottom: 5px;
        border-radius: 4px;
        pointer-events: none;
        z-index: 1;
    }
    .rdrDayStartOfMonth,
    .rdrDayStartOfWeek {
        .rdrInRange,
        .rdrEndEdge {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    .rdrDayEndOfMonth,
    .rdrDayEndOfWeek {
        .rdrInRange,
        .rdrStartEdge {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .rdrDayStartOfMonth,
    .rdrDayStartOfWeek {
        .rdrDayInPreview,
        .rdrDayEndPreview {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            left: 2px;
        }
    }

    .rdrDayEndOfMonth,
    .rdrDayEndOfWeek {
        .rdrDayInPreview,
        .rdrDayStartPreview {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            right: 2px;
        }
    }
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        border: none;
        background-color: ${Color.ACCENT10};
    }
    .rdrDayStartPreview {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .rdrDayDisabled {
        background: none;
    }
`;

export default StyledDateRange;
