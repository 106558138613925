import React, { forwardRef, ReactElement, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../constants/ColorEnum';
import { IconType } from '../../constants/IconType';
import { TextSize } from '../../constants/TextSize';
import { TextWeight } from '../../constants/TextWeight';
import { WhiteSpace } from '../../constants/WhiteSpace';
import { Checkbox } from './Checkbox';
import { FooterItemsProps, HeaderItemsProps } from './Dropdown';
import { DropdownListItem, SizeValues as DropdownListItemSizeValues } from './DropdownListItem';
import { Icon } from './Icon';
import { Text } from './Text';
import { Tooltip, TooltipPlacement } from './Tooltip';
import { VerticalLayout } from './VerticalLayout';
import Scrollbars from 'react-custom-scrollbars-2';
import BoxShadow from './BoxShadow';
import { Separator } from './Separator';
import Key from '../../constants/key';
import { ScrollArea, ScrollAreaSize } from './ScrollArea';

export enum DropdownSize {
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
}

export type DropdownListItemValue = string | number;

export enum DropdownListWidth {
    XXS = 90,
    XS = 120,
    S = 190,
    M = 220,
    L = 240,
    XL = 360,
}

export enum DropdownListSelectionMode {
    NONE = 'none',
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
}

export enum DropdownPosition {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
    TOP_LEFT = 'topLeft',
}

export type ItemContent = {
    icon?: IconType;
    avatar?: any;
    text?: string;
    description?: string | null;
    component?: ReactElement;
};

export type Item = {
    premium?: boolean;
    value?: DropdownListItemValue;
    isSeparator?: boolean;
    selected?: boolean;
    content?: ItemContent;
    disabled?: boolean;
    tooltipText?: string;
    tooltipTextPlacement?: TooltipPlacement;
    showTooltipOnDisabled?: boolean;
};

const SizeValues = {
    [DropdownSize.S]: {
        padding: 8,
    },
    [DropdownSize.M]: {
        padding: 8,
    },
    [DropdownSize.L]: {
        padding: 8,
    },
    [DropdownSize.XL]: {
        padding: 8,
    },
};

const SEPARATOR_TOP_PADDING = 4;
const SEPARATOR_BOTTOM_PADDING = 8;

type DropdownContainerProps = {
    width?: DropdownListWidth;
    fixed?: boolean;
    top?: number;
    left?: number;
    up?: boolean;
};
const DropdownContainer = styled.div<DropdownContainerProps>`
    background: ${Color.SECONDARY};
    border: 1px solid ${Color.PRIMARY10};
    border-radius: 8px;
    box-shadow: ${BoxShadow.M};
    margin: 4px 0;
    position: absolute;
    z-index: 100;
    width: ${({ width }) => (width ? `${width}px` : '100%')};

    ${({ fixed, top, left, up }) =>
        fixed
            ? css`
                  position: fixed;
                  left: 0;
                  top: 0;
                  transform: translate(${left}px, ${top}px);
              `
            : css`
                  top: ${top ? `${top}px` : ''};
                  bottom: ${up ? '35px' : ''};
              `}
`;

type ListContainerProps = {
    size: DropdownSize;
};
const ListContainer = styled.div<ListContainerProps & { height: number; minHeight: number | undefined }>`
    padding: ${({ size }) => SizeValues[size].padding}px;
    height: ${({ height }) => height}px;
    min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '')};
`;

const Title = styled.div`
    display: flex;
    padding: 12px;
    border-bottom: 1px solid ${Color.PRIMARY10};
    &.withTooltip {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const FooterContent = styled.div`
    margin: 8px;
`;

const FooterContainer = styled.div<ListContainerProps>`
    padding: ${({ size }) => SizeValues[size].padding};
`;
const HeaderContainer = styled.div<ListContainerProps>`
    padding: ${({ size }) => SizeValues[size].padding}px;
`;

const FooterWrapper = styled.div`
    :not(:last-child) {
        margin-bottom: 4px;
    }
`;

const TooltipWrapper = styled.span`
    display: flex;
`;

function getItemContent({
    selectionMode,
    selected,
    item,
    itemDisabledValue,
    value,
    maxWidth,
    hideBackgroundColorOnSelected,
}: {
    selectionMode: DropdownListSelectionMode;
    selected: boolean;
    item: ItemContent;
    itemDisabledValue?: DropdownListItemValue;
    value?: DropdownListItemValue;
    maxWidth?: string;
    hideBackgroundColorOnSelected?: boolean;
}): ReactElement[] {
    const itemChildren = [];

    switch (selectionMode) {
        case DropdownListSelectionMode.CHECKBOX:
            itemChildren.push(
                <Checkbox
                    key="checkbox"
                    disabled={hideBackgroundColorOnSelected && itemDisabledValue === value}
                    checked={selected}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={() => {}}
                />,
            );
            break;
        case DropdownListSelectionMode.RADIO:
            // itemChildren.push(<Radio key="radio" />);
            break;
        case DropdownListSelectionMode.NONE:
        default:
            break;
    }

    if (item?.icon) {
        itemChildren.push(<Icon key="icon" icon={item.icon} color={Color.PRIMARY40} />);
    }

    // if (item?.avatar) {
    //     itemChildren.push(<Avatar key="avatar" {...item.avatar} />);
    // }

    if (item?.text) {
        const hasValue = itemDisabledValue && value && itemDisabledValue === value;
        let finalTextChild = (
            <Text
                key="text"
                size={TextSize.S}
                weight={TextWeight.MEDIUM}
                color={hasValue ? Color.PRIMARY40 : Color.PRIMARY}
                maxWidth={maxWidth}
                ellipsis
                whiteSpace={WhiteSpace.NOWRAP}
            >
                {item.text}
            </Text>
        );

        if (item?.description) {
            finalTextChild = (
                <VerticalLayout key="textWithDescription" distance="2px">
                    {finalTextChild}
                    <Text
                        size={TextSize.XS}
                        weight={TextWeight.REGULAR}
                        color={Color.PRIMARY60}
                        ellipsis
                        whiteSpace={WhiteSpace.NOWRAP}
                    >
                        {item.description}
                    </Text>
                </VerticalLayout>
            );
        }

        itemChildren.push(finalTextChild);
    }

    if (item?.component) {
        itemChildren.push(item.component);
    }

    return itemChildren;
}

export type DropdownListProps = DropdownContainerProps &
    ListContainerProps & {
        items: Item[];
        maxVisibleItems?: number;
        selectedValue?: DropdownListItemValue;
        title?: string;
        disableValue?: DropdownListItemValue;
        selectionMode?: DropdownListSelectionMode;
        onItemClick?: (value: DropdownListItemValue, fromEnter: boolean) => void;
        onItemMouseDown?: (value: DropdownListItemValue, index?: number, e?: React.MouseEvent) => void;
        selectedState?: boolean;
        headerItems?: HeaderItemsProps[] | null;
        footerItems?: FooterItemsProps[] | null | undefined;
        hideDropdown?: () => void;
        tooltipValue?: string;
        titleTooltip?: string;
        itemAsLink?: boolean;
        setSelected?: (value?: DropdownListItemValue) => void;
        dataTestid?: string;
        minHeight?: number;
        parentTop?: number;
        className?: string;
        maxWidth?: string;
        up?: boolean;
        id?: string;
        hideBackgroundColorOnSelected?: boolean;
    };

/**
 * This component will not be exported
 */
export const DropdownList = forwardRef<HTMLDivElement, DropdownListProps>((props: DropdownListProps, ref) => {
    const {
        items,
        selectedValue,
        disableValue = '',
        size,
        title,
        width,
        fixed = false,
        top = 0,
        left = 0,
        parentTop,
        onItemClick,
        onItemMouseDown,
        selectionMode = DropdownListSelectionMode.NONE,
        selectedState = true,
        headerItems,
        footerItems,
        hideDropdown,
        titleTooltip,
        itemAsLink = false,
        dataTestid,
        setSelected,
        minHeight,
        className,
        maxWidth,
        up,
        id = '',
        hideBackgroundColorOnSelected = false,
    } = props;
    const scrollRef = useRef<Scrollbars>(null);

    const maxVisibleItems = props.maxVisibleItems || items.length;

    const titleContent = title ? (
        <Title className={titleTooltip ? 'withTooltip' : ''}>
            <Text
                color={Color.PRIMARY40}
                size={TextSize.XXS}
                weight={TextWeight.BOLD}
                uppercase
                ellipsis
                whiteSpace={WhiteSpace.NOWRAP}
            >
                {title}
            </Text>
            {titleTooltip && (
                <Tooltip value={titleTooltip} placement={TooltipPlacement.RIGHT} maxWidth={260}>
                    <TooltipWrapper>
                        <Icon key="icon" icon={IconType.arrow} color={Color.PRIMARY40} />
                    </TooltipWrapper>
                </Tooltip>
            )}
        </Title>
    ) : null;

    const headerContent = headerItems?.length ? (
        <>
            <HeaderContainer size={size}>
                {headerItems.map(({ content, onItemClick }, index) => (
                    <DropdownListItem
                        key={`header${index}`}
                        value={index}
                        size={size}
                        itemAsLink={itemAsLink}
                        onClick={() => {
                            hideDropdown && hideDropdown();
                            onItemClick && onItemClick();
                        }}
                    >
                        {getItemContent({ selectionMode, selected: false, item: content })}
                    </DropdownListItem>
                ))}
            </HeaderContainer>
            {/* <Separator color={Color.PRIMARY10} padding="0" /> */}
        </>
    ) : null;

    const footerContent = footerItems?.length ? (
        <FooterContainer size={size}>
            {/* <Separator color={Color.PRIMARY10} padding="0" /> */}
            <FooterContent>
                {footerItems.map(({ content, onItemClick }, index) => {
                    return (
                        <FooterWrapper key={index}>
                            <DropdownListItem
                                key={index}
                                value={index}
                                size={size}
                                itemAsLink={itemAsLink}
                                onClick={() => {
                                    hideDropdown && hideDropdown();
                                    onItemClick && onItemClick();
                                }}
                            >
                                {getItemContent({ selectionMode, selected: false, item: content })}
                            </DropdownListItem>
                        </FooterWrapper>
                    );
                })}
            </FooterContent>
        </FooterContainer>
    ) : null;

    const listContent = items.map(
        (
            {
                value,
                selected = false,
                content,
                isSeparator = false,
                tooltipText = '',
                tooltipTextPlacement = TooltipPlacement.LEFT,
                disabled = false,
                showTooltipOnDisabled = false,
            },
            index,
        ) => {
            if (isSeparator) {
                return (
                    <Separator
                        key={`${value}_${index}`}
                        color={Color.PRIMARY10}
                        padding={`${SEPARATOR_TOP_PADDING}px 0 ${SEPARATOR_BOTTOM_PADDING}px 0`}
                    />
                );
            }

            if (typeof value === 'undefined' || !content) {
                return null;
            }

            /** If we have a disableValue for all dropdown list and we want to disabled all items with that value */
            let itemDisabledValue = disableValue;
            /** If item has a disabled prop then we take the value to disable it too */
            if (disabled) {
                itemDisabledValue = value;
            }

            return (
                <DropdownListItem
                    key={`${value}_${index}`}
                    value={value}
                    size={size}
                    onClick={(value) => {
                        onItemClick && onItemClick(value, false);
                    }}
                    onMouseDown={(val, e) => onItemMouseDown && onItemMouseDown(val, index, e)}
                    disabled={itemDisabledValue === value}
                    selected={selectedState && (selected || value === selectedValue)}
                    tooltipText={tooltipText}
                    itemAsLink={itemAsLink}
                    tooltipTextPlacement={tooltipTextPlacement}
                    showTooltipOnDisabled={showTooltipOnDisabled}
                    hideBackgroundColorOnSelected={hideBackgroundColorOnSelected}
                >
                    {getItemContent({
                        selectionMode,
                        selected,
                        item: content,
                        itemDisabledValue,
                        value,
                        maxWidth,
                        hideBackgroundColorOnSelected,
                    })}
                </DropdownListItem>
            );
        },
    );

    const { height: itemHeight, marginBottom: itemMarginBottom } = DropdownListItemSizeValues[size];

    const itemsHeight = items.reduce((acc, val, i) => {
        if (i < maxVisibleItems) {
            if (val.isSeparator) {
                // separator has 1px height
                return acc + 1 + SEPARATOR_TOP_PADDING + SEPARATOR_TOP_PADDING / 2 + SizeValues[size].padding;
            } else {
                return acc + itemHeight + (i !== items.length - 1 && i !== maxVisibleItems - 1 ? itemMarginBottom : 0);
            }
        }

        return acc;
    }, 0);

    const setScroll = useCallback(
        (newIndex: number) => {
            const indexToHeight = (index: number) => {
                return index * (itemHeight + itemMarginBottom);
            };
            const scrollToHeight = (myHeight: number) => {
                if (scrollRef && scrollRef.current) {
                    scrollRef.current.scrollTop(myHeight);
                }
            };
            if (maxVisibleItems) {
                if (newIndex === 0) {
                    scrollToHeight(0);
                } else {
                    // verificare daca s-a selectat un item cu index
                    // mai mare decat maximul de elemente vizibile
                    if (newIndex + 1 > maxVisibleItems) {
                        scrollToHeight(indexToHeight(newIndex + 1 - maxVisibleItems));
                    }
                }
            }
        },
        [itemHeight, itemMarginBottom, maxVisibleItems],
    );

    const arrowsKeysSelection = useCallback(
        (e: KeyboardEvent) => {
            const setItem = (newItem: Item) => {
                if (newItem?.value && setSelected) {
                    setSelected(newItem.value);
                }
            };
            switch (e.key) {
                case Key.ArrowUp:
                    {
                        const findSelectedIndex = items.findIndex((item) => item.value === selectedValue);
                        const newIndex =
                            (findSelectedIndex === 0 || findSelectedIndex === -1 ? items.length : findSelectedIndex) -
                            1;
                        const newItem = items[newIndex].isSeparator ? items[newIndex - 1] : items[newIndex];
                        setItem(newItem);
                    }
                    break;
                case Key.ArrowDown:
                    {
                        const findSelectedIndex = items.findIndex((item) => item.value === selectedValue);
                        const newIndex =
                            (findSelectedIndex === items.length - 1 || findSelectedIndex === -1
                                ? -1
                                : findSelectedIndex) + 1;
                        const newItem = items[newIndex].isSeparator ? items[newIndex + 1] : items[newIndex];
                        setItem(newItem);
                    }
                    break;
                case Key.Escape:
                    hideDropdown && hideDropdown();
                    break;
                case Key.Enter:
                    if (onItemClick && selectedValue) {
                        onItemClick(selectedValue, true);
                    }
                    break;
            }
        },
        [setSelected, hideDropdown, onItemClick, selectedValue, items],
    );

    useEffect(() => {
        const findSelectedIndex = items.findIndex((item) => item.value === selectedValue);
        setScroll(findSelectedIndex);
        document.addEventListener('keydown', arrowsKeysSelection, true);

        return () => {
            document.removeEventListener('keydown', arrowsKeysSelection, true);
        };
    }, [items, selectedValue, setScroll, arrowsKeysSelection]);

    const height = itemsHeight + 2 * SizeValues[size].padding;
    const upDirection = up || (!!parentTop && window.innerHeight - parentTop < height + (title ? 50 : 0));

    return (
        <DropdownContainer
            id={id}
            width={width}
            top={top}
            left={left}
            up={upDirection}
            ref={ref}
            fixed={fixed}
            className={className}
        >
            {titleContent}
            {headerContent}
            <ListContainer data-testid={dataTestid} size={size} height={height} minHeight={minHeight}>
                <ScrollArea size={ScrollAreaSize.S} ref={scrollRef} hideHorizontalTrack={true}>
                    {listContent}
                </ScrollArea>
            </ListContainer>
            {footerContent}
        </DropdownContainer>
    );
});
