import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Input } from "../../../shared/components/Input";
import {
  Button,
  ButtonSize,
  ButtonType,
  HTMLButtonType,
} from "../../../shared/components/Button";

import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { createTag, getAllOperatorsTags } from "../redux/actions";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { getResources } from "../../Resources/redux/actions";

const AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const AuthFormNameWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  margin-top: 27px;
`;

const ErrorText = styled(Text)`
  margin-top: 10px;
`;

const ButtonWithLeftMargin = styled.div`
  margin-left: 16px;
`;

export function TagAddForm() {
  const dispatch = useAppDispatch();
  const { errorMessageCreate } = useAppSelector((state) => state.tags);

  const initialValues = {
    name: "",
  };

  const editGradeSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(250, "Maximum 250 symbols")
      .required("Name is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: editGradeSchema,
    onSubmit: async (values) => {
      const submitObject: {
        id?: string;
        name: string;
      } = {
        ...values,
      };

      dispatch(createTag(submitObject)).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }));
          dispatch(getAllOperatorsTags());
        }
      })
    },
  });

  useEffectSkipFirst(() => {
    dispatch(getResources({ page: "1", limit: "9999" }));
  }, []);

  return (
    <AuthFormWrapper>
      <form onSubmit={formik.handleSubmit}>
        <AuthFormNameWrapper>
          <Text size={TextSize.M} weight={TextWeight.SEMIBOLD}>
            Add New Tag
          </Text>
        </AuthFormNameWrapper>
        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <Input
          label="Name"
          {...formik.getFieldProps("name")}
          placeholder={"Name"}
          error={(formik.touched.name && formik.errors.name) || ""}
          required={true}
        />


        <ButtonWrapper>
          <Button
            fullWidth={true}
            customMaxWidth={140}
            size={ButtonSize.L}
            type={HTMLButtonType.SUBMIT}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Confirm
          </Button>
          <ButtonWithLeftMargin>
            <Button
              onClick={() =>
                dispatch(setShownModal({ shownModal: ShowModalEnum.NONE }))
              }
              fullWidth={true}
              customMaxWidth={140}
              size={ButtonSize.L}
              buttonType={ButtonType.WHITE}
            >
              Cancel
            </Button>
          </ButtonWithLeftMargin>
        </ButtonWrapper>
        <div>
          {errorMessageCreate && (
            <ErrorText color={Color.RED_HOT_HOOD}>
              {errorMessageCreate}
            </ErrorText>
          )}
        </div>
      </form>
    </AuthFormWrapper>
  );
}
