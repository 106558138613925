import { useCallback, useEffect, RefObject } from 'react';

function useDropdownClickOutside(
    { current: buttonCurrent }: RefObject<HTMLDivElement>,
    { current: dropdownCurrent }: RefObject<HTMLDivElement>,
    dropdownIsVisible: boolean,
    callback: () => void,
) {
    const handleClickOutside = useCallback(
        ({ target }: MouseEvent) => {
            if (
                dropdownIsVisible &&
                target instanceof Node &&
                dropdownCurrent &&
                !dropdownCurrent.contains(target) &&
                buttonCurrent &&
                !buttonCurrent.contains(target) &&
                callback
            ) {
                callback();
            }
        },
        [dropdownCurrent, buttonCurrent, dropdownIsVisible, callback],
    );

    useEffect(() => {
        if (dropdownIsVisible) {
            // set click because there were some issues where mousedown wouldn't register a click
            document.addEventListener('click', handleClickOutside);
            // apparently click adds some functionality and mouseDown completes it (don't ask me why but it works)
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownIsVisible, handleClickOutside]);
}

export default useDropdownClickOutside;
