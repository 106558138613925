import { Outlet } from "react-router";
import styled from "styled-components";
import Header from "../features/header/components";

const MainLayoutWrapper = styled.div``;

const OutletWrapper = styled.div`
  width: 92%;
  margin: auto;
  margin-top: 37px;
`;

export const MainLayout = () => {
  return (
    <MainLayoutWrapper>
      <Header />
      <OutletWrapper>
        <Outlet></Outlet>
      </OutletWrapper>
    </MainLayoutWrapper>
  );
};
