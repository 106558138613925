import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Color } from '../../constants/ColorEnum';
import { TextSize } from '../../constants/TextSize';
import { TextWeight } from '../../constants/TextWeight';

export enum TooltipPlacement {
    TOP = 'top',
    TOP_START = 'top-start',
    TOP_END = 'top-end',
    RIGHT = 'right',
    RIGHT_START = 'right-start',
    RIGHT_END = 'right-end',
    BOTTOM = 'bottom',
    BOTTOM_START = 'bottom-start',
    BOTTOM_END = 'bottom-end',
    LEFT = 'left',
    LEFT_START = 'left-start',
    LEFT_END = 'left-end',
    AUTO = 'auto',
    AUTO_START = 'auto-start',
    AUTO_END = 'auto-end',
}

export enum TooltipTrigger {
    HOVER = 'mouseenter',
    CLICK = 'click',
    FOCUS = 'focus',
}

export enum ColorTheme {
    DARK = 'DARK',
    LIGHT = 'LIGHT',
}

type TooltipTransientProps = {
    $minWidth?: number;
    theme: ColorTheme;
    borderRadius?: number;
    padding?: string;
};

export type TooltipProps = {
    value: string | ReactElement | null | undefined;
    placement?: TooltipPlacement;
    trigger?: TooltipTrigger;
    maxWidth?: number;
    minWidth?: number;
    children: ReactElement;
    theme?: ColorTheme;
    offset?: number;
    distance?: number;
    delay?: number;
    borderRadius?: number;
    padding?: string;
    interactive?: boolean;
    className?: string;
};

const TippyTooltip = styled<React.FC<TippyProps & TooltipTransientProps>>(({ borderRadius, padding, ...props }) => (
    <Tippy {...props} />
))<TooltipTransientProps>`
    &.tippy-box {
        background-color: ${({ theme }) => (theme === ColorTheme.DARK ? Color.SECONDARY : Color.PRIMARY)};
        border-radius: ${({ borderRadius }) => `${borderRadius}px`};
        color: ${({ theme }) => (theme === ColorTheme.DARK ? Color.PRIMARY : Color.SECONDARY)};
        font-size: ${TextSize.XS}px;
        font-weight: ${TextWeight.MEDIUM};
        line-height: 16px;
        min-width: ${({ $minWidth }) => ($minWidth ? `${$minWidth}px` : 'auto')};

        .tippy-content {
            padding: ${({ padding }) => padding};
        }

        .tippy-arrow {
            color: ${({ theme }) => (theme === ColorTheme.DARK ? Color.SECONDARY : Color.PRIMARY)};
            height: 8px;
            width: 8px;
        }
    }

    &.tippy-box[data-placement^='top'] > .tippy-arrow:before {
        bottom: -4px;
        border-width: 4px 4px 0;
    }

    &.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
        top: -4px;
        border-width: 0 4px 4px;
    }

    &.tippy-box[data-placement^='left'] > .tippy-arrow:before {
        border-width: 4px 0 4px 4px;
        right: -4px;
    }

    &.tippy-box[data-placement^='right'] > .tippy-arrow:before {
        left: -4px;
        border-width: 4px 4px 4px 0;
    }
`;

/**
 * Tooltip is a component which renders a popup tip containing text or custom content.
 */
export const Tooltip = ({
    value,
    placement,
    trigger,
    children,
    maxWidth,
    minWidth,
    theme = ColorTheme.LIGHT,
    offset = 0,
    distance = 8,
    interactive = false,
    borderRadius = 6,
    padding = '6px 10px',
    delay = 100,
    className,
}: TooltipProps) =>
    value ? (
        <TippyTooltip
            placement={placement || TooltipPlacement.TOP}
            content={value}
            offset={[offset, distance]}
            trigger={trigger || TooltipTrigger.HOVER}
            $minWidth={minWidth}
            theme={theme}
            maxWidth={maxWidth || 'auto'}
            duration={0}
            borderRadius={borderRadius}
            padding={padding}
            interactive={interactive}
            delay={delay}
            className={className}
        >
            {children}
        </TippyTooltip>
    ) : (
        children
    );
