import React, { useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { Loading } from "../../../constants/Loading";
import { PageCountEnum } from "../../../constants/PageCountEnum";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { Button, ButtonSize } from "../../../shared/components/Button";
import { Icon } from "../../../shared/components/Icon";
import { Modal } from "../../../shared/components/Modal";
import { RewindLoader } from "../../../shared/components/RewindLoader";
import { Sorting } from "../../../shared/components/Sorting";
import { Text } from "../../../shared/components/Text";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import {
  setShownModal,
  ShowModalEnum,
} from "../../../shared/redux/modalHub.slice";
import { getAllOperatorsTags } from "../../Tags/redux/actions";
import { BonusCard } from "../components/BonusCard";
import { BurningItemAddForm } from "../components/BurningItemAddForm";
import { getBonuses } from "../redux/actions";
import {
  resetEditBonusItem,
  resetErrorMessageSubmit,
} from "../redux/bonus.slice";

const BonusPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin: 24px 0;
`;

const BonusItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px -10px 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleTextWrapper = styled(Text)`
  margin-right: 32px;
`;

const BonusPageTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  width: 100%;
`;

const LoaderWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
`;

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

export const BonusPage = () => {
  const sortingItems = [
    { title: "All", isActive: true },
    { title: "Active", isActive: false },
    { title: "Scheduled", isActive: false },
    { title: "Expired", isActive: false },
  ];

  const onSortItemClick = () => {};

  const showEditModal =
    useAppSelector((state) => state.modalHub.shownModal) ===
    ShowModalEnum.BONUS_EDIT_MODAL;
  const dispatch = useAppDispatch();
  const [page, setPage] = useState("1");
  const [limit, setLimit] = useState(PageCountEnum.TWO_HUNDRED);
  const tagsLoading = useAppSelector(state => state.tags.loading);

  useEffectSkipFirst(() => {
    dispatch(getBonuses({ page, limit }));

    //remove after implementation
    setPage("1");
    setLimit(PageCountEnum.TWO_HUNDRED);
    if (tagsLoading === Loading.INITIAL) {
      dispatch(getAllOperatorsTags());
    }
  }, []);
  const { result, loading } = useAppSelector((state) => state.bonuses);

  return (
    <BonusPageWrapper>
      <BonusPageTitleWrapper>
        <TitleWrapper>
          <TitleTextWrapper size={TextSize.XL} weight={TextWeight.SEMIBOLD}>
            Active bonuses
          </TitleTextWrapper>
          <Button
            icon={IconType.lightning}
            size={ButtonSize.L}
            onClick={() => {
              dispatch(resetErrorMessageSubmit());
              dispatch(resetEditBonusItem());
              dispatch(
                setShownModal({ shownModal: ShowModalEnum.BONUS_EDIT_MODAL })
              );
            }}
          >
            Add new
          </Button>
        </TitleWrapper>
        {/* <Sorting items={sortingItems} onSortItemClick={onSortItemClick} /> */}
      </BonusPageTitleWrapper>
      {loading === Loading.SUCCESS && (
        <BonusItemsWrapper>
          {result.map((item, index) => (
            <BonusCard {...item} key={'Bonus_card_' + index}/>
          ))}
        </BonusItemsWrapper>
      )}

      {loading === Loading.PENDING && (
        <LoaderWrap>
          <RewindLoader color={"#233447"} size={110} sizeUnit={"px"} />
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Loading date
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                Please take a deep breath
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {loading === Loading.ERROR && (
        <LoaderWrap>
          <Icon
            icon={IconType.error}
            size={80}
            color={Color.BRIGHT_GRAY}
          ></Icon>
          <TextSection>
            <Text size={TextSize.L} weight={TextWeight.SEMIBOLD}>
              Something went wrong.
            </Text>
            <div>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                No data fetched. Please reload page or come back in few mins
              </Text>
            </div>
          </TextSection>
        </LoaderWrap>
      )}
      {showEditModal && (
        <Modal>
          <BurningItemAddForm />
        </Modal>
      )}
    </BonusPageWrapper>
  );
};
